import React, { useState } from 'react'

type Props = {
    label: string,
    labelStyle: string,
    data: any[],
    defaultOpt?: string,
    style?: string,
    layout?: string,
    value?: string,
    onChange: () => void
}

const Select: React.FC<Props> = ({label, labelStyle, data, defaultOpt, style, layout, value, onChange}) => {

    return (
        <div className={`${layout}`}>
            {label && <label className={`${labelStyle} mx-2`}>{label}</label>}
            <select 
                value={value} 
                onChange={onChange}
                className={`${style} font-normal text-xs leading-5 w-full bg-NoColor outline-none mt-2 py-3 px-2 indent-2 rounded-md overflow-auto text-Black3`}
            >
                <option value="" className='text-SecondaryAccent'>{defaultOpt}</option>
                {data.length > 0 &&
                    data.map(item => (
                        <>
                            {item.label && <option value={item.value}>{item.label}</option>}
                            {!item.label && <option value={item.code}>{item.description}</option>}
                        </>
                    ))
                }
            </select>
        </div>
    )
}

export default Select