import React, {useCallback, useEffect, useState} from 'react'
import Button from 'components/atoms/Button'
import infoIcon from '../../../assets/svg/information.svg'
import {PocketData} from '../../../mockData/PocketList'
import {PocketTransactions} from '../../../mockData/PocketTransactions'
import { useParams } from 'react-router-dom'
import PaymentCard from 'components/molecules/PaymentCard'
import PageHeader from 'components/molecules/PageHeader'
import PageHeader_old from 'components/molecules/PageHeader_old'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import copyIcon from '../../../assets/svg/copy.svg'
import moreIcon from '../../../assets/svg/more.svg'
import closeIcon from '../../../assets/svg/close-circle-solid.svg'
import Dropdown from 'components/atoms/Dropdown'
import PageTitle from 'components/atoms/PageTitle'
import CopyText from 'components/atoms/CopyText'
import { BASE_URL } from 'services/http'
import Alert from 'components/atoms/Alert'
import { handleException } from 'utils'
import moment from 'moment'
import {ToCamelCase} from '../../../components/atoms/CaseManager'
import ColorTag from 'components/atoms/ColorTag'
import { toggleAppEnv, toggleModal } from 'redux/app/app.action'
import { connect } from 'react-redux'
import SideComponent from '../Transactions/SideComponent'
import axios from 'services/axios'
import {PocketTransactionHeader} from '../../../components/molecules/tableExport'


type Props = {
    publicKey?: any,
    bearerToken?: any,
    modalState?: boolean, 
    toggleModal?: () => void
}

const searchKeys = [
    {title: "pocketId", value: null, link: null, action: () => null},
    {title: "reference", value: null, link: null, action: () => null},
    {title: "narration", value: null, link: null, action: () => null}
  ].sort()

const SubaPocketOverview:React.FC<Props> = ({modalState, toggleModal, bearerToken, publicKey}) => {
    const [query, setQuery] = useState("")
    const [queryKey, setQueryKey] = useState("")
    const [dropdown, setDropdown] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [pocketData, setPocketData] = useState<any>({})
    const [pocketOwnerData, setPocketOwnerData] = useState<any>({})
    const [showModal, setShowModal] = useState(false) 

    const [transactionDetails, setTransactionDetails] = useState<any>({})
    const [loadingTransactions, setLoadingTransactions] = useState(false)
    const [transactionsErrorState, setTransactionsErrorState] = useState(false)
    const [pocketTransactions, setPocketTransactions] = useState<any>([])
    const [totalElements, setTotalElements] = useState(null)
    const [totalPages, setTotalPages] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageNum, setPageNum] = useState(null)
    const [recordsPerPage] =  useState(5)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const pocketId = useParams()
    const url = `search?reference=&pocketId=${pocketId.id}`

    const defaultURl = `transaction/search?pocketId=${pocketId.id}`
    const urlLogic =  query.length !== 0 && (!startDate || !endDate)
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}`

            : query.length !== 0 && startDate !== "" && endDate !== ""
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
            
            : (startDate && endDate === "")
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}`

            : (startDate === "" && endDate)
            ? `${defaultURl}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : (startDate && endDate)
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : defaultURl

    const pocketTransaction_url = `${urlLogic}&size=${recordsPerPage}&page=${pageNum === null ? 0 : pageNum}`


    const fetchPocketFunc = async () => {
        setLoading(true)
        try{
            const res = await axios.get(`${BASE_URL + url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })
            if(res.data.code === "error"){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoading(false)
                setPocketData(res.data.data.content[0])
                setPocketOwnerData(res.data.data.content[0].pocketOwner)
                console.log("PocketOverview_Res:", res.data.data.content)
            }
            return res;
        }catch(error){
            setLoading(false)  
            setErrorState(true)
            handleException(error)
        }
    }

    const fetchPocketTransactions = useCallback(async () => {
        setLoadingTransactions(true)
        try{
            const res = await axios.get(`${BASE_URL + pocketTransaction_url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoadingTransactions(false)
                setPocketTransactions(res.data.data.content)
                console.log("PocketTransactions:", res.data.data.content)

                setTotalElements(res.data.data.totalElements)
                setTotalPages(res.data.data.totalPages)
            }
            return res;
        }catch(error){
            setLoadingTransactions(false)  
            setTransactionsErrorState(true)
            handleException(error)
        }
    }, [pocketTransaction_url])

    useEffect(() => {
        fetchPocketFunc()
    }, [])

    useEffect(() => {
        fetchPocketTransactions()
    }, [fetchPocketTransactions])

    const getCurrentPage = (pageNumber) => {
        setPageNum(pageNumber)
        setCurrentPage(pageNumber)
    }

    const viewTransaction = (id) => {
        setShowModal(prevState => !prevState)
        const transactionFilter = pocketTransactions.filter(item => item.transactionId === id)
        const transactionItem = transactionFilter[0]
        setTransactionDetails(transactionItem)  
    }

    // handleMouseEnter={() => storeNavKey(`/dashboard/pockets/pocket_overview/${data.pocketId}`)} 

    const columns = [
        {
            label: "Reference ID",
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)}
                                >
                                    <CopyText 
                                        text={data.reference} 
                                        textStyle="text-opacity-70 text-xs" 
                                        style='px-3 py-1' 
                                        mssgStyle='-right-8' 
                                        imgStyle="w-4 h-4"
                                    />
                                </TableDataSpan>
        },
        {
            label: 'Transaction Type',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={data.transactionLeg} 
                                    additionalClass={`text-opacity-70 text-xs px-3 py-1 rounded-2xl cursor-pointer
                                        ${data.transactionLeg === 'CREDIT' ? 'bg-Green_Accent text-Green_Accent2' : 'bg-DangerAccent3 text-Danger2'}
                                    `}
                                />
        },
        {
            label: 'Date',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} 
                                    additionalClass='text-opacity-70 text-xs'
                                />
        },
        {
            label: 'Narration',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={data.narration.length < 30 ? data.narration : data.narration.slice(0,30)+"..."} 
                                    additionalClass='text-opacity-70 text-xs'
                                />
        },
        {
            label: 'Amount',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={data.transactionCurrency + " " + (Intl.NumberFormat().format(data.transactionAmount))} 
                                    additionalClass='text-opacity-70 text-xs'
                                />
        },
        {
            label: 'Status',
            render: (data) => <TableDataSpan onClick={()=>viewTransaction(data.transactionId)}>
                                <div>
                                    <span className={`w-1 h-1 p-1 inline-block mr-2 rounded-full
                                        ${data.message === 'Success'
                                        ? 'bg-Success'
                                        : data.message === 'Failed'
                                        ? 'bg-Danger'
                                        : data.message === 'Pending'
                                        ? 'bg-Yellow'
                                        : ''
                                    }`}></span>
                                    <span className='text-opacity-70 text-xs'>{data.message}</span>
                                </div>
                              </TableDataSpan>
        }
    ]
    
    const overviewData = [
        {title: "Available balance", amount: pocketData.availableBalanceAmount},
        {title: "Amount sent", amount: 100},
        {title: "Amount received", amount: 100},
        {title: "Transfer charge", amount: 100},
        {title: "Sub accounts", amount: 100}
    ]

    console.log("pocketData:", pocketData);
    

    const pocketDetails = (
        <div className='w-[450px] mobile:w-[280px] desktop:p-8 mobile:p-4'>
            <div className='flex flex-col mb-10'>
                <span className='flex justify-between items-center'>
                    <PageTitle
                        pageTitle='Pocket Account Details'
                    />
                    <span className='cursor-pointer'>
                        <img src={closeIcon} alt="closeIcon" className='' onClick={() => setDropdown(prevState => !prevState)}/>
                    </span> 
                </span>
                <span className='text-left font-normal text-[15px] leading-7 text-SecondaryAccent'>View the details of your pocket account</span>
            </div>
            <div className='border-t py-5 px-1 flex flex-col gap-6 mb-10'>
                <span className='flex justify-between items-center'>
                    <p className='font-normal text-xs leading-[14.4px] text-GrayCustom2'>Pocket ID</p>
                    <p className='font-semibold text-base leading-5 text-DarkBg'>{pocketData.pocketId}</p>
                </span>
                <span className='flex justify-between items-center'>
                    <p className='font-normal text-xs leading-[14.4px] text-GrayCustom2'>Pocket account number</p>
                    <p className='font-semibold text-base leading-[16.8px] text-DarkBg8'>{pocketData.accountNumber}</p>
                </span>
                <span className='flex justify-between items-center'>
                    <p className='font-normal text-xs leading-[14.4px] text-GrayCustom2'>Status</p>
                    <ColorTag 
                        styleColor={`${pocketData.status === 'ACTIVE' ? 'bg-Green_Accent4 border-Green_Accent' : 'bg-DangerAccent4 border-Danger' } rounded-md px-3 py-1 flex gap-1 items-center`} 
                        text={pocketData.status} 
                        textColor={`${pocketData.status === 'ACTIVE' ? 'text-Green_Accent2' : 'text-Danger'} font-semibold text-sm leading-5`} 
                        BgColor={`${pocketData.status === 'ACTIVE' ? 'bg-Green_Accent2' : 'bg-Danger'}`}
                    />
                </span>
            </div>
            <Button 
                btnType='button'
                btnText='Close'
                btnStyle='bg-DarkBg3 text-white text-[15px] leading-7 font-normal w-full py-2'
                onClick={() => setDropdown(!dropdown)}
            />
        </div>
    )

    return (
        <>    
            <div className='flex flex-col'>
                <div className='flex flex-wrap justify-between gap-4 items-center mb-5'>
                    <PageTitle
                        pageTitle={pocketOwnerData.businessName}
                        pageTitleStyle='text-left'
                    />
                    <Dropdown
                        component={pocketDetails}
                        style="right-0 top-12 mobile:-right-[100%] rounded-lg absolute"
                        dropdown={dropdown}
                    >
                        <Button
                            btnType='button'
                            btnText="Sub Pocket details"
                            btnImg={infoIcon}
                            btnImgStyle="desktop:w-6 desktop:h-6 mobile:w-4 mobile:h-4"
                            btnStyle='bg-DarkBg3 text-SecondaryAccent5 px-4 py-2 flex-row-reverse desktop:text-sm mobile:text-xs font-semibold leading-5'
                            onClick={() => setDropdown(prevState => !prevState)}
                        />
                    </Dropdown>
                </div>
                <div className='flex flex-col mb-8'>
                    <span className='font-normal text-base leading-7 text-SecondaryAccent6'>
                        Business Overview
                    </span>
                    <span className='grid mobile:flex desktop:grid-cols-5 tablet:grid-cols-2 mobile:flex-col gap-3 tablet:flex-wrap'>
                        {overviewData.map((item, i) => (
                            <PaymentCard 
                                key={i}
                                title={item.title}
                                currency={pocketData.availableBalanceCurrency}
                                totalAmount={Intl.NumberFormat().format(item.amount)}
                            />
                        ))}
                    </span>
                </div>
                <div className=''>
                    <PageHeader
                        pageTitle='Transactions'
                        pageTitleStyle='text-lg font-semibold leading-7 text-black'
                        exportTable={true}
                        tableKeys={searchKeys}
                        queryValue={query}
                        setQuery={setQuery}
                        setQueryKey={setQueryKey}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        tableHeaders={PocketTransactionHeader}
                        tableData={pocketTransactions}
                        csvUrl={defaultURl}
                    />
                    <AppTable
                        data={pocketTransactions}
                        columns={columns}
                        loading={loadingTransactions}
                        errorState={errorState}
                        itemsPerPage={recordsPerPage}

                        dataLength={totalElements}
                        getCurrentPage={getCurrentPage}
                    />
                </div>
            </div>
            {showModal && 
                <SideComponent
                    toggleModal={() => setShowModal(prevState => !prevState)}
                    data={transactionDetails}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    modalState: state.app.modalState,
    bearerToken: state.auth.token,
    publicKey: state.auth.public_key
})
  
const mapDispatchToProps = (dispatch) => ({
    toggleModal: () => dispatch(toggleModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(SubaPocketOverview)
