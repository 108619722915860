import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'

const AuthHeader = () => {
    const location = useLocation()
    const navigate = useNavigate()
    
    const returnBtn = () => {
        navigate(-1)
    }

    const isLogin = location.pathname === "/auth/login" ? 'hidden' : null

    return (
        <div className='flex items-center gap-5'>
            <span className={`desktop:w-6 ${isLogin ? "mobile:w-4" : ""}`}>
                <BsArrowLeft className={`${isLogin} cursor-pointer`} onClick={returnBtn}/>
            </span>
            <img className='desktop:w-28 desktop:h-4 w-14 mobile:h-2' src="https://assets.seerbitapi.com/images/seerbit_logo_type.png" alt="logo"/>
        </div>
    )
}

export default AuthHeader