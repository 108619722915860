import React from 'react'
import AppModal from 'components/organisms/CustomModal'
import copyIcon from '../../../assets/svg/copy.svg'
import Button from 'components/atoms/Button'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const SideComponent = (props) => {
    const navigate = useNavigate()

    console.log("props.data:", props.data)

    return (
        <AppModal handleClose={props.toggleModal} modalStyle="desktop:w-1/4 mobile:w-3/4 bg-white min-h-screen z-30 top-0 right-0 animate-slide_left p-8" contentStyle="overflow-y-scroll h-[92vh]">
            <div className='flex flex-col text-left mb-8'>
                <span className='font-normal text-2xl mobile:text-lg text-black mb-5'>Transaction Overview</span>
                <span className='text-[14px] mobile:text-xs leading-7 text-SecondaryAccent'>View the details of the transaction.</span>
            </div>
            <div>
                <div>
                    <span className='text-base mobile:text-sm leading-7 text-SecondaryAccent'>Amount</span>
                </div>
                
                <div className='flex gap-5 items-center'>
                    <span className='font-bold text-[28px] mobile:text-xl leading-7 text-DarkBg3'>{props.data.transactionCurrency + " " + (Intl.NumberFormat().format(props.data.transactionAmount))}</span>
                    {/* {props.data} */}
                    <span className='bg-Green_Accent4 border-2 border-Green_Accent rounded-full px-2 py-1 text-Green_Accent5 text-xs leading-[15.6px] font-semibold'>Successful</span>
                </div>
                
                <div className='mb-12 mt-8 flex flex-col gap-3'>
                    <span className='text-[15px] font-normal leading-7 text-DarkBg3'>{moment(props.data.createdAt).format("MMM DD, YYYY - LTS")}</span>
                    <span className='flex gap-5 items-center'>
                    <span className='text-xs leading-5 font-normal text-DarkBg px-3 py-1 rounded-md bg-SecondaryAccent3 overflow-auto'>{props.data.reference}</span>
                    <img src={copyIcon} alt="copyIcon" className='w-4 h-4'/>
                    </span>
                </div>
                
                <div className='flex flex-col mb-10'>
                    <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] mobile:text-sm leading-6 text-DarkBg pb-4 mb-5'>
                    Payment Breakdown 
                    </span>
                    <div className='flex flex-col gap-5'>
                    <span className='w-full flex justify-between'>
                        <p className='font-normal text-xs leading-3 text-Black2'>Transaction  Amount</p>
                        <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.transactionCurrency + " " + (Intl.NumberFormat().format(props.data.transactionAmount))}</p>
                    </span>
                    <span className='w-full flex justify-between'>
                        <p className='font-normal text-xs leading-3 text-Black2 mb-3'>Trannsaction fee</p>
                        <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.transactionCurrency} 5.00</p>
                    </span>
                    </div>
                </div>

                <div className='flex flex-col mb-10'>
                    <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] mobile:text-sm leading-6 text-DarkBg pb-4 mb-5'>
                        Other Information - Pay by transfer
                    </span>
                    <div className='flex flex-col gap-5'>
                    <span className='w-full flex justify-between'>
                        <p className='font-normal text-xs leading-3 text-Black2'>Expected Amount</p>
                        <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.transactionCurrency + " " + (Intl.NumberFormat().format(props.data.transactionAmount))}</p>
                    </span>
                    <span className='w-full flex justify-between'>
                        <p className='font-normal text-xs leading-3 text-Black2 mb-3'>Transferred Amount</p>
                        <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.transactionCurrency + " " + (Intl.NumberFormat().format(props.data.transactionAmount))}</p>
                    </span>
                    </div>
                </div>

                <div className='mb-5 flex flex-col gap-5'>
                    {/* <span className='text-lg font-semibold leading-[21.6px] mobile:text-sm'>
                    Analysis
                    </span> */}
                    <Button
                    btnType='button'
                    btnText="See detailed overview"
                    btnStyle='bg-SecondaryAccent3 text-[15px] font-normal leading-7 text-black w-full py-3'
                    onClick={() => {
                        navigate(`/dashboard/transactions/transaction_overview/${props.data.reference}`)
                        props.toggleModal()
                    }}
                    />
                </div>
                <Button
                    btnType='button'
                    btnText="Close"
                    btnStyle='bg-DarkBg3 text-[15px] font-normal leading-7 text-white w-full py-3 '
                    onClick={props.toggleModal}
                />
            </div>
        </AppModal>
    )
}

export default SideComponent
