import React, { useCallback, useEffect, useState } from 'react'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import moment from 'moment'
import { getRequest } from 'services/http'
import Alert from 'components/atoms/Alert'
import { handleException } from 'utils'
import { getValues } from 'services/storage'

const UserManagement = () => {
    const [loading, setLoading] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [users, setUsers] = useState([])
    const [recordsPerPage] = useState(10)
    const [totalElements, setTotalElements] = useState(null)
    const [totalPages, setTotalPages] = useState()
    const [pageNum, setPageNum] = useState(null)

    const username = getValues('username')
    const url = `users?&size=${recordsPerPage}&page=${pageNum === null ? 0 : pageNum}`

    const getCurrentPage = (pageNumber) => {
        setPageNum(pageNumber)
    }

    const fetchUsers = useCallback(() => {
        setLoading(true)
        // getRequest(url)
        // .then(async res => {
        //     if(res.data.code === 'error'){
        //         setLoading(false)
        //         Alert('error', res.data.message)
        //         Alert('error', 'res.data.message')
        //     }else{
        //         setLoading(false)
        //         setUsers(res.data.data.content)
        //         // console.log("USER RES:", res.data.data.content);
                
        //         setTotalElements(res.data.data.totalElements)
        //         setTotalPages(res.data.data.totalPages)
        //     }
        // })
        // .catch(error => {
        //     setLoading(false)  
        //     setErrorState(true)
        //     handleException(error)
        // })
    }, [])

    useEffect(() => {
        username?.includes('admin@seerbit.com') && fetchUsers()
    }, [fetchUsers])

    const columns = [
        {
          label: 'Date Created',
          render: (data) => <TableDataSpan text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} additionalClass='text-opacity-70 text-xs'/>
        },
        {
          label: 'Username',
          render: (data) => <TableDataSpan additionalClass='text-opacity-70 text-xs' text={data.username}/>
        },
        {
          label: 'Firstname',
          render: (data) => <TableDataSpan additionalClass='text-opacity-70 text-xs' text={data.firstName}/>
        },
        {
          label: 'Lastname',
          render: (data) => <TableDataSpan additionalClass='text-opacity-70 text-xs' text={data.lastName}/>
        },
        {
          label: 'Actions',
          render: (data) => <>
                              {/* <TableDataSpan onClick={() => navigate(`/dashboard/settings/user_management/${data.username}`)} text="Change Role - " additionalClass='text-opacity-70 text-xs cursor-pointer'/> */}
                              <TableDataSpan onClick={() => {}} text="View User Detail - " additionalClass='text-opacity-70 text-xs cursor-pointer'/>
                              <TableDataSpan onClick={()=>{}} text="Remove" additionalClass='text-opacity-70 text-[10px] text-GrayCustom9 cursor-pointer'/>
                            </>
        }
    ]

    return (
        <>
            {username?.includes('admin@seerbit.com')    &&
                <AppTable
                    data={users}
                    columns={columns}
                    loading={loading}
                    errorState={errorState}
                    itemsPerPage={recordsPerPage}
    
                    dataLength={totalElements}
                    getCurrentPage={getCurrentPage}
                />
            }
            {/* {modalState && sideContent} */}
        </>
    )
}

export default UserManagement