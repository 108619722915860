import {
    TOGGLE_APP_ENV,
    TOGGLE_MODAL,
    TOGGLE_DATA_VIEW,
    SELECT_NAV_ITEM,
    STORE_NAV_KEY,
    STORE_CURRENT_PATH,
    SET_BASE_URL,
    SET_LANDING_PAGE_DATA
} from './app.type'

const APP_INIT_STATE = {
    appEnv: true,
    modalState: false,
    dataView: false,
    selectedNavItem: 'home',
    navKey: '',
    currentPath: '',
    base_url: '',
    landingPage_data: null
}

const AppReducer = (state = APP_INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_APP_ENV:
            return {
                ...state,
                appEnv: !state.appEnv
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                modalState: !state.modalState
            }
        case TOGGLE_DATA_VIEW:
            return {
                ...state,
                dataView: !state.dataView
            }
        case SELECT_NAV_ITEM:
            return {
                ...state,
                selectedNavItem: action.payload
            }
        case STORE_NAV_KEY:
            return {
                ...state,
                navKey: action.payload
            }
        case STORE_CURRENT_PATH:
            return {
                ...state,
                currentPath: action.payload
            }
        case SET_BASE_URL:
            return {
                ...state,
                base_url: action.payload
            }
        case SET_LANDING_PAGE_DATA:
            return {
                ...state,
                landingPage_data: action.payload
            }
        default:
            return state
    }
}

export default AppReducer