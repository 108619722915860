import React from 'react'

const ColorTag = ({
    styleColor,
    text,
    textColor,
    BgColor
}) => {
    return (
        <div className={`${styleColor} border flex items-center gap-1 w-fit px-2 py-[2px] rounded-xl`}>
            <span className={`${BgColor} w-1 h-1 rounded-full`}></span>
            <span className={`${textColor} semibold text-xs leading-[15.6px]`}>{text}</span>
        </div>
    )
}

export default ColorTag