import React from 'react'
import Button from 'components/atoms/Button'
import FormInput from 'components/atoms/FormInput'
import { useParams } from 'react-router-dom'
import {tabHeader, tabBody} from "../../../mockData/SettingsData"
import {roles} from '../../../mockData/Roles'
import editIcon from '../../../assets/svg/edit-grey.svg'
import helpIcon from '../../../assets/svg/help-circle_2.svg'
import TextArea from 'components/atoms/TextAreaInput/TextArea'
import PageTitleHeader from 'components/molecules/PageTitleHeader'

const RoleManager = () => {
  // const roleId = useParams()
  // const roleItem = roles.filter(item => item.role === roleId.id)
  // // const role = roleItem[0]
  

  // console.log("RoleID:", roleId);
  
  const multipleBtnData = [
    {btnText:"Manage permissions", btnStyle:"bg-DarkBg3 px-10 py-2 font-semibold desktop:text-sm mobile:text-xs text-white truncate", btnImg:"", btnImgStyle:"", onClick:()=>{}}
  ]

  return (
    <div className='py-10'>
      <PageTitleHeader 
          // title={role.role}
          title="Role"
          subText="This role grants access to members assisting customers like answering queries and resolving issues."
          subTextStyle="font-normal text-[15px] leading-7"
          btn_num={1}
          btnData={multipleBtnData} 
      />

      <div className='grid grid-cols-2 desktop:grid-cols-3 mobile:grid-cols-1 gap-8 justify-between mt-20 desktop:mt-8'>
        <div className='flex flex-col gap-6 desktop:mr-14 w-full'>
          <span className='font-semibold text-lg leading-7'>
            Role details
          </span>
          <FormInput 
            type="text" 
            name="role_title" 
            label="Role title"
            labelStyle="font-normal text-sm leading-5 text-DarkBg6"
            img={editIcon}
            imgStyle="w-4 h-4"
            inputStyle="border bg-white flex-row-reverse"
            // placeholder={role.role} 
            placeholder="Role" 
            onChange={()=>{}}     
          />
          <TextArea
            name="role_desc"
            label="Role description"
            labelStyle="font-normal text-sm leading-5 text-DarkBg6"
            placeholder="This role grants access to members assisting customers like answering queries and resolving issues."
            // value={}
            img={editIcon}
            imgStyle="w-4 h-4"
            // style,
            inputStyle="flex-row-reverse"
            onChange={()=>{}}
          />
          <div className='flex flex-col gap-3'>
            <span className='flex items-center text-DarkBg6 font-normal text-sm leading-4 '>Team members with this role <img src={editIcon} alt="dull_editIcon" className='ml-3 w-4 h-4'/></span>
            <span className='flex flex-col'>
              <span className='border-b border-SecondaryAccent2 p-1 pl-2 font-normal text-[15px] leading-7'>Tola Sambo</span>
              <span className='border-b border-SecondaryAccent2 p-1 pl-2 font-normal text-[15px] leading-7'>Edikan David</span>
              <span className='border-b border-SecondaryAccent2 p-1 pl-2 font-normal text-[15px] leading-7'>Okeowo Hameed</span>
            </span>
          </div>
          <div className=''>
            <form onSubmit={e => e.preventDefault()} className="flex flex-col gap-3">
            <span className='flex items-center text-DarkBg6 font-normal text-sm leading-4 '>Invite a new member</span>
              <FormInput 
                type="text" 
                name="role_title"
                img={helpIcon}
                imgStyle="w-4 h-4"
                inputStyle="border bg-white flex-row-reverse"
                placeholder="Enter new user ID"
                onChange={()=>{}}     
              />
              <Button
                btnType='submit'
                btnText="Send"
                btnStyle='bg-DarkBg3 text-SecondaryAccent5 px-4 py-2 font-semibold text-sm leading-[22px] w-fit tablet:w-full'
                onClick={()=>{}}
              />
            </form>
          </div>
        </div>

        <div className='w-full'>
          <div className='border border-Green_Accent3 rounded-md'>
            <div className='border-b border-Success p-3'>
              <span className='font-normal text-lg leading-[21.6px]'>Available Permissions for this Role</span>
            </div>
            <div className='flex flex-col gap-2'>
              <span className='font-normal text-sm leading-[16.8px] text-Black2 px-4 py-3'>This role operator can</span>
            </div>
          </div>
        </div>

        <div className='tablet:hidden w-full'>
          <div className='border border-DangerAccent rounded-md'>
            <div className='border-b border-Danger3 p-3'>
              <span className='font-normal text-lg leading-[21.6px]'>Unavailable Permissions for this Role</span>
            </div>
            <div className='flex flex-col gap-2'>
              <span className='font-normal text-sm leading-[16.8px] text-Black2 px-4 py-3'>This role operator cannot access</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleManager