import Button from 'components/atoms/Button'
import CopyText from 'components/atoms/CopyText'
import Toggle from 'components/atoms/Toggle'
import React from 'react'
import { connect } from 'react-redux'
import { toggleAppEnv } from 'redux/app/app.action'

const WebHooks = ({appEnv, toggleAppEnv}) => {
  return (
    <div className='desktop:max-w-[604px] w-full tablet:pb-16'>
      <div className='flex flex-col gap-1 mb-12 max-w-[350px] mobile:w-full'>
        <span className='text-lg font-normal text-black leading-5'>
          Webhooks
        </span>
        <span className='text-[15px] font-normal leading-5 text-DarkBg6'>   
          Connect API webhooks to notify your application when certain payment, inventory, or timecard events occur.
        </span>
      </div>

      <div className='w-full flex flex-col gap-16'>
        <div className='flex flex-col gap-5'>
          <div className='flex justify-between items-center'>
            <span className='font-semibold text-sm leading-[14.4px] text-DarkBg6'>Live Webhook</span>
            <Toggle 
              handleChange={toggleAppEnv} 
              checked={appEnv} 
              style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
            /> 
          </div>
          <CopyText 
            text="Test public key"
            textStyle="!px-4 !py-3 bg-Background3 rounded-md !w-full text-opacity-70 text-sm mr-2 inline-block item-center text-Accent_blue leading-[14.4px]"  
            mssgStyle='-right-16 ' 
            imgStyle="w-5 h-5"
            style="!w-full"
          />
          <div className='flex justify-end items-center'>
            <Button 
              btnType='button'
              btnText='Reset keys'
              btnStyle='bg-black w-fit text-white font-semibold text-sm text-GrayCustom8 px-4 py-3'
            />
          </div>
        </div>

        <div className='flex flex-col gap-5'>
          <div className='flex justify-between items-center'>
            <span className='font-semibold text-sm leading-[14.4px] text-DarkBg6'>Test Webhook</span>
            <Toggle 
              handleChange={toggleAppEnv} 
              checked={appEnv} 
              style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
            /> 
          </div>
          <CopyText 
            text="Test public key"
            textStyle="!px-4 !py-3 bg-Background3 rounded-md !w-full text-opacity-70 text-sm mr-2 inline-block item-center text-Accent_blue leading-[14.4px]"  
            mssgStyle='-right-16 ' 
            imgStyle="w-5 h-5"
            style="!w-full"
          />
          <div className='flex justify-end items-center'>
            <Button 
              btnType='button'
              btnText='Reset keys'
              btnStyle='bg-black w-fit text-white font-semibold text-sm text-GrayCustom8 px-4 py-3'
            />
          </div>
        </div>
      </div>
    </div>
  ) 
}

const mapStateToProps = state => ({
  appEnv: state.app.appEnv
})

const mapDispatchToProps = dispatch => ({
  toggleAppEnv: () => dispatch(toggleAppEnv())
})

export default connect(mapStateToProps, mapDispatchToProps)(WebHooks)