import React, {useEffect, useState} from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

type Props = {
    title:string, 
    active:boolean,
    link:string,
    onClick: (e) => void
}

const SettingsNavItem: React.FC<Props> = ({title, active, link, onClick}) => {
    const [style, setStyle] = useState(active)
    
    const LoadItemStyle = (isActive) => {
        const standardStyle = `flex flex-col gap-3 justify-center cursor-pointer font-normal desktop:text-[15px] desktop:leading-7 tablet:text-xs tablet:leading-4`
        const activeClassName = `text-bold`

        setStyle(isActive)
        
        if(isActive){
            return `${activeClassName + standardStyle} text-DarkBg3`
        }else{
            return `${standardStyle} text-Black2`
        }
    }
    
    return (
        <NavLink to={link} className={({ isActive }) => LoadItemStyle(isActive)} onClick={onClick}>
            <span className="truncate">{title}</span>
            <span className={`${style && "p-[3px]"} flex justify-center`}>
                {style && <div className='p-[1px] border border-DarkBg3 bg-DarkBg3 rounded-t-xl desktop:w-[85%] tablet:w-full'></div>}
            </span>
        </NavLink>
    )
}

export default SettingsNavItem