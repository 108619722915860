import React from 'react'

type Props = {
    title: string
    totalAmount: any
    currency?: string
    ammountPosition?: any
}

const PaymentCard: React.FC<Props> = ({title, totalAmount, currency, ammountPosition}) => {
    const pocketCurrency = currency === "NGN" ? "₦" 
                        :  currency === "GHS" ? "¢"
                        :  currency === "XOF" ? ""
                        :  currency === "USD" ? "$"
                        :  ""
    return (
        // <div className='flex flex-col gap-5 justify-between border p-5 w-fit mobile:w-full rounded-lg desktop:min-w-[300px] min-w-[240px] max-w-[314px] mobile:max-w-full flex-shrink-0 hover:shadow-md'>
        <div className='flex flex-col gap-5 justify-between border p-5 w-full tablet:min-w-[240px] rounded-lg flex-shrink-0 hover:shadow-md'>
            <span className={`${ammountPosition}`}>
                <p className='font-semibold text-3xl text-black'><span className='text-2xl'>{pocketCurrency}</span> {Intl.NumberFormat().format(totalAmount)}</p>
            </span>
            <span className=''>
                <p className='text-base font-normal leading-[30px] text-SecondaryAccent'>{title}</p>
            </span>
        </div>
    )
}

export default PaymentCard