import React from 'react'

export const SubPocketData = () => {
    const ownerOptData = [
        {label: "Self", value: 'self'},
        {label: "3rd Party", value: 'not_self'}
    ]

    const existingPocketData = [
        {label: "Yes", value: 'yes'},
        {label: "No", value: 'no'}
    ]

    const currencyData = [
        {title: "NGN", value: "NGN", link: null, action: () => null},
        {title: "USD", value: "USD", link: null, action: () => null},
        {title: "GHS", value: "GHS", link: null, action: () => null},
        {title: "XOF", value: "XOF", link: null, action: () => null}
    ]

    const pocketFunctionData = [
        {title: 'Credit only', value: 'CREDIT', link: null, action: () => null},
        {title: 'Debit only', value: 'DEBIT', link: null, action: () => null},
        {title: 'Both', value: 'BOTH', link: null, action: () => null}
    ]

    const modeOfIdData = [
        {title: 'Bank Verification Number', value: 'BVN', link: null, action: () => null},
        {title: 'National Identification Number', value: 'NIN', link: null, action: () => null},
    ]

    return {ownerOptData, currencyData, pocketFunctionData, modeOfIdData, existingPocketData}
}