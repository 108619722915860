import React, {useState} from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

type Props = {
    children: JSX.Element,
    data?: any[], 
    component?: JSX.Element,
    style?: string,
    containerStyle?: string,
    iconStyle?: string,
    textStyle?: string,
    dropdown: boolean
    handleSelect?: (searchLabel: string) => void
    setDropdown?: (dropdownState: boolean) => void
}

const Dropdown: React.FC<Props> = ({children, data, component, style, containerStyle, iconStyle, textStyle, dropdown, handleSelect, setDropdown}) => {
    const navigate = useNavigate()

    const actionFunction = (item, handleSelect) => {
        handleSelect(item)
        setDropdown && setDropdown(false)
    }

    return (
        <div className={`${containerStyle} relative flex items-center gap-3 border-SecondaryAccent2 rounded-lg`}>
            {children}
            {dropdown &&
                <div className={`${style} flex flex-col rounded p-2 gap-2 shadow-md z-40 border bg-white`}>
                {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                        <div key={index} className={`${item.style} flex gap-3 hover:bg-SecondaryAccent5 hover:transition-opacity p-2 cursor-pointer rounded-md w-full`}>
                            {item.icon &&
                                <span className='flex justify-center items-center  flex-shrink-0'>
                                    <img src={item.icon} alt="icon" className={`${iconStyle}`}/>
                                </span>
                            }
                            {item.title &&
                                <span className={`${textStyle} flex justify-start items-center font-normal leading-5`} onClick={item.link !== "" ? (() => navigate(`${item.link}`)) : item.action}>
                                    {item.title}
                                </span>
                            }
                            {!item.title && 
                                <span className={`${textStyle} flex justify-start items-center font-normal leading-5 w-full truncate`} onClick={() => actionFunction(item, handleSelect)}>
                                    {item}
                                </span>
                            }
                            {!item.title && item.label && item.link === 'null' &&
                                <span className={`${textStyle} flex justify-start items-center font-normal leading-5 w-full truncate`} onClick={() => actionFunction(item, handleSelect)}>
                                    {item.label}
                                </span>
                            }
                        </div>
                    ))
                }
                {component &&
                    component
                }
                </div>
            }
        </div>   
    )
}

export default Dropdown