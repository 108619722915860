import React, {useCallback, useEffect, useRef, useState} from 'react'
import PageHeader from 'components/molecules/PageHeader'
import PocketCard from 'components/molecules/PocketCard'
import Pagination from '../../../components/organisms/app-table/Pagination'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from 'services/http'
import Alert from 'components/atoms/Alert'
import CopyText from 'components/atoms/CopyText'
import ColorTag from 'components/atoms/ColorTag'
import Spinner from '../../../components/atoms/Spinner'
import { ToCamelCase } from 'components/atoms/CaseManager'
import moment from 'moment'
import { handleException } from '../../../utils'
import axios from 'services/axios'
import { MerchantPocket } from 'components/molecules/tableExport'
import { storeNavKey } from 'redux/app/app.action'
// import { store } from 'redux/store'

// const state = store.getState()
// console.log("State:", state);

const searchKeys = [
  {title:"businessName", value: null, link: null, action: () => null},
  {title:"pocketId", value: null, link: null, action: () => null}, 
  {title:"accountNumber", value: null, link: null, action: () => null},
  {title:"availableBalanceAmount", value: null, link: null, action: () => null},
  {title:"reference", value: null, link: null, action: () => null},
  {title:"parentId", value: null, link: null, action: () => null}
].sort()

const SubPockets = ({dataView, bearerToken, publicKey, storeNavKey, pocketId}) => {
  const [query, setQuery] = useState("")
  const [queryKey, setQueryKey] = useState("")
  const [loading, setLoading] = useState(true)
  const [errorState, setErrorState] = useState(false)
  const [pocketData, setPocketData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalElements, setTotalElements] = useState()
  const [totalPages, setTotalPages] = useState(Number)
  const [pageNum, setPageNum] = useState(null)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const recordsPerPage = useRef(0)
  const navLink = useRef('')

  const navigate = useNavigate()

  if(dataView){
    recordsPerPage.current = 10
  }else{
    recordsPerPage.current = 16
  }

  const defaultUrl = `search?pocket-id/${pocketId}`

  const url =  query.length !== 0 && (!startDate || !endDate)
            ? `${defaultUrl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}`
            
            : query.length !== 0 && startDate !== "" && endDate !== ""
            ? `${defaultUrl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : (startDate && endDate === "")
            ? `${defaultUrl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}`

            : (startDate === "" && endDate)
            ? `${defaultUrl}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : (startDate && endDate)
            ? `${defaultUrl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : `${defaultUrl}`
            // : `search?reference=primary-`

  const mainURL = `${url}&size=${recordsPerPage.current}&page=${pageNum === null ? 0 : pageNum}`

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber)
    setCurrentPage(pageNumber)
  }

  const fetchDataFunc = useCallback(async (URL: string) => {
    setLoading(true)

    try{
      const res = await axios.get(`${BASE_URL + URL}` , {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`
        }
      })
      if(res.data.code === 'error'){
        setLoading(false)
        Alert("error", res.data.message);
      }else{
        setLoading(false)
        const contentData = res.data.data.content.filter(item => item.parentId !== null)
        console.log("SubPocket_data:", contentData)
        setPocketData(contentData)
        setTotalElements(res.data.data.totalElements)
        setTotalPages(res.data.data.totalPages)
      }
    }catch(error: any) {
      setLoading(false)  
      setErrorState(true)
      handleException(error)
    }
  }, [mainURL])

  useEffect(() => {
    fetchDataFunc(mainURL)
  }, [fetchDataFunc, mainURL])

  const handlePageChangeGrid = (pageNumber) => {
    setCurrentPage(pageNumber);
    getCurrentPage(pageNumber)
    setLoading(false)
  }

  const columns = [
    {
      label: 'Date Created',
      render: (data) => <TableDataSpan 
                          handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)} 
                          text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} 
                          additionalClass='text-opacity-70 text-xs'
                        />
    },
    {
      label: 'Business Name',
      render: (data) => <TableDataSpan 
                          handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}
                          text={data.pocketOwner.businessName} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer' 
                        />
    },
    {
      label: 'Pocket Balance',
      render: (data) => <TableDataSpan 
                          handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}
                          text={data.availableBalanceCurrency + " " + (Intl.NumberFormat().format(data.availableBalanceAmount))} 
                          additionalClass='text-opacity-70 text-xs'
                        />
    },
    {
      label: 'Pocket ID',
      render: (data) =>  <TableDataSpan handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}>
                                <CopyText 
                                  text={data.pocketId}
                                  textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                                  mssgStyle='-right-3 -top-[18px]' 
                                  imgStyle="w-4 h-4"
                                />
                              </TableDataSpan>
    },
    {
      label: 'Pocket Account Number',
      render: (data) => <TableDataSpan handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}>
                          <CopyText 
                            text={data.accountNumber}
                            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                            mssgStyle='-right-3 -top-[18px]' 
                            imgStyle="w-4 h-4"
                          />
                        </TableDataSpan>
    },
    {
      label: 'Pocket Reference',
      render: (data) => <TableDataSpan handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}>
                          <CopyText 
                            text={data.reference}
                            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                            mssgStyle='-right-3 -top-[18px]' 
                            imgStyle="w-4 h-4"
                          />
                        </TableDataSpan>
    },
    {
      label: 'Category', 
      render: (data) => <TableDataSpan 
                          handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}                    
                          text={data.parentId === null ? 'Parent' : 'Child'} 
                          additionalClass={`text-opacity-70 text-xs px-3 py-1 rounded-2xl ${
                            data.parentId === null ? 'bg-DangerAccent3 text-Danger2' : 'bg-SecondaryAccent7 text-Accent_blue'
                          }`}
                        />
    },
    {
      label: 'Pocket Status',
      render: (data) =>  <TableDataSpan handleMouseEnter={() => storeNavKey(`/dashboard/sub_pockets/sub_pocket_overview/${data.pocketId}`)}>
                            <ColorTag 
                              styleColor={`${data.status === 'ACTIVE' ? 'bg-Green_Accent4 border-Green_Accent' : 'bg-DangerAccent4 border-Danger' } rounded-md px-3 py-1 flex gap-1 items-center`} 
                              text={data.status} 
                              textColor={`${data.status === 'ACTIVE' ? 'text-Green_Accent2' : 'text-Danger'} font-semibold text-sm leading-5`} 
                              BgColor={`${data.status === 'ACTIVE' ? 'bg-Green_Accent2' : 'bg-Danger'}`}
                            />
                          </TableDataSpan>
    }
  ]

  return (
    <>
      <PageHeader
        pageTitle="Sub pockets"
        exportTable={true}
        manageTableData={true}
        manageTableBtnText="Create Sub pocket"
        manageTableBtnAction={() => navigate("/dashboard/sub_pockets/create_sub_pocket")}
        switchView={true}
        tableKeys={searchKeys}
        queryValue={query}
        setQuery={setQuery}
        setQueryKey={setQueryKey}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        tableHeaders={MerchantPocket}
        tableData={pocketData}
        csvUrl={defaultUrl}
      />
      { dataView === true
        ? <div className={`relative flex flex-col justify-between pt-5 ${pocketData.length < 10 ? 'min-h-[15vh]' : 'min-h-[60vh]'} tablet:max-h-[700px]`}>
            {/* <div className={`${pocketData.length < 10 ? 'min-h-[15vh]' : 'min-h-[60vh]'} tablet:max-h-[700px] rounded-xl overflow-y-scroll custom_container gap-x-5 gap-y-5 ${pocketData !== null ? 'grid desktop:grid-cols-4 grid-cols-2 tablet:grid-cols-1 desktop:gap-x-8 ' : 'flex justify-center items-center'}`}> */}
            <div className={`${pocketData.length < 10 ? 'min-h-[15vh]' : 'min-h-[60vh]'} tablet:max-h-[700px] rounded-xl overflow-y-scroll gap-x-5 gap-y-5 ${pocketData !== null ? 'grid desktop:grid-cols-4 grid-cols-2 mobile:grid-cols-1 desktop:gap-x-8 ' : 'flex justify-center items-center'}`}>
              {loading
                ? <div className={`absolute z-10 flex items-center py-10 justify-center w-full h-[80%]`}>
                    <Spinner
                      text="Loading..."
                    />
                  </div> 
              
                : <PocketCard data={pocketData} />
              }
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages - 1}
                onPageChange={handlePageChangeGrid}
                totalData = {totalElements}
              />
            </div>
          </div>

        : <AppTable
            data={pocketData}
            columns={columns}
            loading={loading}
            errorState={errorState}
            itemsPerPage={recordsPerPage.current}

            dataLength={totalElements}
            getCurrentPage={getCurrentPage}
          />
      }
    </>
  )
}

const mapStateToProps = (state:any) => ({
  dataView: state.app.dataView,
  bearerToken: state.auth.token,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId,
})

const mapDispatchToProps = (dispatch:any) => ({
  storeNavKey: (data: any) => dispatch(storeNavKey(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubPockets)