import { ToTitleCase } from '../atoms/CaseManager'
import CopyText from 'components/atoms/CopyText'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    data: any[]
}

const ItemCard = ({data}:Props) => {
    const navigate = useNavigate()

    const cardContent = (item, rowIndex) => (
        <div key={rowIndex} className='border rounded-md shadow-sm flex gap-[23px] p-3 desktop:min-w-[230px] desktop:max-w-[314px] flex-shrink-0 relative h-fit hover:shadow-md'>
            <div className='flex items-center justify-center flex-shrink-0'>
                <div className='flex justify-center items-center rounded-full w-[50px] h-[50px] bg-DarkBg6'>
                    <span className='font-bold text-2xl text-white'>
                        {item.pocketOwner.businessName.charAt(0).toUpperCase()}
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-2 flex-shrink-0'>
                <div className='cursor-pointer hover:underline ' onClick={() => navigate(`/dashboard/sub_pockets/sub_pocket_overview/${item.pocketId}`)}>
                    <p className='text-lg leading-6 text-DarkBg'>{item.pocketOwner.businessName.length > 10 ? ToTitleCase(item.pocketOwner.businessName.slice(0, 10)) +"..." : ToTitleCase(item.pocketOwner.businessName)}</p> 
                </div>

                <CopyText 
                    text={item.reference} 
                    textStyle="text-xs leading-5 text-Accent_blue  max-w-[150px] overflow-x-scroll"  
                    mssgStyle='-right-[60px]' 
                    imgStyle="w-[12.5px] h-[12.5px]"
                    style=''
                />

                <div className='flex gap-4'>
                    <div className='flex items-center flex-shrink-0'>
                        {item.status === 'ACTIVE' 
                            ?   <>
                                    <div className='bg-Success p-1 rounded-full w-[10px] h-[10px]'></div>
                                    <span className="ml-2 text-xs leading-5 text-DarkBg3">Active</span>
                                </>
                            
                            :   <>
                                    <div className='bg-Danger p-1 rounded-full w-[10px] h-[10px]'></div>
                                    <span className="ml-2 text-xs leading-5 text-DarkBg3">In active</span>
                                </>
                        }
                        
                    </div>
                    <div className='flex flex-shrink-0'>
                        {item.parentId === null
                            ?   <span className='bg-DangerAccent3 rounded-full w-[50px] h-[22px] flex justify-center items-center text-sm leading-5 text-Danger2'>
                                    Parent
                                </span>
                            
                            :   <span className='bg-SecondaryAccent7 rounded-full w-[50px] h-[22px] flex justify-center items-center text-sm leading-5 text-Accent_blue'>
                                   Child
                                </span>
                        }    
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <>
            {data.map((item, rowIndex) => cardContent(item, rowIndex))}
        </>
    )
}

export default ItemCard