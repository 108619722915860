import React from 'react'
import Button from 'components/atoms/Button'
import PageTitle from 'components/atoms/PageTitle'
import InfoNav from 'components/organisms/InfoNav'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { createSubPocket } from '../../../../redux/Pockets/pocket.action'

const CreateSubPocket = ({createSubPocket}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const subPocketNavData = [
        {title: "Basic Info", link: "basic_info"},
        {title: "Varify Account", link: "account_verification"},
        {title: "Finish Sub Pocket", link: "finish_setup"}
    ]

    const currentPath = location.pathname.split('/').slice(-1).toString()

    console.log("currentPath:", currentPath);

    const absolutePath = '/dashboard/sub_pockets/create_sub_pocket/'

    // const handleFormSubmit = () => {
    //     console.log("Save data button fired!!!")
    // }
    
    // const handleButtonOne = () => {
    //     if(currentPath === 'basic_info'){
    //         navigate('/dashboard/sub_pockets/')

    //     }else if(currentPath === 'account_verification'){
    //         navigate(`${absolutePath}basic_info`)

    //     }else{
    //         navigate(`${absolutePath}account_verification`)
    //     }
    // }
    
    // const handleButtonTwo = () => {
    //     if(currentPath === 'basic_info'){
    //         navigate(`${absolutePath}account_verification`)
    //         createSubPocket({

    //         })

    //     }else if(currentPath === 'account_verification'){
    //         navigate(`${absolutePath}finish_setup`)

    //     }else{
    //         // navigate(`${absolutePath}account_verification`)
    //     }
    // }

    return (
        <>
            <PageTitle 
                pageTitle="Create Sub pocket" 
                pageTitleStyle="mt-5 mb-8"
            />

            <div className='border p-8 mobile:p-3 rounded-xl desktop:min-h-[75vh] min-h-[80vh] mobile:min-h-[72vh] overflow-hidden'>
                <div className='flex mobile:flex-col-reverse justify-between'>
                    <div className=''>
                        <Outlet/>
                    </div>
                    <InfoNav
                        navData={subPocketNavData}
                        navPath={absolutePath}
                        navStyle='mobile:!flex-row mobile:gap-1 overflow-scroll custom_container mb-5'
                    />
                </div>

                {/* <div className='flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]'>
                    <Button 
                        btnType="button"
                        btnText={`${currentPath === 'basic_info' ? 'Cancel' : 'Previous'}`}
                        btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                        disabled={false} 
                        disabledClass=""
                        onClick={handleButtonOne}
                    />
                    <Button 
                        btnType="button" 
                        btnText={`${currentPath === 'finish_setup' ? 'Finish' : 'Next'}`}
                        btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                        disabled={false} 
                        disabledClass=""
                        onClick={handleButtonTwo}
                    />
                </div> */}
            </div>
        </>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    createSubPocket: (data) => dispatch(createSubPocket(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubPocket)