import {
  SET_POCKET_DATA,
  SET_POCKET_DETAILS,
  CREATE_SUB_POCKET,
  CLEAR_NEW_SUB_POCKET_DATA
} from './pocket.type'

export const setPocketData = (data: any[]) => ({
  type: SET_POCKET_DETAILS,
  payload: data
})

export const setPocketDetails = (data: any[]) => ({
  type: SET_POCKET_DETAILS,
  payload: data
})

export const createSubPocket = (data: any[]) => ({
  type: CREATE_SUB_POCKET,
  payload: data
})

export const clearNewSubPocketData = ()  => ({
  type: CLEAR_NEW_SUB_POCKET_DATA,
})