import React, { useCallback, useEffect, useState } from 'react'
import {roles} from '../../../mockData/Roles'
import RoleCard from 'components/molecules/RoleCard'
import Button from 'components/atoms/Button'
import PageTitle from 'components/atoms/PageTitle'
import PageTitleHeader from 'components/molecules/PageTitleHeader'
import { BASE_URL, username } from 'services/http'
import Alert from 'components/atoms/Alert'
import Spinner from 'components/atoms/Spinner'
import ErrorEmptyState from 'components/atoms/ErrorEmptyState'
import { handleException } from 'utils'
import axios from 'services/axios'
import { connect } from 'react-redux'

const RolePermission = ({bearerToken, publicKey}) => {
    const [loading, setLoading] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [availableRoles, setAvailableRoles] = useState([])
    const url = "/role-manifest"

    const fetchRolesFunc = useCallback(async () => {
        setLoading(true)

        try{
            const res = await axios.get(`${BASE_URL + url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoading(false)
                setAvailableRoles(res.data.data)
            }
        }catch(error){
            setLoading(false)
            setErrorState(true)
            handleException(error)
        }
    }, [])
    
    useEffect(() => {
        fetchRolesFunc()
    }, [fetchRolesFunc])

    const multipleBtnData = [
        {btnText:"Create custom role", btnStyle:"bg-DarkBg3 px-10 py-2 font-semibold desktop:text-sm mobile:text-xs text-white flex-shrink-0", btnImg:"", btnImgStyle:"", onClick:()=>{}}
    ]

    return (
        <div className='pb-5 pt-2'>
            <PageTitleHeader 
                title="Roles and Permissions"
                btn_num={1}
                btnData={multipleBtnData} 
            />
            {   loading 
                ?   <Spinner
                        text="Loading..."
                    />
                :   errorState 

                // ?   <ErrorEmptyState img={false} style="!border-2 !w-auto"/>

                ?   ""

                :   <div className='grid desktop:grid-cols-3 grid-cols-2 mobile:grid-cols-1 grid-flow-row auto-rows-max desktop:gap-16 gap-5 mobile:gap-y-5'>
                        {(availableRoles as any[]).map((item, index) => (
                            <RoleCard
                                key={index}
                                role={item.code}
                                desc={item.description}
                                // icon={item.roleIcon}
                            />
                        ))}
                    </div>
            }
        </div>
    )
}

const mapStateToProps = (state:any) => ({
    bearerToken: state.auth.token,
    publicKey: state.auth.puvlic_key
})
 
export default connect(mapStateToProps)(RolePermission)

