import React from 'react'
import arrow_down from '../../../assets/svg/arrow-down.svg'
import arrow_up from '../../../assets/svg/arrow-up.svg'
import DropdownItem from './DropdownItem'

type Props = {
    children: JSX.Element,
    dropdownData: any,
    dropdownStyle?: string,
    containerStyle?: string,
    arrowIcon?: boolean,
    arrowStyle?: string,
    selector?: boolean,
    dropdown: boolean,
    setDropdown: (data?:any) => void,
    handleSelect?: (data?:any) => void
}

const Dropdown = (props: Props) => {

    const contentType = Array.isArray(props.dropdownData)

    const propArrowIcon = props.arrowIcon !== undefined ? props.arrowIcon : true;

    console.log("contentType:", contentType)

    const actionFunction = (item, handleSelect) => {
        item.value ? handleSelect(item.value) : handleSelect(item.title)
        props.setDropdown && props.setDropdown(false)
    }

    const compenentContent = (
        contentType
        ?   props.dropdownData.map((item, i) => (
                <>
                    <DropdownItem
                        key={i}
                        title={item.title ? item.title  : item}
                        icon={item.icon}
                        iconStyle="w-5 h-5"
                        link={item.link}
                        style={`${item.style} my-1`}
                        fontStyle='text-sm'
                        handleClick={props.selector ? () => actionFunction(item, props.handleSelect) : item.action}
                        toggler={props.setDropdown}
                    />
                    {console.log("Props:", item.action)}
                </>
        ))

        :   props.dropdownData
    )
    

    return (
        <div className='relative'>
            <div className={`${props.containerStyle} flex gap-3 justify-evenly items-center relative cursor-pointer`} onClick={propArrowIcon ? () => props.setDropdown(prevState => !prevState) : () => {}}>
                {props.children}
                {propArrowIcon && <img src={props.dropdown ? arrow_up : arrow_down} alt="arrow_down" className={`${props.arrowStyle}`}/>}
            </div>
            {props.dropdown &&
                <div className={`${props.dropdownStyle} ${contentType ? 'px-1' : 'p-5'} absolute z-20 bg-white rounded-md w-fit shadow-[0_8px_30px_rgb(0,0,0,0.12)] animate-slide_down`}>
                    <div className={`rounded-md overflow-hidden w-fit ${contentType ? 'min-w-[150px]' : 'min-w-[250px]'}`}>
                        {compenentContent}
                    </div>
                </div>
            }
        </div>
    )
}

export default Dropdown