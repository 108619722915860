import React from 'react'
import { connect } from 'react-redux'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'

import Auth from '../pages/auth'
import Login  from '../pages/auth/Login'

import Dashboard from '../pages/dashboard'
import Home from '../pages/dashboard/Home'

import Transactions from '../pages/dashboard/Transactions'
import TransactionOverview from '../pages/dashboard/Transactions/TransactionOverview'

import TransferFunds from '../pages/dashboard/TransferFunds'

import Businesses from '../pages/dashboard/Businesses'
import ConfigureBusiness from '../pages/dashboard/Businesses/ConfigureBusiness'

import SubPockets from '../pages/dashboard/SubPockets'
import SubPocketOverview from '../pages/dashboard/SubPockets/SubPocketOverview'
import CreateSubPocket from '../pages/dashboard/SubPockets/CreateSubPocket'

import BasicInfo from '../pages/dashboard/SubPockets/CreateSubPocket/BasicInfo'
import AccountVerification from '../pages/dashboard/SubPockets/CreateSubPocket/AccountVerification'
import FinishSetup from '../pages/dashboard/SubPockets/CreateSubPocket/FinishSetup'

import AuditTrail from '../pages/dashboard/AuditTrail'

import Settings from '../pages/dashboard/Settings'

// Merchanst Settings
import UserProfile from '../pages/dashboard/Settings/UserProfile'
import ApiKeys from '../pages/dashboard/Settings/ApiKeys'
import UserManagement from '../pages/dashboard/Settings/UserManagement'
import WebHooks from '../pages/dashboard/Settings/WebHooks'
import BusinessInfo from '../pages/dashboard/Settings/BusinessInformation'
    import BasicDetails from '../pages/dashboard/Settings/BusinessInformation/BasicDetails'
    import BusinessAddress from '../pages/dashboard/Settings/BusinessInformation/BusinessAddress'
    import Customers from '../pages/dashboard/Settings/BusinessInformation/Customers'
    import SupportEmails from '../pages/dashboard/Settings/BusinessInformation/SupportEmails'

import DefaultConfig from '../pages/dashboard/Settings/DefaultConfig'

import RolePermission from '../pages/dashboard/Settings/RolePermission'
import RoleManager from '../pages/dashboard/Settings/RoleManager'

const AppRouter = ({loginStatus, authRes}) => {
    const userAvailable = loginStatus === true && authRes !== null

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={userAvailable ? <Navigate to="/dashboard/home"/> : <Navigate to='/auth/login'/>}/>
                <Route exact path="/auth" element={userAvailable ? <Navigate to="/dashboard/home"/> : <Auth/>}/>
                <Route path="/auth" element={userAvailable ? <Navigate to="/dashboard/home"/> : <Auth/>}>
                    <Route exact path="/auth/login" element={userAvailable ? <Navigate to="/dashboard/home"/> : <Login/>}/>
                </Route>

                <Route path="/dashboard" exact element={userAvailable ? <Navigate to="/dashboard/home"/> : <Navigate to="/auth/login"/>}/>
                <Route path="/dashboard" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Dashboard/>}>
                    <Route path="/dashboard" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Navigate to="/dashboard/home"/>}/>
                    <Route path="/dashboard/home" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Home/>}/>
                    
                    <Route exact path="/dashboard/transactions" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Transactions/>}/>
                    <Route path="/dashboard/transactions/transaction_overview/:id" element={userAvailable === false ? <Navigate to="/auth/login"/> : <TransactionOverview/>}/>
                    
                    <Route exact path="/dashboard/send_money" element={userAvailable === false ? <Navigate to="/auth/login"/> : <TransferFunds/>}/>


                    <Route exact path="/dashboard/business" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Businesses/>}/>
                    <Route path="/dashboard/business/configure_business/:id" element={userAvailable === false ? <Navigate to="/auth/login"/> : <ConfigureBusiness/>}/>

                    <Route exact path="/dashboard/sub_pockets" element={userAvailable === false ? <Navigate to="/auth/login"/> : <SubPockets/>}/>
                    <Route path="/dashboard/sub_pockets/sub_pocket_overview/:id" element={userAvailable === false ? <Navigate to="/auth/login"/> : <SubPocketOverview/>}/>
                    
                    <Route exact path="/dashboard/sub_pockets/create_sub_pocket" element={userAvailable === false ? <Navigate to="/auth/login"/> : <CreateSubPocket/>}>
                    <Route exact path="/dashboard/sub_pockets/create_sub_pocket" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Navigate to="/dashboard/sub_pockets/create_sub_pocket/basic_info"/>}/>
                        <Route path="/dashboard/sub_pockets/create_sub_pocket/basic_info" element={userAvailable === false ? <Navigate to="/auth/login"/> : <BasicInfo/>}/>
                        <Route path="/dashboard/sub_pockets/create_sub_pocket/account_verification" element={userAvailable === false ? <Navigate to="/auth/login"/> : <AccountVerification/>}/>
                        <Route path="/dashboard/sub_pockets/create_sub_pocket/finish_setup" element={userAvailable === false ? <Navigate to="/auth/login"/> : <FinishSetup/>}/>
                    </Route>

                    <Route path="/dashboard/audit_trail" element={userAvailable === false ? <Navigate to="/auth/login"/> : <AuditTrail/>}/>

                    <Route path="/dashboard/settings" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Settings/>}>    
                        <Route path="/dashboard/settings" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Navigate to="/dashboard/settings/profile"/>}/>

                        {/*Merchannt settings routes*/}
                        <Route path="/dashboard/settings/profile" element={userAvailable === false ? <Navigate to="/auth/login"/> : <UserProfile/>}/>
                        <Route path="/dashboard/settings/api_keys" element={userAvailable === false ? <Navigate to="/auth/login"/> : <ApiKeys/>}/>
                        <Route path="/dashboard/settings/user_management" element={userAvailable === false ? <Navigate to="/auth/login"/> : <UserManagement/>}/>
                        <Route path="/dashboard/settings/web_hooks" element={userAvailable === false ? <Navigate to="/auth/login"/> : <WebHooks/>}/>
                        <Route path="/dashboard/settings/business_information" element={userAvailable === false ? <Navigate to="/auth/login"/> : <BusinessInfo/>}>
                            <Route path="/dashboard/settings/business_information" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Navigate to="/dashboard/settings/business_information/basic_details"/>}/>
                            <Route path="/dashboard/settings/business_information/basic_details" element={userAvailable === false ? <Navigate to="/auth/login"/> : <BasicDetails/>}/>
                            <Route path="/dashboard/settings/business_information/business_address" element={userAvailable === false ? <Navigate to="/auth/login"/> : <BusinessAddress/>}/>
                            <Route path="/dashboard/settings/business_information/customers" element={userAvailable === false ? <Navigate to="/auth/login"/> : <Customers/>}/>
                            <Route path="/dashboard/settings/business_information/support_emails" element={userAvailable === false ? <Navigate to="/auth/login"/> : <SupportEmails/>}/>
                        </Route>
                    </Route>
                    <Route path="/dashboard/settings/default_configuration" element={userAvailable === false ? <Navigate to="/auth/login"/> : <DefaultConfig/>}/>
                    <Route path="/dashboard/settings/role_permission" element={userAvailable === false ? <Navigate to="/auth/login"/> : <RolePermission/>}/>
                    <Route path="/dashboard/settings/role_permission/:id" element={userAvailable === false ? <Navigate to="/auth/login"/> : <RoleManager/>}/>
                    <Route path="/dashboard/settings/user_management/:id" element={userAvailable === false ? <Navigate to="/auth/login"/> : <RoleManager/>}/>    
                </Route>
            </Routes>
        </Router>
    )
}

const mapStateToProps = state => ({
    authRes: state.auth.auth_data,
    loginStatus: state.auth.userLoggedIn,
})

export default connect(mapStateToProps)(AppRouter)
