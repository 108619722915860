import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {tableHeader, tableBody} from '../../../mockData/BusinessList'
import Toggle from 'components/atoms/Toggle'
// import Dropdown from 'components/molecules/Dropdown'
import PageHeader from 'components/molecules/PageHeader'
import FormInput from 'components/atoms/FormInput'
import Button from 'components/atoms/Button'
import BusinessListDropdown from '../../../components/molecules/BusinessListDropdown'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import editIcon from '../../../assets/svg/edit-2.svg'

const specStyle = [
  {currency_style: "text-red"},
  {pocket_status_style: "text-green"},
  {business_status_style: "text-blue"}
]

const Businesses = () => {
  const navigate = useNavigate()
  const locate = useLocation()
  const path = locate.pathname
  
  const columns = [
    {
      label: 'ID',
      render: (data: any) => <TableDataSpan text={data.id} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Business name',
      render: (data: any) => <TableDataSpan text={data.business_name} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Country',
      render: (data: any) => <TableDataSpan text={data.country} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Currency',
      render: (data: any) => <TableDataSpan text={data.currency}
        additionalClass={`w-1 h-1 px-3 py-1 rounded-2xl
          ${data.currency === 'NGN'
          ? 'bg-Green_Accent text-Green_Accent2'
          : data.currency === 'USD'
          ? 'bg-Yellow_Accent text-Yellow_Accent2'
          : data.currency === 'GHS'
          ? 'bg-SecondaryAccent3 text-DarkBg3'
          : ''
          }
        `}/>
    },
    {
      label: 'Pockets status',
      render: (data: any) => <TableDataSpan text={data.pocket_status} 
        additionalClass={`text-opacity-70 text-xs px-2 py-1 rounded
          ${data.pocket_status === 'Activated' 
          ? 'bg-Accent_blue5 text-Accent_blue4' 
          : data.pocket_status === 'Deactivated'
          ? 'bg-DangerAccent3 text-Danger2'
          : ''  
          }
        `}/>
    },
    {
      label: 'Business status',
      render: (data: any) => <><TableDataSpan text=""
        additionalClass={`w-1 h-1 p-1 inline-block mr-2 rounded-full
          ${data.business_status === 'Active'
          ? 'bg-Success'
          : data.business_status === 'Inactive'
          ? 'bg-Danger'
          : data.business_status === 'Configure'
          ? 'bg-Yellow'
          : ''
          }
        `}/>
        <TableDataSpan text={data.business_status} additionalClass="text-opacity-70 text-xs"/>
        </>
    },
    {
      label: 'Date created',
      render: (data: any) => <TableDataSpan text={data.date_created} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Action',
      render: (data: any) => <>
        <TableDataSpan additionalClass='text-opacity-70 text-xs'/>
          <span onClick={() => navigate(`/dashboard/business/configure_business/${data.id}`)}>
            <img className='cursor cursor-pointer' src={editIcon} alt="editIcon"/>
          </span>
        </>
    }
  ];

  return (
    <>
      <PageHeader
        pageTitle="Business List" 
        pageTitleStyle=""
        exportTable={true}
        manageTableData={true}
        tableKeys={columns}
      />
      <AppTable
        data={tableBody}
        columns={columns}
        itemsPerPage={10}
      />
    </>
  )
}

export default Businesses