import React, { useCallback, useEffect, useRef, useState } from 'react'
import PocketBalCard from '../../../components/molecules/PocketBalCard'
import PaymentCard from '../../../components/molecules/PaymentCard'
import ChartComp from '../../../components/molecules/ChartComp'
import {Data} from '../../../components/atoms/BarChartData'
import addPocketIcon from '../../../assets/svg/pocketPlus.svg'
import PageTitle from 'components/atoms/PageTitle'
import {ArrowDownIcon} from '../../../components/atoms/ImagesImport'
import arrow_down from '../../../assets/svg/arrow-down.svg'
import FilterComp from 'components/atoms/FilterComp'
import calender from '../../../assets/svg/calendar.svg'
import { BASE_URL } from '../../../services/http'
// import { APP_URL, username, APP_TOKEN, PUBLIC_KEY } from 'services/http'
// import {username, APP_TOKEN, PUBLIC_KEY} from '../../dashboard'
import Alert from 'components/atoms/Alert'
import { handleException } from 'utils'
import { getValues, setValues } from 'services/storage'
import ErrorEmptyState from 'components/atoms/ErrorEmptyState'
import Spinner from 'components/atoms/Spinner'
import { connect } from 'react-redux'
import axios from 'services/axios'
import { setLandingPageData, toggleModal } from 'redux/app/app.action'
import AddSubPocket from './AddSubPocket'
import { setPocketDetails } from '../../../redux/Pockets/pocket.action'
import { store } from '../../../redux/store'
import { Link } from 'react-router-dom'
import Button from 'components/atoms/Button'
import AddIcon from '../../../assets/svg/add_icon_black.svg'
import SendIcon from '../../../assets/svg/sendIcon.svg'
import CopyText from 'components/atoms/CopyText'
import SideComponent from '../Transactions/SideComponent'

const state = store.getState()
console.log("State:", state);

const Home = ({bearerToken, public_key, pocket_data, pocketId, toggleModal, modalState, primaryPocket_details, setPocketDetails, landingPage_data, setLandingPageData}) => {
  const [loading, setLoading] = useState(false)
  const [balLoading, setBalLoading] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // const [glPocketNGN, setGlPocketNGN] = useState<any>({}) 
  // const [glPocketUSD, setGlPocketUSD] = useState<any>({}) 
  const [addPocketList, setAddPocketList] = useState<any>([])
  const [filterKey, setFilterKey] = useState<any>({})

  const [pocketBal, setPocketBal] = useState<any>({})
  const [activePocketsData, setActivePocketsData] = useState([])
  const [transactions, setTransactions] = useState<any>([])
  const [transactionDetails, setTransactionDetails] = useState([])
  const [totalPayinData, setTotalPayinData] = useState<any>({})
  const [totalPayoutData, setTotalPayoutData] = useState<any>({})

  const baseURL = process.env.REACT_APP_SUB_BASE_URL
  const accountType = getValues('userType')

  console.log("ACCOUNT_TYPE:", accountType)
  

  const fecthPocketDetails = async (pocketId) => {
    setLoading(true)
    try{
      const res = await axios.get(`${BASE_URL}pocket-id/${pocketId}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${public_key}`
        }
      })
      if(res.data.code === 'error'){
        Alert("error", res.data.message)
        setLoading(false)
      }else{
        console.log("primaryPocket_details:", res.data.data)
        setPocketDetails({...primaryPocket_details, ...res.data.data})
        // setLoading(false)
      }
      return res;
    }catch(error){
      setLoading(false)
      handleException(error)
    }
  }

  const fetchPocketBal = async (pocketId) => {
    try{
      const res = await axios.get(`${BASE_URL}balance/pocket-id/${pocketId}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${public_key}`
        }
      })
      if(res.data.code === 'error'){
        Alert("error", res.data.message)
        setLoading(false)
      }else{
        setPocketBal(res.data.data)
        console.log("PocketBal:", res.data.data)
        setLoading(false)
      }
      return res;
    }catch(error){
      handleException(error)
      setLoading(false)
    }
  }

  const fetchPocketTransactions = async () => {
    setLoading(true)
    try{
      const res = await axios.get(`${BASE_URL}transaction/search?pocketId=${pocketId}&size=10&page=0` , {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${public_key}`
        }
      })
      if(res.data.code === 'error'){
        setLoading(false)
        Alert("error", res.data.message);
      }else{
        setLoading(false)
        setTransactions(res.data.data.content)
        console.log("transactionsData:", res.data.data.content);
        
      }
    }catch(error){
      setLoading(false)
      setErrorState(true)
      handleException(error)
    }
  }
  

  useEffect(() => {
    fecthPocketDetails(pocketId)
    fetchPocketBal(pocketId)
    
    accountType === 'subPocket' && fetchPocketTransactions()
  }, [])

  const ActivePocketsResponse = landingPage_data?.ActivePocketsResponse
  const TotalPayIn = landingPage_data?.response?.TotalPayIn
  const TotalPayOut = landingPage_data?.TotalPayOut
  const CountResponse = landingPage_data?.countResponse

  const sevenDaysAgo_tpc = CountResponse?.sevenDaysAgoTotalPocketsCount
  const oneMonthAgo_tpc = CountResponse?.oneMonthAgoTotalPocketsCount
  const sixMonthsAgo_tpc = CountResponse?.sixMonthsAgoTotalPocketsCount
  const TotalCurrentPocketsCount = CountResponse?.TotalCurrentPocketsCount
  const NewPocketCreatedWithinLastSevenDaysCount = CountResponse?.NewPocketCreatedWithinLastSevenDaysCount
  

  const filterData = [
    {timePeriod: "Last 7 days", value: sevenDaysAgo_tpc},
    {timePeriod: "Last 1 month", value: oneMonthAgo_tpc},
    {timePeriod: "Last 6 months", value: sixMonthsAgo_tpc},
  ]

  const filterContent = Object.values(filterKey).map(value => value)[0] === 'Last 7 days'
                      ? sevenDaysAgo_tpc
                      : Object.values(filterKey).map(value => value)[0] === 'Last 1 month'
                      ? oneMonthAgo_tpc
                      : Object.values(filterKey).map(value => value)[0] === 'Last 6 months'
                      ? sixMonthsAgo_tpc
                      : ""
  
  const pocketBalArr = [
    {currency: pocketBal.availableBalanceCurrency, balance: (Intl.NumberFormat().format(pocketBal.availableBalanceAmount)), desc: `${accountType === 'subPocket' ? "Sub Pocket Balance" : "GL Pocket"}`},
  ]

  const datasetOne = (mainData: any[]) => {
    const labels = mainData && mainData.map(item => item.year)
    const data = mainData && mainData.map(item => item.pocketsGained)
    const borderWidth = 1
    const borderRadius = 8
    const barPercentage =  0.3
    const categoryPercentage =  1
    const backgroundColor = ['#17191D' , '#E7E9F1']
    return {labels, data, borderWidth, borderRadius, barPercentage, categoryPercentage, backgroundColor}
  }

  const datasetTwo = (mainData: any[]) => {
    const labels = mainData && Object.keys(mainData).map(key => key)
    const data = mainData && Object.values(mainData).map(value => value)
    const borderWidth = 0
    const borderRadius = 4
    const barPercentage =  0.3
    const categoryPercentage = 1
    const backgroundColor = ['#17191D' , '#E7E9F1']
    return {labels, data, borderWidth, borderRadius, barPercentage, categoryPercentage, backgroundColor} 
  }

  const handleAddSubPocket = (e: any) => {
    toggleModal()
    console.log("Function fired!!!", modalState);
    
    // setAddPocketList(addPocketList.concat(pocketBalArr.map((item, i) => (
    //   <PocketBalCard 
    //       key={i} 
    //       id={addPocketList.length + 1} 
    //       currency={'NGN'} 
    //       balance={0.00} 
    //       desc={""} 
    //       removeItem={handleRemoveSubPocket}/>
    // ))))
  }

  let sumTotalPayIn = TotalPayIn && Object.values(TotalPayIn).map(value => value)
  let sumTotalPayOut = TotalPayOut && Object.values(TotalPayOut).map(value => value)

  const getSumation = (varObj: any[]) => {
    let sum = varObj.reduce(function(accumulator, currentValue) {
      return accumulator + currentValue;
    }, 0);

    return sum.toFixed(2)
  }

  const subPocketData = [
    {title: 'Account Number', value: pocket_data.accountNumber, style: null},
    {title: 'Pocket Reference', value: pocket_data.reference, style: null},
    {title: 'Business Name', value: pocket_data.pocketOwner?.businessName, style: null},
    {title: 'Funding Link', value: pocket_data.fundingLink, style: 'text-Accent_blue'}
  ]
  
  const viewTransaction = (id) => {
    setShowModal(prevState => !prevState)
    const transactionFilter = transactions.filter(item => item.transactionId === id)
    const transactionItem = transactionFilter[0]
    setTransactionDetails(transactionItem)
  }

  return (
    <div className='relative'>
    {loading
      ? <div className={`absolute z-20 flex items-center py-10 justify-center w-full h-[90vh]`}>
          <Spinner
            text="Loading..."
          />
        </div> 

      : errorState
      ? <ErrorEmptyState img={true}/>

      : <div className='pb-0 w-full'>
          <PageTitle pageTitle='Dashboard'/>
          <div className={`w-full flex mobile:flex-col-reverse justify-between my-8`}>
            <span className='desktop:flex desktop:flex-wrap grid grid-cols-2 mobile:grid-cols-1 gap-5 w-full'>
                {pocketBalArr.map((item, i) => (
                    <PocketBalCard 
                        key={i} 
                        currency={item.currency}
                        balance={item.balance}
                        desc={item.desc}
                        loading={balLoading}
                    />
                ))}
                {/* {addPocketList} */}
                {accountType !== 'subPocket' &&
                  <div 
                    className='w-[140px] h-[136px] rounded-lg flex flex-shrink-0 flex-col gap-2 justify-center hover:border-2 border items-center cursor-pointer'
                    onClick={handleAddSubPocket}
                  >
                    <img src={addPocketIcon} alt="addPocketIcon" className="w-7 h-7"/>
                    <p className='font-semibold text-base leading-7 text-black'>Pocket</p>
                  </div>
                }
            </span>
            <span className='w-fit mobile:w-full mobile:mb-8 flex items-start gap-5 flex-shrink-0'>
              <Button 
                btnType='button'
                btnText="Fund Pocket"
                btnImg={AddIcon}
                btnImgStyle="w-3 h-3"
                btnStyle="bg-SecondaryAccent5 text-DarkBg3 font-semibold px-5 py-2 truncate w-fit flex-shrink-0 flex-row-reverse"
                onClick={()=>{}}
              />
              <Button 
                btnType='button'
                btnText="Send Money"
                btnImg={SendIcon}
                btnImgStyle="w-4 h-4"
                btnStyle="bg-DarkBg3 text-white font-semibold px-5 py-2 truncate w-fit flex-shrink-0 flex-row-reverse"
                onClick={()=>{}}
              />
            </span>
          </div>

          {accountType === 'subPocket'
              ? <>
                <div className='flex mobile:flex-col gap-5'>
                  <div className='border rounded-lg desktop:w-3/5 w-1/2 mobile:w-full p-5 my-8 mobile:my-2 flex flex-col justify-between gap-3'>
                    {subPocketData.map(item => (
                      <div className='flex justify-between items-center p-1'>
                        <span className='text-sm mobile:text-xs font-normal text-GrayCustom leading-7'>{item.title}</span>
                        <CopyText 
                          text={item.value.length > 20 ? item.value.slice(0, 20)+'. . .' : item.value}
                          textStyle={`${item.style !== '' ? item.style : 'text-DarkBg'} bg-SecondaryAccent3 px-3 py-1 rounded-md w-fit text-opacity-70 font-semibold text-base mobile:text-xs leading-7`}
                          mssgStyle='-right-3 -top-[18px] hidden' 
                          imgStyle="w-4 h-4"
                        />
                      </div>
                    ))}
                  </div>

                  <div className='rounded-lg desktop:w-2/5 w-1/2 mobile:w-full my-8 mobile:my-2 flex flex-col justify-between gap-5  mobile:gap-8'>
                    <PaymentCard 
                      title="Total payin" 
                      currency={'NGN'}
                      totalAmount={TotalPayIn && getSumation(sumTotalPayIn)}
                      ammountPosition={'text-right'}
                    />

                    <PaymentCard 
                      title="Total payout" 
                      currency={'NGN'}
                      totalAmount={TotalPayOut && getSumation(sumTotalPayOut)}
                      ammountPosition={'text-right'}
                    />
                  </div>
                </div>

                <div className='w-full my-8 flex flex-col gap-5'>
                  <span>Recent Transactions</span>
                  {transactions?.map(item => (
                    <div className='border hover:border-white px-5 py-2 rounded-md flex justify-between items-center hover:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] transition-all cursor-pointer' onClick={()=>viewTransaction(item.transactionId)}>
                      <div className='flex flex-col gap-2'>
                        <span className='font-semibold text-base leading-7'>{item.description}</span>
                        <span className='font-normal text-xs loading-5'>{item.createdAt}</span>
                      </div>
                      
                      <div className='flex items-center gap-4'>
                        <span className='font-bold text-lg leading-7 text-SecondaryAccent'>{item.transactionCurrency + " " + (Intl.NumberFormat().format(item.transactionAmount))}</span>
                        <span className={`text-opacity-70 text-xs rounded-full px-2 py-1 cursor-pointer ${item.transactionLeg === 'DEBIT' ? 'bg-DangerAccent3 text-Danger2' : 'bg-Green_Accent text-Green_Accent2'}`}>{item.transactionLeg}</span>
                      </div>
                    </div>
                  ))}
                </div>
                </>
              : <>
                  <div className='w-auto flex tablet:flex-col justify-between gap-5 my-20'>
                    <div className='border flex flex-col rounded desktop:w-1/2 w-full'>
                        <div className='border-b p-8 mobile:p-4 mobile:flex mobile:flex-col mobile:justify-between gap-5'>
                            <span className='flex justify-between items-center'>
                                <p className='font-normal text-base mobile:text-xs text-left leading-7 text-SecondaryAccent'>Total pocket accounts</p>
                                <span className='flex justify-end gap-2 items-center border rounded px-4 py-2 w-fit'>
                                    <img src={calender} alt="calender_icon" className='w-5 h-5'/>
                                    <FilterComp 
                                        dropdownTitle={filterContent === "" ? 'Last 6 months' : filterKey.timePeriod} 
                                        data={filterData} 
                                        style="border-none mobile:text-xs"
                                        handleSelect={setFilterKey}
                                    />
                                </span>
                            </span>
                            <span className='font-bold text-[35px] leading-9 text-Dark'>
                              {filterContent === "" ? sixMonthsAgo_tpc : filterContent}
                            </span>
                        </div>
                        <div className='flex flex-col gap-8 p-8'>
                            {/* <span className='font-normal text-base leading-7 text-SecondaryAccent'>
                                New pocket accounts in the Last 7 days
                            </span>
                            <span className='font-bold text-[35px] leading-9 text-Dark'>
                              {NewPocketCreatedWithinLastSevenDaysCount}
                            </span> */}
                            <span className='font-normal text-base leading-7 text-SecondaryAccent'>
                                Total active Pockets
                            </span>
                            <span className='font-bold text-[35px] leading-9 text-Dark'>
                              {TotalCurrentPocketsCount}
                            </span>
                        </div>
                    </div>
                    <div className='border flex flex-col rounded desktop:w-1/2 w-full pb-5'>
                        <div className='p-8 mobile:p-4 mobile:flex mobile:flex-col mobile:justify-between gap-5'>
                            <span className='flex justify-between items-center'>
                                <p className='font-normal text-base mobile:text-xs text-left leading-7 text-SecondaryAccent'>Active pockets</p>
                                <span className='flex justify-end gap-2 items-center border rounded px-4 py-2 mobile:w-fit'>
                                    <img src={calender} alt="calender_icon" className='w-5 h-5'/>
                                    <FilterComp 
                                        dropdownTitle="Last 7 days" 
                                        data={filterData} 
                                        style="border-none mobile:text-xs"
                                    />
                                </span>
                            </span>
                        </div>
                        <span className='flex justify-center items-center'>
                            <span className='desktop:w-[70%] mobile:w-full'>
                                <ChartComp chartType="doughnut" propData={ActivePocketsResponse} dataset={datasetOne} legend="Pockets Gained"/>
                            </span>
                        </span>
                    </div>
                  </div>

                  <div className='w-auto flex tablet:flex-col justify-between'>
                    <div className='desktop:w-1/2 tablet:w-full flex justify-start tablet:mb-16'>
                        <span className='desktop:w-[90%] tablet:w-full'>
                            <PaymentCard 
                              title="Total payin" 
                              currency={'NGN'}
                              totalAmount={TotalPayIn && getSumation(sumTotalPayIn)}
                            />
                            <div className="flex items-center gap-5 py-5">
                                <p className="font-semibold test-base leading-5 text-[#333333]">This week</p>
                                <ArrowDownIcon style="w-3 h-3"/>
                            </div>
                            <ChartComp chartType="bar" propData={TotalPayIn}  dataset={datasetTwo} legend="Total Payin"/>
                        </span>
                    </div>
                    <div className='desktop:w-1/2 tablet:w-full flex justify-end'>
                        <span className='desktop:w-[90%] tablet:w-full'>
                            <PaymentCard 
                              title="Total payout" 
                              currency={'NGN'}
                              totalAmount={TotalPayOut && getSumation(sumTotalPayOut)}
                            />
                            <div className="flex items-center gap-5 py-5">
                                <p className="font-semibold test-base leading-5 text-[#333333]">This year</p>
                                <ArrowDownIcon style="w-3 h-3"/>
                            </div>
                            <ChartComp chartType="bar" propData={TotalPayOut}  dataset={datasetTwo} legend="Total Payout"/>
                        </span>
                    </div>
                  </div>
                </>
          }
        </div>
    }
    {showModal && 
      <SideComponent
        toggleModal={() => setShowModal(prevState => !prevState)}
        data={transactionDetails}
      />
    }
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  bearerToken: state.auth.token,
  modalState: state.app.modalState,
  public_key: state.auth.public_key,
  pocket_data: state.auth.pocket_data,
  loggedUserData: state.auth.loggedUserData,
  pocketId: state.auth.pocket_data.pocketId,
  landingPage_data: state.app?.landingPage_data,
  primaryPocket_details: state.pocket.primaryPocket_details
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleModal: () => dispatch(toggleModal()),
  setPocketDetails: (data: any[]) => dispatch(setPocketDetails(data)),
  // setLandingPageData: (data: any[]) => dispatch(setLandingPageData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)