import React, { useState } from 'react'
import axios from 'services/axios'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'components/atoms/Button'
import FormInput from 'components/atoms/FormInput'
import Spinner from 'components/atoms/Spinner'
import showPwd from "../../../assets/svg/show.svg";
import hidePwd from "../../../assets/svg/hide.svg";
import { BASE_URL, getRequest, postRequest } from 'services/http'
import Alert from 'components/atoms/Alert'
import { handleException } from 'utils'
import { logoutHandler, setAuthRes, setBearerToken, setPocketData, setPublicKey, setUserData, setUserLoggedIn } from 'redux/auth/auth.action'
// import { setPocketData} from '../../../redux/pockets/pocket.action'
import { connect } from 'react-redux'
import { setValues } from 'services/storage'
import { setLandingPageData } from 'redux/app/app.action'
import RadioAndCheckInput from 'components/atoms/RadioAndCheckInputGroup/RadioAndCheckInput'

const Login = ({setAuthRes, auth_data, setBearerToken, bearerToken, setUserData, user_data, setUserLoggedIn, setPublicKey, pocket_data, primaryPocket_data, setPocketData, setLandingPageData, landingPage_data}) => {
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const[accountNum, setAccountNum] = useState('')

    const[emailErr, setEmailErr] = useState('')
    const[passwordErr, setPasswordErr] = useState('')
    const[accountNumErr, setAccountNumErr] = useState('')

    const[fetchStatus, setFetchStatus] = useState('')
    const[userType, setUserType] = useState('')

    const[loading, setLoading] = useState(false)
    const[showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()
    const formComplete = email !== "" && password !== "";
    const disabledBtn = formComplete === false || loading === true;

    const userTypeData = [
        {label: "Business", value: 'primary_pocket'},
        {label: "Sub Pocket", value: 'subPocket'}
    ]

    const fetchPageData = async (token:any, public_key:any) => {
        try{
          const res = await axios.get(`${BASE_URL}landingpage`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "Public-Key": `${public_key}`
            }
          })
          if(res.data.code === "error") {
            Alert("error", res.data.message);
          } else {
            setLandingPageData({...landingPage_data, ...res.data.data})
            console.log("Dashboard_data:", res.data.data);
            
          }
        }catch(error){
          handleException(error)
        }
    }

    const fetchUserData = async (token: any) => {
        try{
            const res = await axios.get(`${BASE_URL}user/find-by-username/${email}`, {
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setUserData({...user_data, ...res.data.data})
                const public_key = `${res.data.data.isBusiness
                    ?   res.data.data.businessDetails.publicKey
                    :   process.env.REACT_APP_POCKET_PUBLIC_KEY}`
    
                setValues(`${email}_public_key`, public_key)
                setValues('business_type', res.data.data.isBusiness)
                setPublicKey(public_key)
                setFetchStatus(res.data.code)

                if(res?.data.data.isBusiness){
                    await fetchPrimaryPocket(token, public_key)
                    await fetchPageData(token, public_key)
                }else{
                    Alert("error", "Seerbit Merchants only.");
                    setLoading(false)
                    logoutHandler()
                    localStorage.clear()
                    sessionStorage.clear()
                }
            }
            return res;
        }catch(error){
            setLoading(false)
            handleException(error)
        }
    }

    const fetchPrimaryPocket = async (token:any, public_key:any) => {
        try{
            const res = await axios.get(`${BASE_URL}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "Public-Key": `${public_key}`
                }
            })
            if(res.data.code === 'error'){
                Alert('error', res.data.message)
            }else{
                setValues('primary_pocket_id', res.data.data.pocketId)

                console.log("Primary Pocket Response:", res)

                if(userType === 'subPocket'){
                    const pocketID = res.data.data.pocketId
                    await fetchSubPocket(token, public_key, pocketID, accountNum)
                }else{
                    setPocketData({...pocket_data, ...res.data.data})
                }
            }
            return res;
        }catch(error){
            handleException(error)
        }
    }

    // search?pocket-id/${pocketId}

    // search?pocket-id/100000015&accountNumber=0010010129

    const fetchSubPocket = async (token:any, public_key:any, pocketId:any, accountNumber:any) => {
        try{
            const res = await axios.get(`${BASE_URL}search?pocket-id/${pocketId}&accountNumber=${accountNumber}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    "Public-Key": `${public_key}`
                }
            })

            if(res.data.code === 'error'){
                Alert('error', res.data.message)
            }else{
                setValues('userType', `${userType}`)
                setValues('sub_pocket_id', res.data.data.content[0].pocketId)
                setPocketData({...pocket_data, ...res.data.data.content[0]})

                console.log("Sub_Pocket_Response:", res)
                console.log("Sub_Pocket_Id:", res.data.data.content[0].pocketId)
            }

            return res
        }catch(error){
            handleException(error)
        }
    }

    const validateUser = async (userData: any) => {
        try{
            const res = await axios.post(`${BASE_URL}dashboard/authenticate`, userData, {
                headers:{
                    "Content-Type": "application/json"
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setAuthRes({...auth_data, ...res.data})
                setBearerToken(res.data.data.bearerToken)
                setValues(`${email}_token`, res.data.data.bearerToken)

                const response = await fetchUserData(res.data.data.bearerToken)

                console.log("UserData Res:", response)
                
                if(response?.data.data.isBusiness){
                    if(fetchStatus !== 'error'){
                        Alert("success", "Login Successful.");
                        setUserLoggedIn(true);
                        setValues("username", email);
                        navigate('/dashboard/home')
                        setLoading(false);
                    }else{
                        Alert("error", "Unable to fetch user data.");
                        setLoading(false);
                    }
                }else{
                    setLoading(false)
                    logoutHandler()
                    localStorage.clear()
                    sessionStorage.clear()
                }
            }
            return res;
        }catch(error){
            setLoading(false)
            handleException(error)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        if(userType === "") {
            setUserType('primary_pocket')
        }

        if(email.length < 1){
            setEmailErr("Please enter a valid email");
        }
        if(password.length < 1){
            setPasswordErr("Password should be at least 1 characters");
        }
        if((userType === 'subPocket') && (accountNum.length < 1)){
            setAccountNumErr("Please enter a valid account number");
        }

        const userData = {
            email: email,
            password: password
        }

        validateUser(userData)
    }

    const handleAccountNumber = e => {
        const regex = /^[0-9\b]+$/

        if(e.target.value === '' || regex.test(e.target.value)){
            setAccountNum(e.target.value)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <span className="text-center flex flex-col gap-5 mb-5">
                <p className="font-normal text-2xl leading-7">Welcome Back!</p>
                <p className="font-normal text-xl leading-7 text-Black2">
                    Login to your account
                </p>
            </span>
            <span className='flex flex-col gap-3'>
               <RadioAndCheckInput 
                   type="radio" 
                   name="selfOwned" 
                   inputData={userTypeData} 
                   containerStyle="gap-8"
                   label='Account Type'
                   labelStyle='font-semibold text-base leading-6 text-Black2'
                   handleSelect={setUserType}
               />
                <FormInput
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    inputStyle="border border-transparent text-Black2"
                    label="Email Address"
                    labelStyle="font-normal text-base leading-6 text-Black2"
                    onChange={(e)=>setEmail(e.target.value)}
                    value={email}
                    validationErr={emailErr}
                />
                <FormInput
                    type={showPassword ? "text" : "password"}
                    name="passwd"
                    placeholder="********"
                    inputStyle="border border-transparent text-Black2 flex-row-reverse"
                    label="Password"
                    labelStyle="font-normal text-base leading-6 text-Black2"
                    onChange={(e)=>setPassword(e.target.value)}
                    value={password}
                    validationErr={passwordErr}
                    img={showPassword ? showPwd : hidePwd}
                    imgStyle="cursor-pointer w-5 h-5 block"
                    imgOnClick={() => setShowPassword((prevState) => !prevState)}
                />
                {userType === 'subPocket'
                    &&   <FormInput
                            type="text"
                            name="accountNumber"
                            placeholder="Account Number"
                            inputStyle="border border-transparent text-Black2 flex-row-reverse"
                            label="Account Number"
                            labelStyle="font-normal text-base leading-6 text-Black2"
                            onChange={handleAccountNumber}
                            value={accountNum}
                            validationErr={accountNumErr}
                        /> 
                }
            </span>
            <span>
                <Button
                    btnType="submit"
                    btnText={
                        loading ? (
                            <Spinner/>      // <Spinner text="Loading..." textStyle="text-white" />
                        ) : (
                            "Sign in"
                        )
                    }
                    btnStyle={`${
                        disabledBtn ? "cursor-not-allowed" : ""
                    } bg-black text-white px-6 py-3 mt-12 w-full`}
                    disabled={formComplete === false || loading === true}
                />
            </span>
            <span className="flex justify-center mt-6">
                <Link
                    to="/auth/forgot_password"
                    className="text-Accent_blue text-base leading-7 hover:underline hover:transition-all"
                >Forgot password?</Link>
            </span>
        </form>
    )
}

const mapStateToProps = (state:any) => ({
    bearerToken: state.auth.token,
    auth_data: state.auth.auth_data,
    pocket_data: state.auth.pocket_data,
    landingPage_data: state.app.landingPage_data,
    primaryPocket_data: state.pocket.primaryPocket_data
})

const mapDispatchToProps = (dispatch:any) => ({
    logoutHandler: () => dispatch(logoutHandler()),
    setAuthRes: (data: any) => dispatch(setAuthRes(data)),
    setUserData: (data: any) => dispatch(setUserData(data)),
    setPublicKey: (data: any) => dispatch(setPublicKey(data)),
    setPocketData: (data: any) => dispatch(setPocketData(data)),
    setBearerToken: (data: any) => dispatch(setBearerToken(data)),
    setUserLoggedIn: (data: boolean) => dispatch(setUserLoggedIn(data)),
    setLandingPageData: (data: any[]) => dispatch(setLandingPageData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)