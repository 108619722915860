import React from 'react'
import Toggle from 'components/atoms/Toggle'
import { useNavigate } from 'react-router-dom'
import NavDropdownData from '../molecules/NavDropdownData'
import { connect } from 'react-redux'
// import { setUserLoggedIn, toggleAppEnv } from 'redux/app/app.action'
import { logoutHandler, setAuthRes, setUserLoggedIn } from 'redux/auth/auth.action'
import { toggleAppEnv } from 'redux/app/app.action'
import DropdownItem from 'components/atoms/Dropdown/DropdownItem'

const UserProfileNav = ({appEnv, toggleAppEnv, setAuthRes, setUserLoggedIn, bearerToken}) => {

  const {dropdownData} = NavDropdownData(logoutHandler, bearerToken)


    const navigate = useNavigate()

    return (
        <div className='flex flex-col gap-8 h-full relative'>
            <div className='flex justify-center items-center bg-DarkBg3 rounded-full w-10 h-10 animate-slide_left'>
                <span className='font-bold text-base leading-4 text-center mx-auto text-SecondaryAccent4'>TS</span>
            </div>
            <div className='border flex flex-col gap-3 rounded-lg animate-slide_left'>
            {dropdownData.length > 0 && 
                dropdownData.map((item, index) => (
                    <div key={index} className={`${item.style} flex gap-2 hover:bg-SecondaryAccent5 hover:transition-opacity py-3 px-2 cursor-pointer rounded-sm w-full p-1`} onClick={item.link === "" ? item.action : () => navigate(`${item.link}`)}>
                        {item.icon !== "" &&
                            <span className='flex justify-center items-center  flex-shrink-0'>
                                <img src={item.icon} alt="icon" className="w-6 h-6"/>
                            </span>
                        }
                        <span className={`flex justify-start items-center  truncate text-base leading-5 font-normal w-full`}>
                            {item.title}
                        </span>
                    </div>
                    // <DropdownItem
                    //     key={index}
                    //     title={item.title}
                    //     icon={item.icon}
                    //     iconStyle="w-5 h-5"
                    //     link={item.link && item.link}
                    //     style={`${item.style} my-1`}
                    //     fontStyle='text-sm'
                    //     handleClick={props.selector ? () => actionFunction(item, props.handleSelect) : item.action}
                    //     toggler={props.setDropdown}
                    // />
                ))
            }
            </div>
            <div className='border absolute bottom-0 w-full px-2 py-3 flex flex-col gap-5 rounded-lg animate-slide_left'>
                <Toggle handleChange={toggleAppEnv} checked={appEnv} label={`${appEnv ? 'Live Mode' : 'Test'}`} labelStyle={`${appEnv ? 'text-black' : 'text-Primary'} truncate text-base leading-5 font-normal`} style="w-6 h-3 after:h-3 after:w-3 after:top-1 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"/>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    deviceCode: state.app.deviceCode,
    auth_data: state.auth.device_auth_data,
    appEnv: state.app.appEnv,
    bearerToken: state.auth.token
})
  
const mapDispatchToProps = (dispatch: any) => ({
    toggleAppEnv: () => dispatch(toggleAppEnv()),
    logoutHandler: () => dispatch(logoutHandler()),
    setAuthRes: (data: any) => dispatch(setAuthRes(data)),
    setUserLoggedIn: (value: boolean) => dispatch(setUserLoggedIn(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileNav)