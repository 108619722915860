import React, { useState } from 'react'
import FormInput from 'components/atoms/FormInput'
import Button from 'components/atoms/Button'
import globalEdit from '../../../../assets/svg/global-edit.svg'
import arrow_down from '../../../../assets/svg/arrow-down.svg'
import arrow_up from '../../../../assets/svg/arrow-up.svg'
import Dropdown from 'components/atoms/Dropdown'
import TextArea from 'components/atoms/TextAreaInput/TextArea'
import { connect } from 'react-redux'
import { store } from '../../../../redux/store'

// const state = store.getState()
// console.log("State:", state.pocket.primaryPocket_details)

const BasicDetails = ({primaryPocketData, user_data}) => {
  const [dropdown, setDropdown] = useState(false)
  const [selectedIndustry, setSelectedIndustry] = useState("")

  const [businessName, setBusinessName] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [webUrl, setWebUrl] = useState("")

  console.log("user_data:", user_data.authorities.map(item => item.description))
  console.log("primaryPocketData:", primaryPocketData)
  
  const component = (
    <></>
  )

  const default_businessName = primaryPocketData.pocketOwner.businessName
  const default_contactEmail = primaryPocketData.pocketOwner.emailAddress
  const user_roles = user_data.authorities
  // const businessDesc = 

  // const userRole = 
  // const websiteUrl = 
  // const phoneNumber = 
   
  return (
    <div className='flex flex-col gap-5 w-full desktop:w-[715px]'>
      <div className='flex flex-col gap-5 w-full'>
        <span className='bg-Black4 w-[96px] h-[96px] rounded-full flex justify-center items-center'> 
          <img src={globalEdit} alt="profile_icon" className=''/>
        </span>
        <form className='flex flex-col gap-5'>
          <div className='flex mobile:flex-col gap-5 justify-between items-center'>
            <FormInput 
              type="text"  
              name="businessName" 
              placeholder="Business name" 
              inputStyle="border border-transparent text-Black2"
              label="Business name" 
              labelStyle="font-normal text-base leading-6 text-DarkBg6" 
              style='w-full'
              onChange={(e)=>setBusinessName(e.target.value)}
              value={businessName === "" ? default_businessName : businessName}
              validationErr=""
            />
            <FormInput 
              type="text"  
              name="website_url" 
              placeholder="Website URL" 
              inputStyle="border border-transparent text-Black2"
              label="Website URL" 
              labelStyle="font-normal text-base leading-6 text-DarkBg6" 
              style='w-full'
              onChange={(e)=>setWebUrl(e.target.value)}
              value={webUrl}
              validationErr=""
            />
          </div>
          <div className='flex mobile:flex-col gap-5 justify-between items-center'>
            <FormInput 
              type="email"  
              name="contact_email" 
              placeholder="example@gmail.com" 
              inputStyle="border border-transparent text-Black2"
              label="Contact email" 
              labelStyle="font-normal text-base leading-6 text-DarkBg6" 
              style='w-full'
              onChange={(e)=>setContactEmail(e.target.value)}
              value={contactEmail === "" ? default_contactEmail : contactEmail}
              validationErr=""
            />
            <FormInput 
              type="text"  
              name="phone" 
              placeholder="+234" 
              inputStyle="border border-transparent text-Black2"
              label="Phone number" 
              labelStyle="font-normal text-base leading-6 text-DarkBg6" 
              style='w-full'
              onChange={(e)=>setPhoneNumber(e.target.value)}
              value={phoneNumber}
              validationErr=""
            />
          </div>
          <div className='flex mobile:flex-col gap-5 justify-between items-baseline'>
            {/* <Dropdown
              component={component}
              style="absolute right-0 top-24 w-full animate-slide_down max-h-[200px] overflow-y-scroll !p-1 !gap-1" 
              textStyle="text-sm"
              containerStyle='w-full'
              dropdown={dropdown}
              setDropdown={setDropdown}
            >
              <div className='flex flex-col gap-[10px] w-full'>
                <label className='font-normal text-base leading-6 text-DarkBg6 mx-2'>Manage User Role</label>
                <div className='flex justify-between items-center border w-full rounded-md pr-2'>
                  <span className={`${selectedIndustry === "" ? "text-GrayCustom" : "text-GrayCustom4"} px-2 py-[10px] text-sm leading-5 font-normal`}>
                    {selectedIndustry === "" ? "Select Industry" : selectedIndustry}
                  </span>
                  <span className='p-1' onClick={()=>setDropdown(prevState => !prevState)}>
                    <img src={dropdown ? arrow_up : arrow_down} alt="arrowIcon" className="w-4 h-4"/>
                  </span>
                </div>
              </div>
            </Dropdown> */}
            <div className={`a w-full flex flex-col`}>
              <span className={`font-normal text-base leading-6 text-DarkBg6 m-2`}>User Roles</span>
              <div className={`e-span border border-transparent text-Black2 rounded-md overflow-scroll p-3 flex flex-col gap-1 max-h-[100px]`}>
                {user_roles.map(item => <span className='font-semibold leading-5 w-full hover:bg-GrayCustom3 transition-all'>{item.description}</span>)}
              </div>
            </div>
            <TextArea
              name="business_desc"
              label="Business description"
              labelStyle="font-normal text-base leading-6 text-DarkBg6 mx-2"
              placeholder="Business description"
              value=""
              style="w-full"
              inputStyle="px-2 py-[10px]"
              onChange={()=>{}}
            />
          </div>
          <div className='flex justify-end items-center gap-5'>
            <Button 
              btnType="button"
              btnText="Cancel"
              btnStyle='bg-SecondaryAccent3 text-black font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
            />
            <Button 
              btnType="button"
              btnText="Save changes"
              btnStyle='bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
            />
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  appEnv: state.app.appEnv,
  user_data: state.auth.user_data,
  primaryPocketData: state.pocket.primaryPocket_details

})

export default connect(mapStateToProps, null)(BasicDetails)