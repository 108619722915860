import {
  SET_POCKET_DATA,
  SET_POCKET_DETAILS,
  CREATE_SUB_POCKET,
  CLEAR_NEW_SUB_POCKET_DATA
} from './pocket.type'

const POCKET_INIT_STATE = {
  primaryPocket_data: [],
  primaryPocket_details: [],
  subPocketData: []
}
  

const PocketReducer = (state = POCKET_INIT_STATE, action) => {
  switch (action.type) {
    case SET_POCKET_DATA:
      return {
        ...state,
        primaryPocket_data: action.payload
      }
    case SET_POCKET_DETAILS:
      return {
        ...state,
        primaryPocket_details: action.payload
      }
    case CREATE_SUB_POCKET:
      return {
        ...state,
        subPocketData: action.payload
      }
    case CLEAR_NEW_SUB_POCKET_DATA:
      const array1 =  action.payload

        while(array1.length > 0){ 
          array1.pop()
        }

      return {
        ...state,
        subPocketData: array1
      }
    default:
      return state
  }
}

export default PocketReducer