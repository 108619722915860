import axios from 'axios';
import { getValues } from './storage';
// import { store } from '../redux/store'

// const state = store.getState()
// console.log("State:", state.auth.token);

const userLoggedIn = getValues("loggedIn")
export const username = getValues('username')

export const BASE_URL = `${process.env.REACT_APP_PRODUCTION_BASE_URL}` //PRODUCTION_URL
// export const BASE_URL = `${process.env.REACT_APP_STAGING_BASE_URL}` //STAGING_URL

// export const PUBLIC_KEY = getValues(`${username}_public_key`)

// const axiosRequest = (reqMethod) => {
//     try{
//         // const res = axios.reqMethod(url, data)
//     }
// }

const headers = {
    "Content-Type": "application/json",
    // "Authorization": `Bearer ${token}`,
}


export const getRequest = async (url, headers) => {
    const res = await axios.get(url, headers)
    return res
}

export const postRequest = async (url, postData, headers) => {
    const res = await axios.post(url, postData, headers)
    return res
}

export const deleteRequest = async (url, postData, headers) => {}


export enum ResponseStatusEnum {
    ERROR = 'error',
    SUCCESS = 'success',
    FAIL = 'failed',
    DUPLICATE_REFERENCE = 'duplicate_reference',
    INVALID_PIN = 'invalid_pin',
    PENDING = 'pending',
    NEW_DEVICE = 'new_device',
}
