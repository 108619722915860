import { combineReducers } from 'redux'
import AppReducer from './app/app.reducer'
import AuthReducer from './auth/auth.reducer'
import PocketReducer from './Pockets/pocket.reducer'

const rootReducer = combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    pocket: PocketReducer
})

export default rootReducer