import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/atoms/Button'
import {TransactionsData} from '../../../mockData/TransactionsData'
import printerIcon from '../../../assets/svg/printer.svg'
import copyIcon from '../../../assets/svg/copy.svg'
import mastercard from '../../../assets/svg/mastercard.svg'
import nigeriaIcon from '../../../assets/svg/nigeria_flag.svg'
import PageTitleHeader from 'components/molecules/PageTitleHeader'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import ColorTag from 'components/atoms/ColorTag'
import CopyText from 'components/atoms/CopyText'
import {printContent, printStyled} from '../../../components/atoms/PrintFunction'
import {handleDownload} from '../../../components/atoms/DownloadFunction'
import { handleException } from 'utils'
import axios from 'services/axios'
import { BASE_URL } from 'services/http'
import { connect } from 'react-redux'
import Alert from 'components/atoms/Alert'

const TransactionOverview = ({bearerToken, publicKey}) => {
    const [loading, setLoading] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [data, setData] = useState<any>({});

    const transactionRef = useParams()

    const url = `transaction/reference/${transactionRef.id}`

    const fetchFunction = useCallback(async () => {
        setLoading(true)
        try{
            const res = await axios.get(`${BASE_URL + url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoading(false)
                setData(res.data.data)
            }
            return res;
        }catch(error){
            setLoading(false)  
            setErrorState(true)
            handleException(error)
        }
    }, [])

    useEffect(() => {
        fetchFunction()
    }, [fetchFunction])

    const elementId = document.getElementById('printable_content');

    const multipleBtnData = [
        {btnText:"Print Receipt", btnStyle:"bg-SecondaryAccent2 flex-row-reverse px-4 py-2 font-semibold desktop:text-sm mobile:text-xs text-DarkBg3 truncate flex-shrink-0", btnImg: printerIcon, btnImgStyle:"w-4 h-4", onClick:() => printContent(elementId)},
        {btnText:"Download Receipt", btnStyle:"bg-DarkBg3 px-10 py-2 font-semibold desktop:text-sm mobile:text-xs text-white flex-shrink-0", btnImg:"", btnImgStyle:"", onClick: () => handleDownload(elementId, elementId)}
    ]

    // const date = moment(data.createdAt).format("MMM DD, YYYY - h:mm LTS")

    return (
        <div className='py-5'>
            <PageTitleHeader 
                title="Transaction Overview"
                btn_num={2}
                btnData={multipleBtnData} 
            />
            <div id="printable_content" className='flex flex-col gap-5 border rounded-md desktop:max-w-[1142px] mobile:w-full py-10 px-16 mobile:p-4'>
                <span className='flex mobile:flex-col tablet:flex-col justify-between gap-5 py-5'>
                    <div className='desktop:w-1/2 w-full flex justify-start mobile:justify-center'>
                        <div className='w-full'>
                            <div>
                                <span className='text-base leading-7 text-SecondaryAccent'>Amount</span>
                            </div>
                            
                            <div className='flex gap-5 items-center'>
                                <span className='font-bold desktop:text-2xl tablet:text-xl mobile:text-lg leading-7 text-DarkBg3'>{data.transactionCurrency} {data.transactionAmount}</span>
                                <span className='bg-Green_Accent4 border-2 border-Green_Accent rounded-full px-2 py-1 text-Green_Accent5 text-xs leading-[15.6px] font-semibold'>Successful</span>
                                {/* <ColorTag 
                                    styleColor={`${data.status === '00' ? 'bg-Green_Accent3 border-Green_Accent2' : data.status === 'Inactive' ? 'bg-DangerAccent4 border-Danger' : 'bg-Yellow_Accent border-Yellow' } rounded-md px-3 py-1 flex gap-1 items-center`} 
                                    text={data.status === '00' ? 'Successful' : 'Failed'} 
                                    textColor={`${data.status === '00' ? 'text-Green_Accent2' : data.status === 'Inactive' ? 'text-Danger' : 'text-Yellow'} font-semibold text-sm leading-5`} 
                                    BgColor={`${data.status === '00' ? 'bg-Green_Accent2' : data.status === 'Inactive' ? 'bg-Danger' : 'bg-Yellow'}`}
                                /> */}
                            </div>
                            
                            <div className='mb-12 mt-8 flex flex-col gap-3'>
                                <span className='text-[15px] font-normal leading-7 text-DarkBg3'>{moment(data.createdAt).format("MMM DD, YYYY - LTS")}</span>
                                {/* <span className='flex gap-2 items-center'>
                                    <span className='text-xs leading-5 font-normal text-DarkBg px-3 py-1 rounded-md bg-SecondaryAccent3'>{data.reference}</span>
                                    <img src={copyIcon} alt="copyIcon" className='w-4 h-4'/>
                                </span> */}
                                <CopyText 
                                    text={data.reference}
                                    textStyle="bg-SecondaryAccent3 px-3 py-1 rounded-md w-fit font-normal text-xs leading-5 text-DarkBg"  
                                    mssgStyle='-right-[60px]' 
                                    imgStyle="w-4 h-4"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='desktop:w-1/2 w-full flex justify-end mobile:justify-center'>
                        <div className='flex flex-col w-full'>
                            <span className='font-bold text-lg leading-[21.6px] mb-5'>Customer Information</span>
                            <div className='flex flex-col gap-5 border-t py-5'>
                                <span className='w-auto mobile:w-full flex justify-between items-center'>
                                    <p className='font-normal text-xs leading-[14.4px] text-Black5'>Customer Name</p>
                                    <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>Henry Adedugba</p>
                                </span>
                                <span className='w-auto mobile:w-full flex justify-between items-center'>
                                    <p className='font-normal text-xs leading-[14.4px] text-Black5'>Email</p>
                                    <p className='font-semibold text-sm leading-[16.8px] text-Accent_blue'>{data.createdBy}</p>
                                </span>
                                <span className='w-auto mobile:w-full flex justify-between items-center'>
                                    <p className='font-normal text-xs leading-[14.4px] text-Black5'>Phone Number</p>
                                    <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>09064627930</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </span>
                <span className=''>
                    <div className='border-b py-5'>
                        <span className='font-semibold text-lg leading-[21.6px] text-black'>Payment Breakdown </span>
                    </div>
                    <div className='py-5 flex flex-col gap-6'>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Transaction Amount</p>
                            <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{data.transactionCurrency} {data.transactionAmount}</p>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Transaction Fee</p>
                            <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{data.transactionCurrency} {((10/100) * data.transactionAmount).toFixed(2)}</p>
                        </span>
                    </div>
                </span>
                <span className=''>
                    <div className='border-b py-5'>
                        <span className='font-semibold text-lg leading-[21.6px] text-black'>Other Information - Pay by transfer </span>
                    </div>
                    <div className='py-5 flex flex-col gap-6'>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Expected Amount</p>
                            <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{data.transactionCurrency} {data.transactionAmount}</p>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Transferred Amount</p>
                            <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{data.transactionCurrency} {data.transactionAmount}</p>
                        </span>
                    </div>
                </span>
                <span className=''>
                    <div className='border-b py-5'>
                        <span className='font-semibold text-lg leading-[21.6px] text-black'>Analysis </span>
                    </div>
                    <div className='py-5 flex flex-col gap-6'>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Master Card</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> <img src={mastercard} alt="mastercard" className='w-4 h-4'/> *** 1722</span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Bank Name</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> First Bank Nigeria</span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Country</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> <img src={nigeriaIcon} alt="mastercard" className='w-4 h-4'/></span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Device Type</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> Android Mobile </span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>IP Address</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> 192.168.88.1 </span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Duration</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> 3 minutes 20 seconds</span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Attempts</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> 2 attempts</span>
                        </span>
                        <span className='desktop:w-[282px] mobile:w-full flex justify-between items-center'>
                            <p className='font-normal text-xs leading-[14.4px] text-Black5'>Errors</p>
                            <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8 flex gap-2 items-center'> 0 errors</span>
                        </span>
                    </div>
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = (state:any) => ({
    bearerToken: state.auth.token,
    publicKey: state.auth.public_key
})

export default connect(mapStateToProps)(TransactionOverview)
