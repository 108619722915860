import React, { useRef, useState } from 'react'
import AppModal from 'components/organisms/CustomModal'
import copyIcon from '../../../assets/svg/copy.svg'
import Button from 'components/atoms/Button'
import FormInput from './FormInput'
import axios from 'services/axios'
import {BASE_URL} from '../../services/http'
import { connect } from 'react-redux'
import { handleException } from 'utils'
import moment from 'moment'
import Select from './SelectInput'
import Dropdown from './Dropdown'
import arrow_down from '../../assets/svg/arrow-down.svg'
import arrow_up from '../../assets/svg/arrow-up.svg'
import Alert from './Alert'
import { CSVLink, CSVDownload } from 'react-csv'
import Spinner from './Spinner'

const exportSize = [10, 25, 50, 100, 200]

const ExportModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [exportData, setExportData] = useState([])
    const [dropdown, setDropdown] = useState(false)
    const [selectSize, setSelectedSize] = useState('')
    const [btnErr, setBtnErr] = useState('')

    const defaultStartDate = moment().subtract('1', 'days').format("DD-MM-YYYY");
    const defaultEndDateTwo = moment().format("DD-MM-YYYY");
    const defaultEndDate = Date()

    const fetchDataToExport = async (inputData) => {
        setLoading(true)
        const finalURL = (inputData.start && inputData.end === "")
                    ? `${props.csvUrl}createdOnStartDate=${moment(inputData.start).format("DD-MM-YYYY")}`

                    : (inputData.start === "" && inputData.end)
                    ? `${props.csvUrl}createdOnEndDate=${moment(inputData.end).format("DD-MM-YYYY")}`

                    : (inputData.start && inputData.end)
                    ? `${props.csvUrl}createdOnStartDate=${moment(inputData.start).format("DD-MM-YYYY")}&createdOnEndDate=${moment(inputData.end).format("DD-MM-YYYY")}`

                    : `${props.csvUrl}`
        try{
            const res = await axios.get(`${BASE_URL + finalURL}&size=${selectSize}&page=0`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.bearerToken}`,
                    "Public-Key": `${props.publicKey}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                handleException(res.data.message)
            }else{
                setLoading(false)
                if(res.data.data.content.length < 1){
                    setBtnErr('There nothing to export here')
                    Alert('error', 'Opps, Nothing to export here')
                }else{
                    setExportData(res.data.data.content)
                    Alert('success', `Ready for export`)
                }
                
                // setTimeout(() => {
                //     Alert('success', `Export ${res.data.message}ful`)
                // }, 3000)
            }
        }catch(error){
            setLoading(false)
            handleException(error)
        }
    }

    const handleSubmit = () => {
        // else if(startDate >= defaultEndDate){
        //     Alert('error','Start date must be at least 1 day before the present day')
        // }else if(endDate > defaultEndDate){
        //     Alert('error','End date cannot exceed the present day')
        // }

        if(startDate === ""){
            Alert('error','Enter a valid start date')
        }else if(endDate === ""){
            Alert('error','Enter a valid end date')
        }else if(startDate > endDate){
            Alert('error','Start date cannot exceed the End date')
        }else if(startDate === endDate){
            Alert('error','Start date and End date cannot be the same')
        }else if(endDate > defaultEndDate){
            Alert('error','End date cannot exceed the present day')
        }else{
            const inputData = ({
                start: startDate,
                end: endDate
            })

            fetchDataToExport(inputData)
        }
    }

    const formComplete = startDate !== "" && endDate !== "";
    const disabledBtn = formComplete === false || loading === true;
    
    return (
        <AppModal 
            handleClose={props.toggleModal} 
            modalStyle="desktop:w-2/6 w-3/5 mobile:w-4/5 bg-white min-h-fit z-30 top-0 left-0 right-0 p-8 my-[10rem] mobile:my-[3rem] mx-auto animate-slide_down rounded-xl" 
            contentStyle="overflow-y-scroll min-h-fit"
        >
            <div className='flex flex-col text-left mb-8'>
                <span className='font-normal text-2xl mobile:text-lg text-black mb-5'>Export to CSV</span>
                <span className='text-[14px] mobile:text-xs leading-7 text-SecondaryAccent'>Select date range to export all {props.titleName} data between selected range.</span>
            </div>

            <div className='min-h-fit flex flex-col gap-5 mobile:gap-2 justify-between'>
                <div className='rounded-md flex mobile:flex-col flex-shrink gap-5 mobile:gap-2 w-full'>
                    <FormInput
                        label="Start Date"
                        labelStyle="text-GrayCustom7 text-xs"
                        type="date"
                        name="start_date"
                        style="rounded-lg w-full"
                        inputStyle={`${startDate === '' ? 'text-GrayCustom' : 'text-DarkBg3'} border`}
                        value={startDate === '' ? defaultStartDate : startDate}
                        onChange={e=>setStartDate((e.target.value))}
                    />
                    <FormInput
                        label="End Date"
                        labelStyle="text-GrayCustom7 text-xs"
                        type="date"
                        name="end_date"
                        style="rounded-lg w-full"
                        inputStyle={`${endDate === '' ? 'text-GrayCustom' : 'text-DarkBg3'} border`}
                        value={endDate === '' ? defaultEndDate : endDate}
                        onChange={e=>setEndDate && setEndDate((e.target.value))}
                    />
                </div>
                <div className='min-h-[7vh] mb-5'>
                    <Dropdown
                        data={exportSize}
                        style="absolute -right-6 bottom-0 w-1/2 animate-slide_down"
                        textStyle="text-sm"
                        containerStyle='min-w-[200px] max-w-[300px]'
                        dropdown={dropdown}
                        handleSelect={setSelectedSize}
                        setDropdown={setDropdown}
                    >
                        <FormInput
                            label={`Select size to be exported`}
                            labelStyle="text-GrayCustom7 text-xs"
                            type="text"
                            name="searchItem"
                            // placeholder={`Select size`}
                            placeholder={selectSize === '' ? '10' : selectSize}
                            style="rounded-lg w-fit mobile:w-full"
                            inputStyle="flex-row-reverse border cursor-pointer"
                            img={dropdown ? arrow_up : arrow_down}
                            imgStyle="w-4 h-4 cursor-pointer"
                            imgOnClick={()=>setDropdown(prevState => !prevState)}
                            value={selectSize}
                            validationErr=""
                            disabled={true}
                            onChange={()=>{}}
                        />
                    </Dropdown>
                </div>
                <div className='w-full flex flex-col gap-3'>
                    {exportData.length < 1 
                        ?   <>
                                <Button 
                                    btnType="button" 
                                    btnText={
                                        loading ? (
                                            <Spinner text="Preparing csv file..." textStyle="text-DarkBg3" />
                                        ) : (
                                            btnErr ? btnErr : "Fetch data"
                                        )
                                    }
                                    btnStyle="px-3 py-2 font-semibold text-base leading-7 bg-SecondaryAccent5 text-DarkBg3 flex-row-reverse rounded-lg truncate w-full" 
                                    disabled={disabledBtn} 
                                    disabledClass="cursor-not-allowed bg-GrayCustom text-DarkBg3"
                                    onClick={handleSubmit}
                                />
                                {btnErr && <span className='text-xs font-semibold leading-5 text-Primary'>Perhaps you should select a different date range.</span>}
                            </>

                        :   <CSVLink
                                filename={`${props.titleName}-file.csv`}
                                headers={props.headers}
                                data={exportData}
                            >
                                <div className='px-3 py-2 font-semibold text-base leading-7 bg-DarkBg3 text-SecondaryAccent5 flex-row-reverse rounded-lg truncate w-full flex justify-center items-center'>
                                    <span>
                                        Export data
                                    </span>
                                </div>
                            </CSVLink>
                    }
                </div>
            </div>
        </AppModal>
    )
}


const mapStateToProps = (state) => ({
    bearerToken: state.auth.token,
    publicKey: state.auth.public_key
})

export default connect(mapStateToProps)(ExportModal)