import React, {useEffect, useState} from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

type Props = {
    title:string, 
    active:boolean,
    link:string,
    onClick: (e) => void
}

const BusinessInfoNavItem: React.FC<Props> = ({title, active, link, onClick}) => {
    const [style, setStyle] = useState(active)
    
    const LoadItemStyle = (isActive) => {
        const standardStyle = `flex justify-between cursor-pointer font-normal desktop:text-[15px] desktop:leading-7 tablet:text-xs tablet:leading-4 w-full`
        const activeClassName = `text-bold`

        setStyle(isActive)
        
        if(isActive){
            return `${activeClassName + standardStyle} text-DarkBg3`
        }else{
            return `${standardStyle} text-Black2`
        }
    }
    
    return (
        <div className={`border-r-2 mobile:border-none pr-2  ${style ? "flex justify-between gap-5 border-black" : "border-white"}`}> 
            <NavLink to={link} className={({ isActive }) => LoadItemStyle(isActive)} onClick={onClick}>
                <div className={`${style ? 'bg-SecondaryAccent8 mobile:font-semibold' : 'bg-white'} w-full truncate px-3 py-1 transition-all rounded-md`}>
                    <span className='text-[15px] font-normal leading-5'>{title}</span>
                </div>
            </NavLink>
            {/* {style && <div className="border border-black rounded-xl"></div>} */}
        </div>
    )
}

export default BusinessInfoNavItem