import React, { useEffect, useState } from 'react'
import FormInput from 'components/atoms/FormInput'
import { useNavigate } from 'react-router-dom'
import { setAuthRes, setUserLoggedIn } from 'redux/auth/auth.action'
import { setValues, removeValues } from 'services/storage'
import { NotificationBell } from '../atoms/ImagesImport'
import arrowDownIcon from '../../assets/svg/arrow-down.svg'
import arrowUpIcon from '../../assets/svg/arrow-up.svg'
import userIcon from '../../assets/svg/navDropdownIcon/user.svg'
import profileTick from '../../assets/svg/navDropdownIcon/profile-tick.svg'
import configureIcon from '../../assets/svg/navDropdownIcon/configure.svg'
import messageIcon from '../../assets/svg/navDropdownIcon/message.svg'
import logoutIcon from '../../assets/svg/navDropdownIcon/logout.svg'
import searchIcon from '../../assets/svg/search-icon.svg'
import menuIcon from '../../assets/svg/menu-icon.svg'
import seerbitBoxedLogo from '../../assets/svg/SeerBit boxed Icon SVG.svg'
import { connect } from 'react-redux'
// import { toggleModal } from '../../redux/app/app.action'
import AppModal from '../organisms/CustomModal'
import SideNav from './SideNav'
import Dropdown from 'components/atoms/Dropdown'
import UserProfileNav from './UserProfileNav'

const MobileNav = () => {
    const [value, setValue] = useState("")
    const [dropdown, setDropdown] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalId, setModalId] = useState("")


    const navigate = useNavigate()

    const handleOnChange = e => {
        setValue(e.target.value)
    }

    const handleModal = (e) => {
        setModalId(e.target.id)
        setShowModal(prevState => !prevState)
    }

    const dropdownData = [
        {icon: userIcon, title: "Profile", style: "", link: "", action: () => {}},
        {icon: profileTick, title: "Permissions", style: "", link: "", action: () => {}},
        {icon: configureIcon, title: "Configurations", style: "", link: "", action: () => {}},
        {icon: messageIcon, title: "Support", style: "", link: "", action: () => {}},
        {icon: logoutIcon, title: "Logout", style: "border-t", link: "", action: () => handleLogout()}
    ]

    const handleLogout = () => {
        setAuthRes([]);
        setValues('loggedIn', false);
        setUserLoggedIn(false);
        removeValues("token")
        navigate('/auth/login');
    }

    const modalContent = (
        <AppModal handleClose={handleModal} modalStyle="bg-white min-h-screen z-40 top-0 left-0 animate-slide_right2 flex justify-center items-center" contentStyle="h-full w-full absolute top-0 left-0 right-0">
            <SideNav setShowModal={setShowModal} setModalId={setModalId}/>
        </AppModal>
    )

    const sideModalContent = (
        <AppModal handleClose={handleModal} modalStyle="desktop:w-1/4 mobile:w-3/4 bg-white min-h-screen z-30 top-0 right-0 animate-slide_left p-8" contentStyle="overflow-y-scroll h-[92vh] flex flex-col justify-between py-8">
            <UserProfileNav/>
        </AppModal>   
    )

    return (
        <>
            <span className='justify-center ml-8' onClick={e=>handleModal(e)}>
                <img src={seerbitBoxedLogo} alt="menu_icon" className='' id="mainNav"/>
            </span>
            <span className='w-1/2 ml-8 mr-5'>
                <FormInput
                    type="search"
                    name="searchItem"
                    placeholder="Search payment references, transactions, clients"
                    style={'text-Black2'}
                    inputStyle="bg-GrayCustom6 text-Black2"
                    inputStyle2={`text-xs leading-5 text-GrayCustom7 pl-8`}
                    value={value}
                    img={searchIcon}
                    imgStyle="w-4 h-4 !relative !left-5"
                    validationErr=""
                    onClick={()=>{}}
                    onChange={handleOnChange}
                />
            </span>
            <span className='flex items-center gap-3 mr-8'>
                <div className='relative flex flex-shrink-0'>
                    <span className='flex-shrink-0'>
                        <NotificationBell style="w-7 h-7"/>
                    </span>
                    <span className='flex justify-center items-center absolute top-1 right-0 rounded-full border border-Primary text-center bg-Primary text-white font-semibold text-xs leading-5 ' style={{width:"13.5px", height:"13.5px"}}>
                        5
                    </span>
                </div>
                <span className='flex flex-shrink-0 justify-center' onClick={e=>handleModal(e)}>
                    <img src={menuIcon} alt="menu_icon" className='' id="sideNav"/>
                </span>
            </span>

            
            {showModal && modalId === "mainNav" && modalContent}
            {showModal && modalId === "sideNav" && sideModalContent}
        </>
    )
}

export default MobileNav