import React from 'react';

type Props = {
  handleChange: () => void,
  checked: boolean,
  label?: string,
  labelStyle?: string,
  style?: string
}

const Toggle = ({ handleChange, checked, label, labelStyle, style }: Props) => {
  return (
    <div className="inline-block w-fit">
      <label htmlFor="toggle-id" className={`${label && 'mr-5'} inline-flex relative items-center cursor-pointer`}>
        <input type="checkbox" value="" id="toggle-id" className="sr-only peer" onChange={handleChange} checked={checked}/>
        <div className={`${style} bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:shadow-md after:rounded-full after:transition-all`}></div>
        {label && <span className={`${labelStyle} ml-3 text-sm font-medium`}>{label}</span>}
      </label>
    </div>
  )
}

export default Toggle;