import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../atoms/Button'
import PageTitle from '../atoms/PageTitle'

type Props = {
    title: string,
    btn_num: number,
    btnData?: any[],
    subText?: string,
    subTextStyle?: string
}

const MobilePageHeader: React.FC<Props> = (props) => {
    return (
        <div className='flex flex-wrap justify-between gap-4 items-center mb-8'>
            <span className={`flex ${props.subText && 'flex-col'}`}>
                <PageTitle
                    pageTitle={props.title}
                    pageTitleStyle='text-left'
                />
                {props.subText &&
                    <p className={`${props.subTextStyle} text-wrap`}>
                        {props.subText}
                    </p>
                }
            </span>
            <span className='flex gap-5 flex-wrap'>
            {props.btn_num >= 1 &&
                props.btnData?.map((item, i) => (
                    <Button
                        key={i}
                        btnType="button"
                        btnText={item.btnText}
                        btnImg={item.btnImg && item.btnImg}
                        btnImgStyle={item.btnImgStyle}
                        btnStyle={item.btnStyle}
                        onClick={item.onClick}
                    />
                ))
            }
            </span>
        </div>
    )
}

export default MobilePageHeader

{/* <Button
                        key={i}
                        btnType="button"
                        btnText={item.btnText}
                        btnImg={item.btnImg && item.btnImg}
                        btnImgStyle={item.btnImgStyle}
                        btnStyle={item.btnStyle}
                        onClick={item.onClick}
                    /> */}