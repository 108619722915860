import React, { useState } from 'react'
import PageTitle from 'components/atoms/PageTitle'
import FormInput from 'components/atoms/FormInput'
import Select from 'components/atoms/SelectInput'
import RadioAndCheckInput from 'components/atoms/RadioAndCheckInputGroup/RadioAndCheckInput'
import Dropdown from 'components/atoms/Dropdown/index'
import arrow_down from '../../../../assets/svg/arrow-down.svg'
import arrow_up from '../../../../assets/svg/arrow-up.svg'
import helpIcon from '../../../../assets/svg/help-circle_2.svg'
import {SubPocketData} from './SubPocketData'
import Button from 'components/atoms/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { clearNewSubPocketData, createSubPocket } from '../../../../redux/Pockets/pocket.action'
import { connect } from 'react-redux'
import { ErrorMssg } from 'components/atoms/ErrorMssg'
import Alert from 'components/atoms/Alert'

const BasicInfo = ({subPocketData, createSubPocket, clearNewSubPocketData}) => {
    const location = useLocation()
    const navigate = useNavigate()
    
    const [dropdown, setDropdown] = useState(false)
    const [pocketType, setPocketType] = useState(false)
    const [radioSelect, setRadioSelect] = useState('')
    const [currency, setCurrency] = useState("")
    const [pocketFunc, setPocketFunc] = useState("")   
    const [pocketRef, setPocketRef] = useState("")   

    const [currencyErr, setCurrencyErr] = useState("")

    const {ownerOptData, currencyData, pocketFunctionData} = SubPocketData()

    const absolutePath = '/dashboard/sub_pockets/create_sub_pocket/'

    const currentPath = location.pathname.split('/').slice(-1).toString()    

    const handleSubmit = (e?: any) => {
        e.preventDefault()

        if(currency === "" || radioSelect === "" || pocketFunc === "" || pocketRef === ""){
            Alert('error', 'Fill all fields')
        }else{
            navigate(`${absolutePath}account_verification`)
            console.log("subPocketData:", subPocketData)
            Alert('success','Next')
        }

        createSubPocket({...subPocketData, ...{
            currency: currency,
            owner: radioSelect,
            pocketType: pocketFunc,
            pocketRef: pocketRef
        }})
        console.log("subPocketData:", subPocketData)
    } 
    
    const handleEvent = () => {
        navigate('/dashboard/sub_pockets/')
        clearNewSubPocketData()
        console.log("subPocketData:", subPocketData)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='w-[342px] mobile:w-full'>
                <PageTitle 
                    pageTitle="Provide basic Information" 
                    pageTitleStyle="!font-semibold !text-xl !my-0"
                />
                <div className='flex flex-col gap-5 mt-8'>
                    <Dropdown
                        dropdown={dropdown}
                        setDropdown={setDropdown}
                        dropdownData={currencyData}
                        dropdownStyle="-right-0 top-20 animate-slide_down !gap-3"
                        selector={true}
                        arrowStyle="absolute right-4 bottom-4"
                        handleSelect={setCurrency}
                    >
                        <div className='flex flex-col gap-2 w-full'>
                            <span className='font-semibold text-base leading-6 text-Black2 mx-2'>Currency</span>
                            <div className='border rounded-md p-3 w-full'>
                                <p className='font-normal text-sm text-BackDrop_d_lg'>{currency === "" ? "Select currency" : currency}</p>
                            </div>
                            {/* <ErrorMssg validationErr={}/> */}
                        </div>
                    </Dropdown>

                    <RadioAndCheckInput 
                        type="radio" 
                        name="selfOwned" 
                        inputData={ownerOptData} 
                        containerStyle="gap-2 flex-col"
                        label='Who is this pocket for?'
                        labelStyle='font-semibold text-base leading-6 text-Black2'
                        handleSelect={setRadioSelect}
                    />

                    <Dropdown
                        dropdown={pocketType}
                        setDropdown={setPocketType}
                        dropdownData={pocketFunctionData}
                        dropdownStyle="-right-0 top-20 animate-slide_down !gap-3"
                        selector={true}
                        arrowStyle="absolute right-4 bottom-4"
                        handleSelect={setPocketFunc}
                    >
                        <div className='flex flex-col gap-2 w-full'>
                            <span className='font-semibold text-base leading-6 text-Black2 mx-2'>Select pocket type</span>
                            <div className='border rounded-md p-3 w-full'>
                                <p className='font-normal text-sm text-BackDrop_d_lg'>{pocketFunc === "" ? "Select pocket function" : pocketFunc}</p>
                            </div>
                        </div>
                    </Dropdown>
                    
                    <FormInput 
                        type="text"  
                        name="reference" 
                        placeholder="Enter pocket reference number" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="Reference" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        img={helpIcon}
                        imgOnClick={()=>{}}
                        imgStyle="w-4 h-4"
                        value={pocketRef}
                        onChange={e => setPocketRef(e.target.value)}
                        validationErr=''
                    />
                </div>
            </div>
        
            <div className='flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]'>
                <Button 
                    btnType="button"
                    btnText={`${currentPath === 'basic_info' ? 'Cancel' : 'Previous'}`}
                    btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={handleEvent}
                />
                <Button 
                    btnType="submit" 
                    btnText={`${currentPath === 'finish_setup' ? 'Finish' : 'Next'}`}
                    btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={()=>{}}
                />
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    subPocketData: state.pocket.subPocketData
})

const mapDispatchToProps = dispatch => ({
    createSubPocket: (data) => dispatch(createSubPocket(data)),
    clearNewSubPocketData: ()   => dispatch(clearNewSubPocketData())
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)