import React, { useState } from 'react'
import InfoNavItem from 'components/atoms/InfoNavItem'

const InfoNav = (props) => {
  const [selected, setSelected] = useState("")

  return (
    <div className={`flex flex-col gap-2 ${props.navStyle}`}>
    { props.navData.map(item => (
        <InfoNavItem
          title={item.title}
          active={item.title === selected}
          link={`${props.navPath}${item.link}`}
          onClick={e => setSelected(e.target.dataset.name)}
        />
    ))}
    </div>
  )
}

export default InfoNav