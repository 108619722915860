import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ToTitleCase } from '../CaseManager'

type Props = {
    title?: string,
    icon?: string,
    iconStyle?: string,
    value?: string,
    link?: string,
    style?: string,
    fontStyle?: string,
    toggler?: (data:boolean) => void,
    handleClick?: (data?:any) => void
}

const DropdownItem = (props: Props) => {
    const navigate = useNavigate()
    
    const clickHandler = () => {
        if(props.link !== null){
            navigate(`${props.link}`)
        } else if(props.handleClick !== null){
            props.handleClick && props.handleClick()
        }

        props.toggler && props.toggler(false)
    }

    return (
        <div   
            className={`${props.style} flex gap-3 items-center w-full rounded-md px-3 py-2 my-1 group hover:bg-BackDrop_d_xs cursor-pointer`} 
            onClick={clickHandler}
        >
            {props.icon && <img src={props.icon} alt="item_icon" className={`${props.iconStyle}`}/>}
            <span className={`${props.fontStyle} font-normal text-base group-hover:font-semibold`}>{ToTitleCase(props.title)}</span>
            {/* <span className={`${props.fontStyle} font-normal text-base group-hover:font-semibold`}>{props.title}</span> */}
        </div>
    )
}

export default DropdownItem