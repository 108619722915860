import React from 'react'

type Props = {
    name: string,
    label?: string,
    labelStyle?: string,
    placeholder?: string,
    value?: string,
    img?: string,
    imgStyle?: string,
    style?: string,
    inputStyle?: string,
    // onChange: React.ChangeEventHandler<HTMLInputElement>
    onChange: () => void
}

const TextArea: React.FC<Props> = ({
    name,
    label,
    labelStyle,
    placeholder,
    value,
    img,
    imgStyle,
    style,
    inputStyle,
    onChange
}) => {
    return (
        <div className={`${style} flex flex-col`}>
            {label && <label className={`${labelStyle} m-2`}>{label}</label>}
            <span className={`${inputStyle} flex border rounded-md px-4 py-2 overflow-hidden`}>
                {img && <img src={img} alt="icon" className={`${imgStyle}`}/>}
                <textarea 
                    placeholder={placeholder} 
                    name={name}
                    className="w-full font-normal text-[15px] leading-7"
                    onChange={onChange}
                >
                    {value}
                </textarea>
            </span>
        </div>
    )
}

export default TextArea