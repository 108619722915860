import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import link_arrowRight from '../../assets/svg/Settings_Roles/link_arrow-right.svg'

type Props = {
    role: string, 
    desc?: string, 
    icon?: string
}

const RoleCard: React.FC<Props> = ({role, desc, icon}) => {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col items-center gap-2 border rounded-md px-10 py-6'>
            <span className='bg-DarkBg3 w-[50px] h-[50px] rounded-lg flex justify-center items-center mb-2'>
                {icon && <img src={icon} alt="role_icon" className='w-[24px] h-[24px]'/>}
            </span>
            <span className='font-normal text-lg leading-7 text-DarkBg-7 text-center mb-3'>{desc}</span>
            <span className='font-normal text-sm leading-5 text-GrayCustom10 text-center mb-1 max-w-[262px]'>Short description of what <br/> the role  is about the privileges that comes with it.</span>
            <span className='font-normal text-xs leading-5 text-Accent_blue flex gap-1 cursor-pointer' onClick={() => navigate(`/dashboard/settings/role_permission/${role}`)}>View permission <img src={link_arrowRight} alt="link_arrowRight"/></span>
        </div>
    )
}

export default RoleCard