import Spinner from 'components/atoms/Spinner'
import React from 'react'
import walletLight from '../../assets/svg/wallet-1.svg'
import walletDark from '../../assets/svg/wallet-2.svg'
import {RefreshIcon, CloseIcon} from '../atoms/ImagesImport'

type Props = {
    id?:number 
    currency: string, 
    balance: any,
    desc: string,
    loading?: boolean,
    removeItem?: (e:any) => void
}

const PocketBalCard:React.FC<Props> = ({id, currency, balance, desc, loading, removeItem}) => {

    return (
        <>
        {loading 
            ?   <div className={`absolute z-30 flex items-center py-10 justify-center w-full h-full bg-white bg-opacity-75`}>
                    <Spinner
                        text="Loading..."
                    />
                </div> 

            :   <div className='relative group hover:bg-Black4 hover:transition-all bg-white flex flex-col gap-5 border p-5 w-fit mobile:w-full rounded-lg desktop:min-w-[300px] min-w-[240px] max-w-[314px] mobile:max-w-full flex-shrink-0 hover:shadow-md'>
                    <div className='flex justify-between'>
                        <div className=''>
                            <img src={walletLight} alt="walletIcon" className='absolute top-5 left-5 hidden hover:transition-all group-hover:inline-block'/>
                            <img src={walletDark} alt="walletIcon" className='absolute top-5 left-5 hover:transition-all group-hover:hidden'/>
                        </div>
                        <div className='flex gap-3 '> 
                            <span className='group-hover:text-white'>{id}</span>
                            <span><RefreshIcon/></span>
                            <span onClick={removeItem}><CloseIcon id={id}/></span>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <span className='hover:transition-all group-hover:text-white text-black flex gap-1 font-semibold text-xl leading-6 tracking-wider '>
                            <p className='font-bold'>{currency}</p>
                            <p>{balance}</p>
                        </span>
                        <span className='font-normal text-base leading-[30px] text-SecondaryAccent'>{desc}</span>
                    </div>
                </div>
        }
        </>
    )
}

export default PocketBalCard