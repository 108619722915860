import React, { useState } from 'react'
import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'
import FormInput from 'components/atoms/FormInput'
import arrow_down from '../../../../assets/svg/arrow-down.svg'
import arrow_up from '../../../../assets/svg/arrow-up.svg'

const Customers = () => {
  const [dropdown, setDropdown] = useState(false)
  const [selectedIndustry, setSelectedIndustry] = useState("")

  const component = (<></>)
  return (
    <div className='w-full desktop:w-[342px]'>
      <form className='flex flex-col gap-5'>
        <FormInput 
          type="text"  
          name="business_address" 
          placeholder="business_address" 
          inputStyle="border border-transparent text-Black2"
          label="Business address" 
          labelStyle="font-normal text-base leading-6 text-DarkBg6" 
          style='w-full'
          onChange={()=>{}}
          value=""
          validationErr=""
        />
        <FormInput 
          type="text"  
          name="business_city" 
          placeholder="business_city" 
          inputStyle="border border-transparent text-Black2"
          label="Business city" 
          labelStyle="font-normal text-base leading-6 text-DarkBg6" 
          style='w-full'
          onChange={()=>{}}
          value=""
          validationErr=""
        />
        <Dropdown
          component={component}
          style="absolute right-0 top-24 w-full animate-slide_down max-h-[200px] overflow-y-scroll !p-1 !gap-1" 
          textStyle="text-sm"
          containerStyle='w-full'
          dropdown={dropdown}
          setDropdown={setDropdown}
        >
          <div className='flex flex-col gap-[10px] w-full'>
            <label className='font-normal text-base leading-6 text-DarkBg6 mx-2'>Business state</label>
            <div className='flex justify-between items-center border w-full rounded-md pr-2'>
              <span className={`${selectedIndustry === "" ? "text-GrayCustom" : "text-GrayCustom4"} px-2 py-[10px] text-sm leading-5 font-normal`}>
                {selectedIndustry === "" ? "Select Industry" : selectedIndustry}
              </span>
              <span className='p-1' onClick={()=>setDropdown(prevState => !prevState)}>
                <img src={dropdown ? arrow_up : arrow_down} alt="arrowIcon" className="w-4 h-4"/>
              </span>
            </div>
          </div>
        </Dropdown>
        <Dropdown
          component={component}
          style="absolute right-0 top-24 w-full animate-slide_down max-h-[200px] overflow-y-scroll !p-1 !gap-1" 
          textStyle="text-sm"
          containerStyle='w-full'
          dropdown={dropdown}
          setDropdown={setDropdown}
        >
          <div className='flex flex-col gap-[10px] w-full'>
            <label className='font-normal text-base leading-6 text-DarkBg6 mx-2'>Business country</label>
            <div className='flex justify-between items-center border w-full rounded-md pr-2'>
              <span className={`${selectedIndustry === "" ? "text-GrayCustom" : "text-GrayCustom4"} px-2 py-[10px] text-sm leading-5 font-normal`}>
                {selectedIndustry === "" ? "Select Industry" : selectedIndustry}
              </span>
              <span className='p-1' onClick={()=>setDropdown(prevState => !prevState)}>
                <img src={dropdown ? arrow_up : arrow_down} alt="arrowIcon" className="w-4 h-4"/>
              </span>
            </div>
          </div>
        </Dropdown>
        <div className='flex justify-end items-center gap-5'>
          <Button 
            btnType="button"
            btnText="Cancel"
            btnStyle='bg-SecondaryAccent3 text-black font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
          />
          <Button 
            btnType="button"
            btnText="Save changes"
            btnStyle='bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
          />
        </div>
      </form>
    </div>
  )
}

export default Customers