import React from 'react'
import authSideImg from '../../assets/images/authSideImg.png'
import checkMailImg from '../../assets/gif/Rectangle.gif'
import passwdResetSuccessImg from '../../assets/gif/success_1.gif'
import SeerbitLogo from '../../assets/svg/seerbit_logo_typed.svg'
import notificationBell from '../../assets/svg/notification.svg'
import homeIcon_solid from '../../assets/svg/hubSolid.svg'
import homeIcon_outline from '../../assets/svg/hubOutline.svg'
import receiptIcon_solid from '../../assets/svg/receipt-solid.svg'
import receiptIcon_outline from '../../assets/svg/receipt-outline.svg'
import businessIcon_solid from '../../assets/svg/shop-solid.svg'
import businessIcon_outline from '../../assets/svg/shop-outline.svg'
import walletIcon_solid from '../../assets/svg/wallet-solid.svg'
import walletIcon_outline from '../../assets/svg/wallet-outline.svg'
import activityIcon_solid from '../../assets/svg/activity-solid.svg'
import activityIcon_outline from '../../assets/svg/activity-outline.svg'
import settingsIcon_outline from '../../assets/svg/setting-outline.svg'
import wallet1 from '../../assets/svg/wallet-1.svg'
import wallet2 from '../../assets/svg/wallet-2.svg'
import refreshIcon from '../../assets/svg/refresh.svg'
import closeIcon from '../../assets/svg/close-circle.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import addPocketIcon from '../../assets/svg/pocketPlus.svg'
import editIcon from '../../assets/svg/edit-2.svg'
import trashIcon from '../../assets/svg/trash.svg'
import exportIcon from '../../assets/svg/export.svg'
import receipt_outline from '../../assets/svg/receipt-text_outline.svg'
import receipt_solid from '../../assets/svg/receipt-text_solid.svg'

export const CheckMailImg = () => {
    return  <img src={checkMailImg} alt="gifImg" className='w-52 h-52'/>
}

export const PwdResetSuccessImg = () => {
  return  <img src={passwdResetSuccessImg} alt="gifImg" className='w-52 h-52'/>
}

export const SeerbitLogoTyped = () => {
  return <img src={SeerbitLogo} alt="seerbit_logo_typed" className='w-40 h-auto'/>
}

export const NotificationBell = ({style}) => {
  return <img src={notificationBell} alt="notification_bell" className={`${style}`}/>
}

export const HomeIconSolid = () => {
  return <img src={homeIcon_solid} alt="homeIcon" className='w-5 h-5'/>
}

export const HomeIconOutline = () => {
  return <img src={homeIcon_outline} alt="homeIcon" className='w-5 h-5'/>
}

export const BusinessIconSolid = () => {
  return <img src={businessIcon_solid} alt="businessIcon" className='w-5 h-5'/>
}

export const BusinessIconOutline = () => {
  return <img src={businessIcon_outline} alt="businessIcon" className='w-5 h-5'/>
}

export const WalletIconSolid = () => {
  return <img src={walletIcon_solid} alt="walletIcon" className='w-5 h-5'/>
}

export const WalletIconOutline = () => {
  return <img src={walletIcon_outline} alt="walletIcon" className='w-5 h-5'/>
}

export const ActivityIconSolid = () => {
  return <img src={activityIcon_solid} alt="activityIcon" className='w-5 h-5'/>
}

export const ActivityIconOutline = () => {
  return <img src={activityIcon_outline} alt="activityIcon" className='w-5 h-5'/>
}

export const SettingsIconOutline = () => {
  return <img src={settingsIcon_outline} alt="settingsIcon" className='w-5 h-5'/>
}

export const RefreshIcon = () => {
  return <img src={refreshIcon} alt="refreshIcon" className='w-5 h-5'/>
}

export const CloseIcon = ({id}) => {
  return <img src={closeIcon} alt="closeIcon" id={id} className='w-5 h-5'/>
}

export const ArrowDownIcon = ({style}) => {
  return <img src={arrowDown} alt="arrowDownIcon" className={`${style}`}/>
}

export const EditIcon = () => {
  return <img src={editIcon} alt="editIcon" className='w-5 h-5'/>
}

export const TrashIcon = () => {
  return <img src={trashIcon} alt="trashIcon" className='w-5 h-5'/>
}

export const ExportIcon = () => {
  return <img src={exportIcon} alt="exportIcon" className='w-5 h-5'/>
}

export const ReceiptSolid = () => {
  return <img src={receipt_solid} className='w-5 h-5'/>
}
export const ReceiptOutline = () => {
  return <img src={receipt_outline} className='w-5 h-5'/>
}