import {
    SET_TOKEN,
    SET_PUBLIC_KEY,
    SET_USER_LOGGED_IN,
    AUTH_RESPONSE,
    SET_USER_DATA,
    SET_PRIMARY_POCKET_DATA,
    RESET_STATE
} from './auth.type'

const INIT_STATE = {
    token: null,
    public_key: null,
    auth_data: null,
    user_data: null,
    pocket_data: null,
    userLoggedIn: false,
}

const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_PUBLIC_KEY:
            return {
                ...state,
                public_key: action.payload
            }
        case SET_USER_LOGGED_IN: 
            return {
                ...state,
                userLoggedIn: action.payload
            }
        case AUTH_RESPONSE:
            return {
                ...state,
                auth_data: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                user_data: action.payload
            }
        case SET_PRIMARY_POCKET_DATA:
            return {
                ...state,
                pocket_data: action.payload
            }
        case RESET_STATE:
            return INIT_STATE
        default:
            return state
    }
}

export default AuthReducer
