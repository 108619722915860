import { useCallback, useState } from 'react'
import userIcon from '../../assets/svg/navDropdownIcon/user.svg'
import profileTick from '../../assets/svg/navDropdownIcon/profile-tick.svg'
import configureIcon from '../../assets/svg/navDropdownIcon/configure.svg'
import messageIcon from '../../assets/svg/navDropdownIcon/message.svg'
import logoutIcon from '../../assets/svg/navDropdownIcon/logout.svg'
import { setValues, getValues, removeValues } from 'services/storage'
import { useNavigate } from 'react-router-dom'
import Alert from 'components/atoms/Alert'
import { handleException, logoutFunc } from 'utils'

const NavDropdownData = (logoutHandler, bearerToken) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const dropdownData = [
        {icon: userIcon, title: "Profile", style: "", link: `/dashboard/settings/profile`, action: () => null},
        {icon: profileTick, title: "Permissions", style: "", link: `/dashboard/settings/role_permission/`, action: () => null},
        {icon: configureIcon, title: "Configurations", style: "", link: `/dashboard/settings/default_configuration`, action: () => null},
        {icon: messageIcon, title: "Support", style: "", link: `/dashboard/settings/profile`, action: () => null},
        {icon: logoutIcon, title: "Logout", style: "border-t ", link: null, action: logoutFunc}
    ]

    return {dropdownData}
}

export default NavDropdownData