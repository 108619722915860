import React, { useCallback, useEffect, useState } from 'react'
import {FaHome, FaReceipt, FaFileInvoiceDollar, FaCodeBranch, FaGetPocket} from 'react-icons/fa'
import {MdMessage, MdKeyboardArrowDown, MdPayments, MdPeopleAlt, MdSubscriptions} from 'react-icons/md'
import {GoPackage} from 'react-icons/go'
import {HiChevronUpDown, HiShoppingCart} from 'react-icons/hi2'
import {HiDeviceMobile} from 'react-icons/hi'
import {IoLinkSharp, IoSettings, IoReceiptOutline ,IoReceipt} from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import SideNavItem from 'components/atoms/SideNavItem'
import {    SeerbitLogoTyped, 
            HomeIconSolid, 
            HomeIconOutline,
            BusinessIconSolid,
            BusinessIconOutline,
            WalletIconSolid,
            WalletIconOutline,
            ActivityIconSolid,
            ActivityIconOutline,
            SettingsIconOutline,
            ReceiptSolid,
            ReceiptOutline
        } from '../atoms/ImagesImport'
import receipt_outline from '../../assets/svg/receipt-text_outline.svg'
import receipt_solid from '../../assets/svg/receipt-text_solid.svg'
import Alert from 'components/atoms/Alert'
import { getValues } from 'services/storage'
import { selectNavItem, storeCurrentPath } from 'redux/app/app.action'
import { ToTitleCase } from 'components/atoms/CaseManager'
// import Settings from 'pages/dashboard/Settings'

type Props = {
    setModalId?: (id: string) => void,
    setShowModal?: (value: any) => void,
    selectedNavItem: any,
    selectNavItem: (value: any) => void,
    currentPath: any,
    storeCurrentPath: (value: any) => void,
}

const SideNav: React.FC<Props> = ({setModalId, setShowModal, selectedNavItem, selectNavItem, currentPath, storeCurrentPath}) => {
    // const [selected, setSelected] = useState("")
    const [loading, setLoading] = useState(false)
    const username = getValues('username')
    const businessType = getValues('business_type')
    const accountType = getValues('userType')
    
    const navigate = useNavigate()

    const navItems = [
        { text: 'Home', icon1: <HomeIconSolid/>, icon2: <HomeIconOutline/>, style:`${username && username.includes('admin') ? 'hidden' : 'block'} py-4`, available: true, status: "" },

        { text: 'Transactions', icon1: <ReceiptSolid/>, icon2: <ReceiptOutline/>, style:`${username && username.includes('admin') ? 'hidden' : 'block'} py-4`, available: true, status: "" },

        // { text: 'Business', icon1: <BusinessIconSolid/>, icon2: <BusinessIconOutline/>, style:`${username && username.includes('admin') ? 'hidden' : 'block'} py-4`, available: false, status: "" },
        
        { text: 'Sub Pockets', icon1: <WalletIconSolid/>, icon2: <WalletIconOutline/>, style:`${businessType && businessType === 'false' ? 'hidden' : (accountType === 'subPocket') ? 'hidden' : 'block'} py-4`, available: true, status: "" },

        { text: 'Audit trail', icon1: <ActivityIconSolid/>, icon2: <ActivityIconOutline/>, style:`${username && username.includes('admin') ? 'hidden' : (accountType === 'subPocket') ? 'hidden' : 'block'} py-4`, available: false, status: "" },

        { text: '', icon1: <></>, icon2: <></>, style:"border-b border-GrayCustom3", available: null, status: "" },

        { text: 'Settings', icon1: <IoSettings className='w-5 h-5'/>, icon2: <SettingsIconOutline/>, style:`py-4 mt-12`, available: true, status: "" },   
    ]

    
    useEffect(() => {
        if(username && username.includes('admin')){
            selectNavItem('settings')
        }else{
            selectNavItem(navItems[0].text.replace(" ", "_").toLocaleLowerCase() === selectedNavItem && selectedNavItem)
        }
    }, [])

    useEffect(()=>{
        storeCurrentPath(selectedNavItem)
    }, [])

    const handleNavItemClick = (e: { target: { id: string } }, item: { text: string; icon1: JSX.Element; icon2: JSX.Element; style: string; available: boolean; status: string } | { text: string; icon1: JSX.Element; icon2: JSX.Element; style: string; available: null; status: string }) => {
        if(item.available === false) {
            Alert('info', "Sorry, this feature is not available yet.");
            return;
        }else{
            selectNavItem(item.text.replace(" ", "_").toLocaleLowerCase())
            navigate(`/dashboard/${item.text.replace(" ", "_").toLocaleLowerCase()}`)
            
            setModalId && setModalId(e.target.id)
            setShowModal && setShowModal((prevState: any) => !prevState)
        }
    }
 
    return (
        <div className='h-fit sticky top-0 mobile:w-full'>
            <div className='flex flex-col justify-between bg-black relative h-[100vh]'>
                <div className='top-0 flex justify-center p-5'>
                    <SeerbitLogoTyped/>
                </div>
                <div className='w-full flex flex-col gap-2 my-auto absolute top-20'>
                    {navItems.map((item, index) => ( 
                        <SideNavItem
                            key={index}
                            icon1={item.icon1}
                            icon2={item.icon2}
                            text={item.text}
                            style={item.style}
                            active={item.text.toLocaleLowerCase().replace(" ", "_") == selectedNavItem}
                            onClick={e => handleNavItemClick(e, item)}
                        />
                    ))} 
                </div>
                <div className='bottom-0 border-t border-GrayCustom3 p-5 pb-20'>
                    <div className='flex justify-between items-center gap-3 bg-GrayCustom4 rounded-md py-2 px-2 flex-shrink-0 overflow-auto'>
                        <span className='flex justify-center items-center flex-shrink-0 bg-DarkBg3 rounded-full w-8 h-8 border border-Background font-bold text-base leading-4 text-center mx-auto text-SecondaryAccent4'>
                            {username?.slice(0,2).toUpperCase()}
                        </span>
                        <span className='overflow-x-scroll custom_container'>
                            <p className='font-normal text-xs leading-5 text-SecondaryAccent5'>{accountType === 'subPocket' ? 'Sub Pocket Account' : 'Business Account'}</p>
                            <p className='font-normal text-sm leading-5 text-wrap text-Black3'>{username}</p>
                        </span>
                        <span className='text-Black3'>
                            <MdKeyboardArrowDown className='w-5 h-5'/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state:any) => ({
    darkMode: state.app.darkMode,
    currentUser: state.auth.device_auth_data,
    loggedUserData: state.auth.setLoggedUserData,
    selectedNavItem: state.app.selectedNavItem,
    currentPath: state.app.currentPath
})

const mapDispatchToProps = (dispatch:any) => ({
    selectNavItem: (data:any) => dispatch(selectNavItem(data)),
    storeCurrentPath: (data:any) => dispatch(storeCurrentPath(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SideNav)