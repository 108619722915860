import React, { useState } from 'react'
import Button from 'components/atoms/Button'
import show from '../../../assets/svg/show.svg'
import hide from '../../../assets/svg/hide.svg'
import CopyText from 'components/atoms/CopyText'

const ApiKeys = () => {
    const [showItem, setShowItem] = useState(false)
    const [itemId, setItemId] = useState("")

    const handleClick = e => {
        setShowItem(prevState => !prevState)
        setItemId(e.target.id)
    }

    console.log("showItem:", showItem);
    console.log("itemId:", itemId);
    
    
    return (
        <div className='desktop:w-8/12 w-full mobile:w-full tablet:pb-16'>
            <div className='flex flex-col gap-1 mb-12'>
                <span className='text-lg font-normal text-black leading-5'>
                    Your  Business Information
                </span>
                <span className='text-[15px] font-normal leading-5 text-DarkBg6'>   
                    Create and manage API credentials for connecting to the SeerBit backend.
                </span>
            </div>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-col gap-5'>
                    <span className='font-semibold text-sm leading-[14.4px] text-DarkBg6'>
                        Live keys
                    </span>

                    <div className='flex items-center gap-5'>
                        <span className='font-normal text-xs text-DarkBg6 leading-[14.4px] w-[15%]'>Secrete key</span>
                        {showItem && itemId === 'secreteKey_one' 
                            ?   <span className='px-4 py-2 bg-Background3 rounded-md desktop:w-[52.25%] w-[70%] truncate'>Test item</span> 
                            :   <span className='px-4 py-2 bg-Background3 rounded-md desktop:w-[52.25%] w-[70%] truncate'>****************</span>
                        }
                        <span onClick={handleClick} className='cursor-pointer flex-shrink-0'>
                            <img src={showItem && itemId === 'secreteKey_one' ? hide : show } alt="" className='w-[18px] h-[18px]' id="secreteKey_one"/>
                        </span>
                    </div>

                    <div className='flex items-center gap-5'>
                        <span className='font-normal text-xs text-DarkBg6 leading-[14.4px] w-[15%]'>Public key</span>
                        <CopyText 
                            text="Test public key"
                            textStyle="!px-4 !py-3 bg-Background3 rounded-md !w-full text-opacity-70 text-sm mr-2 inline-block item-center text-Accent_blue leading-[14.4px]"  
                            mssgStyle='-right-20 ' 
                            imgStyle="w-5 h-5"
                            style="desktop:!w-[56.5%] !w-[77.5%] mobile:!w-full"
                        />
                    </div>
                    <div className='flex justify-end items-center'>
                        <Button 
                            btnType='button'
                            btnText='Reset keys'
                            btnStyle='bg-black w-fit text-white font-semibold text-sm text-GrayCustom8 px-4 py-3'
                        />
                    </div>
                </div>
                
                <div className='flex flex-col gap-5'>
                    <span className='font-semibold text-sm leading-[14.4px] text-DarkBg6'>
                        Test keys
                    </span>

                    <div className='flex items-center gap-5'>
                        <span className='font-normal text-xs text-DarkBg6 leading-[14.4px] w-[15%]'>Secrete key</span>
                        {showItem && itemId === 'secreteKey_two' 
                            ?   <span className='px-4 py-2 bg-Background3 rounded-md desktop:w-[52.25%] w-[70%] truncate'>Test item</span> 
                            :   <span className='px-4 py-2 bg-Background3 rounded-md desktop:w-[52.25%] w-[70%] truncate'>****************</span>
                        }
                        <span onClick={handleClick} className='cursor-pointer flex-shrink-0'>
                            <img src={showItem && itemId === 'secreteKey_two' ? hide : show } alt="" className='w-[18px] h-[18px]' id="secreteKey_two"/>
                        </span>
                    </div>

                    <div className='flex items-center gap-5'>
                        <span className='font-normal text-xs text-DarkBg6 leading-[14.4px] w-[15%]'>Public key</span>
                        <CopyText 
                            text="Test public key"
                            textStyle="!px-4 !py-3 bg-Background3 rounded-md !w-full text-opacity-70 text-sm mr-2 inline-block item-center text-Accent_blue leading-[14.4px]"  
                            mssgStyle='-right-20 ' 
                            imgStyle="w-5 h-5"
                            style="desktop:!w-[56.5%] !w-[77.5%] mobile:!w-full"
                        />
                    </div>
                    <div className='flex justify-end items-center'>
                        <Button 
                            btnType='button'
                            btnText='Reset keys'
                            btnStyle='bg-black w-fit text-white font-semibold text-sm text-GrayCustom8 px-4 py-3'
                        />
                    </div>
                </div>

                <div className='flex flex-col gap-5'>
                    <span className='font-semibold text-sm leading-[14.4px] text-DarkBg6'>
                        Live keys
                    </span>

                    <div className='flex items-center gap-5'>
                        <span className='font-normal text-xs text-DarkBg6 leading-[14.4px] w-[15%]'>Public key</span>
                        <CopyText 
                            text="Test public key"
                            textStyle="!px-4 !py-3 bg-Background3 rounded-md !w-full text-opacity-70 text-sm mr-2 inline-block item-center text-Accent_blue leading-[14.4px]"  
                            mssgStyle='-right-20 ' 
                            imgStyle="w-5 h-5"
                            style="desktop:!w-[56.5%] !w-[77.5%] mobile:!w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApiKeys