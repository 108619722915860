import React, { useState } from 'react'
import PageTitle from 'components/atoms/PageTitle'
import FormInput from 'components/atoms/FormInput'
import searchIcon from '../../assets/svg/search-normal.svg'
import getInTouchIcon from '../../assets/svg/Notifications/message-2.svg'
import setBusinessIcon from '../../assets/svg/Notifications/setting.svg'
import apiDocIcon from '../../assets/svg/Notifications/code.svg'
import dashboardIcon from '../../assets/svg/Notifications/category.svg'
import profileIcon from '../../assets/svg/Notifications/profile-circle.svg'
import arrowRight from '../../assets/svg/arrow_right-2.svg'
import arrowDown from '../../assets/svg/arrow-down.svg'
import closeIcon from '../../assets/svg/close-circle.svg'

const Support = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [showAns, setShowAns] = useState(false)
    const [queId, setQueId] = useState('')

    const supportItemArr = [
        {icon: getInTouchIcon, title:'Get in touch', desc:'Get answers to your questions and  access help from our team.', hasMore: true},
        {icon: setBusinessIcon, title:'Set up your business', desc:'Learn how you can integrate SeerBit pocket with your business.', hasMore: true},
        {icon: apiDocIcon, title:'API Documentation', desc:'Understand how to integrate SeerBit’s Pocket API into your product development process.', hasMore: true},
        {icon: dashboardIcon, title:'Explore your dashboard', desc:'Quickly scan the features of your dashboard in less than a minute.', hasMore: true},
        {icon: profileIcon, title:'Your account', desc:'Learn how to debug issues related to your account.', hasMore: false}
    ]

    const commonQuestionsArr = [
        {id:'one', que:'What is a sub pocket account?', ans:'Consectetur adipisicing aliqua fugiat velit ad aliquip commodo ipsum ut voluptate. Tempor incididunt in sit incididunt dolor laboris fugiat nulla dolore nostrud esse quis cillum. Mollit non irure ad ex nisi cupidatat id. Occaecat officia Lorem aliquip adipisicing labore. Eu nisi irure anim reprehenderit deserunt dolor consectetur. Nostrud qui Lorem est culpa veniam irure ea esse veniam voluptate.'},
        {id:'two', que:'Can I have more than one pocket account for my business?', ans:'Duis est quis minim cillum velit qui ea ex proident eiusmod reprehenderit cupidatat qui culpa. Sunt quis proident esse reprehenderit irure nulla ipsum. Irure ex voluptate veniam fugiat aliqua velit anim quis officia est.'},
        {id:'three', que:'How do I configure the fees on my pocket dashboard?', ans:'Pariatur reprehenderit quis commodo anim eiusmod id ex. Deserunt irure anim est nulla. Irure non nulla do quis magna pariatur eiusmod consectetur eu consequat excepteur consectetur excepteur.'}
    ]

    const openFaq = e => {
        if(showAns){
            setShowAns(false)
        }
        setShowAns(true)
        setQueId(e.target.id)
    }

    const closeFaq = () => {
        setShowAns(false)
    }

    const handleFaq = e => {
        setShowAns(prevState => !prevState)
        setQueId(e.target.id)
    }

    return (
        <div className='bg-Black6 rounded-lg py-5 px-4 flex flex-col gap-7'>
            <div className='flex flex-col gap-3'>
                <span className='font-semibold text-xl'>
                    Support
                </span>
                <FormInput
                    type="search"
                    name="searchItem"
                    placeholder="Find help and resources"
                    style=""
                    inputStyle="bg-Black7 text-Black3 font-normal text-sm"
                    inputStyle2={`text-xs leading-5 text-GrayCustom7 pl-8`}
                    value=""
                    img={searchIcon}
                    imgStyle="w-4 h-4 !relative !left-5"
                    validationErr=""
                    onClick={()=>{}}
                    onChange={()=>{}}
                />
            </div>
            <div className='flex flex-col gap-3'>
                {supportItemArr.map((item, i) => (
                    <div key={i} className='border border-Black9 p-5 rounded-lg flex w-full bg-Black4 hover:bg-Black10 transition-all flex-shrink-0 cursor-pointer'>
                        <div className='flex mr-5'>
                            <span className='w-10 h-10 rounded-md bg-Black8 border border-Black9 flex justify-center items-center'>
                                <img src={item.icon} alt="support-icon" className='w-6 h-6'/>
                            </span>
                        </div>
                        <div className='flex flex-col gap-1 w-full'>
                            <span className='max-w-[297px] font-normal text-base leading-[19.2px]'>{item.title}</span>
                            <span className='max-w-[297px] text-Black3 font-normal text-xs leading-[16.8px]'>{item.desc}</span>
                        </div>
                        {item.hasMore && 
                            <div className='flex justify-center items-center'>
                                <img src={arrowRight} alt='arrowRight' className='w-2 h-auto'/>
                            </div>
                        }
                    </div>
                ))}
            </div>
            <div className='flex flex-col gap-3'>
                <span className='font-semibold text-base leading-[19.2px]'>
                    Common questions
                </span>
                <div className='flex flex-col gap-2'>
                {commonQuestionsArr.map((item, i) => (
                    <div className='' key={i}>
                        <div className='flex justify-between items-center border-b border-Black2 p-3 cursor-pointer' onClick={openFaq}>
                            <span id={item.id} className='font-normal text-sm text-SecondaryAccent2'>
                                {item.que}
                            </span>
                            <span className='w-8 h-5 flex justify-center items-center' onClick={handleFaq}>
                                <img src={showAns && queId === item.id ? arrowDown : arrowRight} alt="arrowRight" className={`${showAns && queId === item.id ? 'w-3' : 'w-[5px]'} h-auto`}/>
                            </span>
                        </div>
                        {showAns && queId === item.id &&
                            <div className='p-2 relative'>
                                <div className='absolute right-4 top-4' onClick={closeFaq}>
                                    <img src={closeIcon} alt="arrowRight" id={item.id} className='w-4 h-4'/>
                                </div>
                               <p className='text-sm text-SecondaryAccent2 font-normal max-w-[90%] text-justify'>{item.ans}</p>
                            </div>
                        }
                    </div>
                ))}
                </div>
            </div>
        </div>
    )
}

export default Support