import React from 'react'
import Button from './Button'

type Props = {
    pageTitle: string,
    pageTitleStyle?: string 
}

const PageTitle: React.FC<Props> = ({pageTitle, pageTitleStyle}) => {
  return (
    <div className='flex justify-between my-2'>
        <span className={`${pageTitleStyle} desktop:text-3xl desktop:leading-7 text-2xl mobile:text-xl flex-wrap tablet:leading-5 font-semibold text-black truncate`}>
            {pageTitle}
        </span>
    </div>
  )
}

export default PageTitle