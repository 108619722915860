import React from 'react'
import './layout.css'
import checkIcon from '../../../assets/svg/checkIcon.svg'

type Props = {
    type?: "radio" | "checkbox" | undefined 
    name?: string,
    checked?: boolean,
    containerStyle?: string,
    checkStyle?: string,
    inputData: any[],
    label?: any,
    labelStyle?: string
    handleSelect?: (data?:any) => void
}

const RadioAndCheckInput: React.FC<Props> = ({type, name, checked, containerStyle, checkStyle, inputData, label, labelStyle, handleSelect}) => {
    return (
        <div className={`flex flex-col justify-between`}>
            {label && <label className={`${labelStyle}`}>{label}</label>}
            <div className={`flex ${containerStyle}`}>
            {inputData.length > 0 &&
                inputData.map((item, i) => (
                    <div key={i} className='flex items-center gap-2 py-2 cursor-pointer'>
                        <label className='custom_radio_btn'>
                            <input type={type} name={name} checked={checked} className="hidden" value={item.value} onChange={handleSelect && (() => handleSelect(item.value))}/>
                            <span className={`${checkStyle} checkmark items-center`}>
                                <div className='w-full h-full flex justify-center items-center'>
                                    <img src={checkIcon} alt="checkIcon" className="w-calc h-calc"/>
                                </div>
                            </span>
                        </label>
                        <span className='text-xs leading-5 text-DarkBg6'>{item.label}</span>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default RadioAndCheckInput

{/* <label className='w-[30px] h-[30px] border-2 border-DarkBg rounded-full flex justify-center items-center'> */}
{/* <span className={`${checkStyle} w-calc h-calc rounded-full bg-DarkBg inline-block opacity-0 transition-all checked:`}></span> */}
