import React, { useState } from 'react'
import arrow_down from '../../assets/svg/arrow-down.svg'
import arrow_up from '../../assets/svg/arrow-up.svg'

type Props = {
    dropdownTitle: string,
    data:  any[],
    style?: string,
    handleSelect?: (searchLabel: string) => void
}

const FilterComp: React.FC<Props> = ({dropdownTitle, data, style, handleSelect}) => {
    const [toggleDropdown, setToggleDropdwon] = useState(false) 

    const handleChange = () => {
        setToggleDropdwon(prevState => !prevState)
    }

    const actionFunction = (item, handleSelect) => {
        // const defaultValue = item[0]
        // handleSelect(!item ? defaultValue : item)
        handleSelect(item)
        setToggleDropdwon(prevState => !prevState)
    }

    return (
        <div className={`${style} border relative flex items-center gap-3 border-SecondaryAccent2 border-dashed rounded-lg tablet:py-2`}>
            <div className='flex gap-5 tablet:justify-between items-center'>
                <span className=''>
                    {dropdownTitle}
                </span>
                <span className=''>
                    <img src={toggleDropdown ? arrow_up : arrow_down} alt="arrow-down" className='transition-all' onClick={handleChange}/>
                </span>
            </div>
            {toggleDropdown
                ?   <div className={`flex flex-col flex-grow rounded border shadow-md absolute z-20 left-0 top-10 bg-white max-h-[300px] tablet:max-h-[180px] overflow-scroll animate-slide_down`}>
                    {data.length > 0 &&
                        data.map((item, index) => (
                            <span key={index} className='hover:bg-SecondaryAccent3 py-2 px-5 hover:cursor-pointer text-sm truncate mobile:no-truncate' onClick={() => actionFunction(item, handleSelect)}>
                                {item.timePeriod 
                                    ?   item.timePeriod
                                    :   item
                                }
                            </span>
                        ))
                    }
                    </div>
                
                :   ""
            }
        </div>
    )
}

export default FilterComp