export const TrailHeader =  ["User", "Transfer Reference", "Activity Type", "Active", "Date"]

export const TrailData = [
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},
    {user: "Ready Empire", transferRef: "6315689", activityType: "Transfer", activity: "Invited omotola.sambO@seerbit.com as a Pocket Admin", date:"08-05-2023"},

]