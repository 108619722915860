import FormInput from 'components/atoms/FormInput'
import Toggle from 'components/atoms/Toggle'
import React from 'react'
import { connect } from 'react-redux'
import { toggleAppEnv } from 'redux/app/app.action'

type Props = {
    title?: string,
    inputTitleOne?: string,
    inputTitleTwo?: string,
    appEnv: boolean,
    toggleAppEnv: () => void
}

const DetailsCard: React.FC<Props> = ({toggleAppEnv, appEnv, title, inputTitleOne, inputTitleTwo}) => {
    return (
        <div className='flex flex-col gap-5 bg-Background3 rounded-md p-5'>
            {title &&
                <span className='flex justify-between items-center'>
                    <p className='text-xs leading-5 text-DarkBg6'>{title}</p>
                    <Toggle 
                        handleChange={toggleAppEnv} 
                        checked={appEnv} 
                        style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
                    />
                </span>
            }
            <span className='w-full flex flex-col justify-between'>
                <p className='font-normal text-xs leading-3 text-Black2 mb-3'>{inputTitleOne}</p>
                <FormInput 
                    type="number"  
                    name="service_charge" 
                    placeholder="0.0" 
                    inputStyle="text-Black2 bg-white"
                    onChange={()=>{}}
                    min={0}
                    // value={email}
                    // validationErr={emailError}
                />
            </span>
            <span className='w-full flex flex-col justify-between'>
                <p className='font-normal text-xs leading-3 text-Black2 mb-3'>{inputTitleTwo}</p>
                <FormInput 
                    type="number"  
                    name="service_charge" 
                    placeholder="0.0" 
                    inputStyle="text-Black2 bg-white"
                    onChange={()=>{}}
                    min={0}
                    // value={email}
                    // validationErr={emailError}
                />
            </span>
        </div>
    )
    }

const mapStateToProps = (state: any) => ({
    appEnv: state.app.appEnv
})
    
const mapDispatchToProps = (dispatch: any) => ({
    toggleAppEnv: () => dispatch(toggleAppEnv())
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailsCard)