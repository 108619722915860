import React, { useState } from 'react'
import Button from 'components/atoms/Button'
import FormInput from 'components/atoms/FormInput'
import RadioAndCheckInput from 'components/atoms/RadioAndCheckInputGroup/RadioAndCheckInput'
import Toggle from 'components/atoms/Toggle'
import { connect } from 'react-redux'
import { toggleAppEnv } from 'redux/app/app.action'
import { getValues } from 'services/storage'
import profileEdit from '../../../assets/svg/user-edit.svg'
// import { store } from '../../../redux/store'

// const state = store.getState()
// console.log("State:", state.pocket)

const checkboxData = [
    {label: "Are you a developer"}
]

const UserProfile = ({appEnv, toggleAppEnv, user_data, primaryPocketData}) => {
    const[firstname, setFirstname] = useState('')
    const[lastname, setLastname] = useState('')
    const[email, setEmail] = useState('')
    const[phone, setPhone] = useState('')

    console.log("primaryPocketData?.pocketOwner:", primaryPocketData?.pocketOwner)

    const username = primaryPocketData?.pocketOwner?.username
    const default_firstname = primaryPocketData?.pocketOwner?.firstName
    const default_lastname = primaryPocketData?.pocketOwner?.lastName
    const default_email = primaryPocketData?.pocketOwner?.emailAddress
    const default_phoneNumber = primaryPocketData?.pocketOwner?.phoneNumber

    const handleReset = () => {
        setFirstname("")
        setLastname("")
        setEmail("")
        setPhone("")
    }

    return (
        <>
            <div className='flex flex-col gap-1 mb-12'>
                <span className='text-lg font-normal text-black leading-5'>
                    Your Personal Information
                </span>
                <span className='text-[15px] font-normal leading-5 text-DarkBg6'>
                    Update your personal and contact information.
                </span>
            </div>
            <div className='flex flex-col gap-5 w-full desktop:w-[715px]'>
                <div className='flex flex-col gap-5 w-full'>
                    <span className='bg-Black4 w-[96px] h-[96px] rounded-full flex justify-center items-center'> 
                        <img src={profileEdit} alt="profile_icon" className=''/>
                    </span>
                    <form className='flex flex-col gap-5'>
                        <div className='flex mobile:flex-col gap-5 justify-between items-center'>
                            <FormInput 
                                type="text"  
                                name="firstname" 
                                placeholder="Firstname" 
                                inputStyle="border border-transparent text-Black2"
                                label="First name" 
                                labelStyle="font-normal text-base leading-6 text-DarkBg6" 
                                style='w-full'
                                onChange={(e)=>setFirstname(e.target.value)}
                                value={firstname === "" ? default_firstname : firstname}
                                validationErr=""
                            />
                            <FormInput 
                                type="text"  
                                name="lastname" 
                                placeholder="Lastname" 
                                inputStyle="border border-transparent text-Black2"
                                label="Last name" 
                                labelStyle="font-normal text-base leading-6 text-DarkBg6" 
                                style='w-full'
                                onChange={(e)=>setLastname(e.target.value)}
                                value={lastname === "" ? default_lastname : lastname}
                                validationErr=""
                            />
                        </div>
                        <div className='flex mobile:flex-col gap-5 justify-between items-center'>
                            <FormInput 
                                type="email"  
                                name="email" 
                                placeholder="example@gmail.com" 
                                inputStyle="border border-transparent text-Black2"
                                label="Email address" 
                                labelStyle="font-normal text-base leading-6 text-DarkBg6" 
                                style='w-full'
                                onChange={(e)=>setEmail(e.target.value)}
                                value={email === "" ? default_email : email}
                                validationErr=""
                            />
                            <FormInput 
                                type="text"  
                                name="phone" 
                                placeholder="+234" 
                                inputStyle="border border-transparent text-Black2"
                                label="Phone number" 
                                labelStyle="font-normal text-base leading-6 text-DarkBg6" 
                                style='w-full'
                                onChange={(e)=>setPhone(e.target.value)}
                                value={phone === "" ?  default_phoneNumber :  phone}
                                validationErr=""
                            />
                        </div>
                        <div className='flex flex-col gap-8'>
                            <span className='w-full flex flex-col justify-between'>
                                <RadioAndCheckInput type="checkbox" name="currency" inputData={checkboxData} checkStyle=""/>
                            </span>
                        </div>
                        {/* <div className='flex justify-end items-center gap-5'>
                            <Button 
                                btnType="button"
                                btnText="Cancel"
                                btnStyle='bg-SecondaryAccent3 text-black font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
                            />
                            <Button 
                                btnType="button"
                                btnText="Save changes"
                                btnStyle='bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
                            />
                        </div> */}
                    </form>
                </div>
                <div className=''>
                    <div className='border-b pb-2'>
                        <span className=''>
                            Profile security 
                        </span>
                    </div>
                    <div className=''>
                        <div className='flex items-center justify-start gap-16 mobile:gap-5 py-5'>
                            <span>
                                <p className='font-normal text-[15px] leading-5 text-black'>Change your password</p>
                                <p className='font-normal text-xs leading-6 text-Black4'>You can request a password reset here easily</p>
                            </span>
                            <Button 
                                btnType="button"
                                btnText="Request password reset"
                                btnStyle='bg-black text-SecondaryAccent5 font-semibold text-sm mobile:text-xs leading-7 px-4 py-2 !rounded-lg mobile:w-full truncate'
                            />
                        </div>
                        <div className='flex items-center justify-start gap-16 mobile:gap-5 py-5'>
                            <span>
                                <p className='font-normal text-[15px] leading-5 text-black'>Two factor authentication</p>
                                <p className='font-normal text-xs leading-6 text-Black4'>You can request a password reset here easily</p>
                            </span>
                            <Toggle 
                                handleChange={toggleAppEnv} 
                                checked={appEnv} 
                                style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
                            />  
                        </div>
                        <div className='flex justify-end items-center gap-5'>
                            <Button 
                                btnType="button"
                                btnText="Cancel"
                                btnStyle='bg-SecondaryAccent3 text-black font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
                                onClick={handleReset}
                            />
                            <Button 
                                btnType="button"
                                btnText="Save changes"
                                btnStyle='bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    appEnv: state.app.appEnv,
    user_data: state.auth.user_data,
    primaryPocketData: state.pocket.primaryPocket_details

})

const mapDispatchToProps = dispatch => ({
    toggleAppEnv: () => dispatch(toggleAppEnv())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)