import React from 'react'
import FormInput from 'components/atoms/FormInput'
import Toggle from 'components/atoms/Toggle'
import { title } from 'process'
import { toggleAppEnv, toggleModal } from 'redux/app/app.action'
import { connect } from 'react-redux'
import Select from 'components/atoms/SelectInput'

type Props = {
    appEnv: boolean, 
    toggleAppEnv: () => void, 
    min?: any, 
    max?: any
}

const ConfigCard: React.FC<Props> = ({appEnv, toggleAppEnv, min, max}) => {
    const optData = [
        {value: "one", label:'Select Option One'},
        {value: "two", label:'Select Option Two'},
        {value: "three", label:'Select Option Three'},
        {value: "four", label:'Select Option Four'},
        {value: "five", label:'Select Option Five'}
    ]

    return (
        <div className='flex flex-col gap-3 bg-Background3 rounded-md py-5 px-2'>
            <div className='flex justify-between items-center'>
                <span className='font-normal text-xs leading-5'>Payout service status</span>
                <Toggle 
                    handleChange={toggleAppEnv} 
                    checked={appEnv} 
                    style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
                />
            </div>
            <Select
                label="Service charge type"
                labelStyle="font-normal text-xs leading-5 text-DarkBg6"
                data={optData}
                defaultOpt="Select service charge type"
                style="bg-white"
                value=''
                onChange={()=>{}}
            />
            <FormInput
                label="Payout service charge"
                labelStyle='font-normal text-xs leading-5 text-DarkBg6'
                type="number"
                name="payout_service_charge"
                placeholder='0.0'
                inputStyle="bg-white"
                inputContainerStyle=""
                min={0}
                onChange={()=>{}}
            />
            {min || max 
                ?   <>
                        <div className='flex justify-between items-center'>
                            <span className='font-normal text-xs leading-5'>Limit amount</span>
                            <Toggle
                                handleChange={toggleAppEnv}
                                checked={appEnv}
                                style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6" />
                        </div>
                        <div className='flex justify-between items-center gap-3'>
                            <span className='w-1/2'>
                                <FormInput
                                    label="Min amount"
                                    labelStyle='font-normal text-xs leading-5 text-DarkBg6'
                                    type="number"
                                    name="min_amount"
                                    placeholder='0.0'
                                    inputStyle="bg-white"
                                    inputContainerStyle=""
                                    min={0}
                                    onChange={() => { } } />
                            </span>
                            <span className='w-1/2'>
                                <FormInput
                                    label="Max amount"
                                    labelStyle='font-normal text-xs leading-5 text-DarkBg6'
                                    type="number"
                                    name="max_amount"
                                    placeholder='0.0'
                                    inputStyle="bg-white"
                                    inputContainerStyle=""
                                    min={0}
                                    onChange={() => { } } />
                            </span>
                        </div>
                    </>
                :   ""
            }
        </div>
    )
}

const mapStateToProps = state => ({
    modalState: state.app.modalState,
    appEnv: state.app.appEnv
  })
  
  const mapDispatchToProps = dispatch => ({
    toggleModal: () => dispatch(toggleModal()),
    toggleAppEnv: () =>  dispatch(toggleAppEnv())
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ConfigCard)