import React, { useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import Alert from 'components/atoms/Alert';
import { setAuthRes, setUserLoggedIn, logoutHandler } from 'redux/auth/auth.action';
import { removeValues, setValues } from 'services/storage';
import { BASE_URL, getRequest, postRequest } from '../services/http';
import { getValues } from '../services/storage';
import { store } from '../redux/store'

const state = store.getState()
const bearerToken = state.auth.token

export const appMode = () => {
  return 'development';
}

export function toMoney(value: string | any) {
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN',
  });
  
  return `₦${formatter.format(value).replace("NGN", "").trim()}`;
}

export const handleException = (error: any) => {
  try {
    if (error.response) {
      if(error.response.data.message === "Invalid token"){
        Alert('info', "Token expired, reinitiate log in.");
        logoutFunc(bearerToken)
      }else{
        Alert('error', error.response.data.message);
      }
    } else {
      Alert('error', error.message);
    }
  } catch (e) {
    Alert('error', 'We are unable to process your request at the moment.');
  }
};

export const commaSeparateNumber = (val: string) => {
  if (val.length > 3) {
      return (Number(val.replace(/\D/g, '')) || '').toLocaleString();
  }
  return val;
}

// export const TokenCheck = (setAuthRes, setUserLoggedIn) => {
//   const [tokenStatus, setTokenStatus] = useState(null)
//   const url = `is-token-valid?token=${getValues('token')}`

//   getRequest(url)
//   .then(res => {
//       console.log("token checker res:", res.data.data)
//       setTokenStatus(res.data.data)
//   })
//   .catch(error => {
//       handleException(error)
//   })
  
//   const output:any = tokenStatus ? true : logoutFunc(setAuthRes, setUserLoggedIn)

//   return {output}
// }

export const logoutFunc = async (bearerToken) => {
  try{
      const res = await axios.post(`${BASE_URL}logout?token=${bearerToken}`, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${bearerToken}`
          }
      })
      if(res.data.code === 'error'){
          Alert('error',"Something went wrong") 
      }else{
          logoutHandler()
          localStorage.clear()
          sessionStorage.clear()
          window.location.href = "/auth/login"
      }
      return res;
  }catch(error){
      handleException(error)
  }
}