import React, { useRef, useState } from 'react'
import Button from 'components/atoms/Button'
import PageTitle from 'components/atoms/PageTitle'
import RadioAndCheckInput from 'components/atoms/RadioAndCheckInputGroup/RadioAndCheckInput'
import { SubPocketData } from './SubPocketData'
import FormInput from 'components/atoms/FormInput'
import addIcon from '../../../../assets/svg/blue_add.svg'
import addIcon_grey from '../../../../assets/svg/add_grey.svg'
import closeIcon from '../../../../assets/svg/close-circle.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { createSubPocket } from '../../../../redux/Pockets/pocket.action'
import { connect } from 'react-redux'
import Alert from 'components/atoms/Alert'
import { handleException } from 'utils'
import axios from 'services/axios'
import { BASE_URL } from 'services/http'

const FinishSetup = ({subPocketData, createSubPocket, publicKey, bearerToken, primaryPocket_details}) => {
    const location = useLocation()
    const navigate = useNavigate()
    
    const [loading, setLoading] = useState(false)
    const [radioSelect, setRadioSelect] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    // const [additionalInfo, setAdditionalInfo] = useState('')
    const [elementList, setElementList] = useState<any[]>([])

    const additionalInfo = useRef('')
 
    const {existingPocketData} = SubPocketData()

    const absolutePath = '/dashboard/sub_pockets/create_sub_pocket/'

    const currentPath = location.pathname.split('/').slice(-1).toString()

    const createSubpocket = async (url: string, subPocketData: any) => {
        try{
            const res = await axios.post(`${BASE_URL+url}`, subPocketData, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })

            if(res.data.code === 'error'){
                setLoading(false)
                Alert("error", res.data.message);
            }else{
                setLoading(false)
                console.log("CreateSubpocketData_Response:", res);
            }

            return res;
        }catch(error){
            setLoading(false)
            handleException(error)
        }
    }

    const handleSubmit = (e?: any) => {
        e.preventDefault()

        if(radioSelect === "" || businessName === "" || firstname === "" || lastname === "" || email === "" || phone === ""){
            Alert('error', 'Fill all fields')
        }else{
            createSubPocket({...subPocketData, ...{
                existingPocket: radioSelect,
                businessName: businessName,
                firstName: firstname,
                lastName: lastname,
                email: email,
                phoneNumber: phone,
                addedInfo: additionalInfo.current !== "" ? additionalInfo.current : ""
            }})

            console.log("CompiledData:", subPocketData)

            Alert('success','Submitted Successfully')

            const response = createSubpocket(`pocket-id/${primaryPocket_details.pocketId}/sub-pocket`, subPocketData)          
            // // navigate(`${absolutePath}finish_setup`)
            // console.log("subPocketData:", subPocketData)
        }
    }    


// accountNumber: "0010010019"
// accountVerifierNumber: "00000000000"
// accountVerifierScheme: "BVN"
// availableBalanceAmount: "0.00"
// availableBalanceCurrency: "NGN"
// fundingLink: "https://pay.seerbitapi.com?ps=iOnXCOn9&po=100000009"
// ledgerBalanceAmount: "0.00"
// ledgerBalanceCurrency: "NGN"
// parentId: null
// pocketAccounts: [{…}]
// pocketFunction: "BOTH"
// pocketFundings: null
// pocketId: "100000009"
// pocketLimits: []
// pocketMomos: []
// pocketOwner: {
    // businessName: "DLOCAL NIGERIA"
    // emailAddress: "centricgateway_ng@dlocalmail.com"
    // firstName: "DLOCAL NIGERIA"
    // lastName: "DLOCAL NIGERIA"
    // ownerVerifierNumber: "00000000000"
    // ownerVerifierScheme: "NIN"
    // phoneNumber: "2348101027606"
    // pocketOwnerId: "iOnXCOn9"
// reference: "primary-100000009"
// status: "ACTIVE"
// subPockets: null
// tier: "UNLIMITED"



//   {
//     "currency": "NGN",
//     "selfOwned": false,
//     "pocketFunction": "BOTH",
//     "reference": "pckl8890",
//     "accountVerifierScheme": "BVN",
//     "accountVerifierNumber": "22291829755",
//     "pocketOwner": {
//         "existingPocketOwner": "false",
//         "pocketOwnerId": "z9r2auf6",
//         "pocketOwnerDetails" : {
//             "businessName": "J.D. Inc.",
//             "firstName": "John",
//             "lastName": "Doe",
//             "emailAddress": "user2392@seerbit.com",
//             "phoneNumber": "08094483374",
//             "canRetailPockets": false,
//             "merchantDetails" : {
//                 "publicKey" : "SBPUBK_TREVCZ55LVXVXZWVLK0OSBLWOWY57Q33"
//             }
//         }
//     },
//     "tagGroup":"LIVEP-RETAILS",
//     "tagName":"LIVEP-FIELD"
//   }
// ]

    const handleEvent = () => {
        navigate(`${absolutePath}account_verification`)
    }

    const handleRemoveElement = () => setElementList([])

    const addedInfo = (e?: any) => {     
        elementList.length < 1
        ?   setElementList(elementList?.concat(
                <FormInput 
                    type="text"  
                    name="addedInfo" 
                    placeholder="Enter any additional Information here"
                    style="mb-2 w-full"
                    inputStyle="border border-transparent text-Black2"
                    label="Additional Information" 
                    labelStyle="font-semibold text-base leading-6 text-Black2" 
                    value={additionalInfo.current}
                    onChange={e => additionalInfo.current =  e.target.value}
                    validationErr=''

                    img={closeIcon}
                    imgOnClick={handleRemoveElement}
                    imgStyle="w-4 h-4 cursor-pointer"
                />
            ))
        
        :   Alert('info','You can only add one additional info.')
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className='w-[342px] mobile:w-full h-[66vh] overflow-scroll custom_container'>
                <div className='flex gap-10 items-center'>
                    <PageTitle 
                        pageTitle="Finish Setup" 
                        pageTitleStyle="!font-semibold !text-xl !my-0"
                    />
                </div>
                <div className='flex flex-col gap-1 mt-5 mb-5 mobile:mb-16'>
                    <RadioAndCheckInput 
                        type="radio" 
                        name="selfOwned" 
                        inputData={existingPocketData} 
                        containerStyle="gap-16 flex-row"
                        label='Do you have an existing pocket account?'
                        labelStyle='font-semibold text-base leading-6 text-Black2'
                        handleSelect={setRadioSelect}
                    />

                    <FormInput 
                        type="text"  
                        name="businessName" 
                        placeholder="Business Name" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="What is your business name" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        value={businessName}
                        onChange={e => setBusinessName(e.target.value)}
                        validationErr=''
                    />

                    <FormInput 
                        type="text"  
                        name="firstname" 
                        placeholder="Firstname" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="Enter Firstname" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        value={firstname}
                        onChange={e => setFirstname(e.target.value)}
                        validationErr=''
                    />

                    <FormInput 
                        type="text"  
                        name="lastname" 
                        placeholder="Lastname" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="Enter Lastname" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        value={lastname}
                        onChange={e => setLastname(e.target.value)}
                        validationErr=''
                    />

                    <FormInput 
                        type="text"  
                        name="email" 
                        placeholder="Email" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="Enter Email" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        validationErr=''
                    />

                    <FormInput 
                        type="text"  
                        name="phoneNumber" 
                        placeholder="Mobile" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="Phone number" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                        validationErr=''
                    />

                    {elementList}

                    {/* {elementList.map((item, i) => (
                        <div className='border py-2 px-3 rounded-md flex justify-between items-center'>
                            {item}
                            {i}
                        </div>
                    ))} */}

                    <div className='w-full flex justify-end items-center gap-2 cursor-pointer' onClick={addedInfo}>
                        <img src={elementList.length > 0 ? addIcon_grey : addIcon} alt="addIcon" className='w-4 h-4'/>
                        <p className={`${elementList.length > 0 ? 'text-BackDrop_d_lg' : 'text-Accent_blue'} font-normal text-sm`}>Additional information</p>
                    </div>
                </div>
            </div>

            <div className='flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]'>
                <Button 
                    btnType="button"
                    btnText={`${currentPath === 'basic_info' ? 'Cancel' : 'Previous'}`}
                    btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={handleEvent}
                />
                <Button 
                    btnType="submit" 
                    btnText={`${currentPath === 'finish_setup' ? 'Finish' : 'Next'}`}
                    btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={()=>{}}
                />
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    subPocketData: state.pocket.subPocketData,
    bearerToken: state.auth.token,
    publicKey: state.auth.public_key,
    primaryPocket_details: state.pocket.primaryPocket_details
})

const mapDispatchToProps = dispatch => ({
    createSubPocket: (data) => dispatch(createSubPocket(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishSetup)