import React, { useEffect, useRef, useState } from 'react'
import AppModal from '../../../components/organisms/CustomModal'
import Button from 'components/atoms/Button'
import editIcon from '../../../assets/svg/edit-white.svg'
import ColorTag from 'components/atoms/ColorTag'
import { toggleModal, toggleAppEnv } from 'redux/app/app.action'
import { connect } from 'react-redux'
import Toggle from 'components/atoms/Toggle'
import ArrowDownIcon from '../../../assets/svg/arrow-down.svg'
import ArrowUpIcon from '../../../assets/svg/arrow-up.svg'
import ConfigCard from 'components/molecules/ConfigCard'
import Prompts from 'components/atoms/Prompts'
import PageTitle from 'components/atoms/PageTitle'
import PageTitleHeader from 'components/molecules/PageTitleHeader'
import axios from 'services/axios'
import { BASE_URL } from 'services/http'
import { handleException } from 'utils'
import Alert from 'components/atoms/Alert'

type Props = {
  modalState: boolean,
  appEnv: boolean,
  publicKey: string, 
  bearerToken: string,
  toggleModal: () => void,
  toggleAppEnv: () => void
}

const DefaultConfig: React.FC<Props> = ({publicKey, bearerToken, modalState, appEnv, toggleModal, toggleAppEnv}) => {
  const [loading, setLoading] = useState(false)
  const [showCard, setShowCard] = useState(false)
  const [cardId, setCardId] = useState("")

  const fetchConfigData = async (url: string) => {
    setLoading(true)
    try{
      const res = await axios.get(`${BASE_URL+ url}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`
        }
      })
  
      if(res.data.code === 'error'){
        setLoading(false)
        Alert("error", res.data.message);
      }else{
        setLoading(false)
        console.log("ConfigData_Response:", res.data.data.content);
      }
  
      return res
    }catch(error){
      setLoading(false)
      handleException(error)
    }
  }

  useEffect(() => {
    fetchConfigData('fee-configs')
  }, [])

  const toggleCard = (e) => {
    setCardId(e.target.id)
    setShowCard(!showCard)
  }

  const modalContent = (
    <AppModal handleClose={toggleModal} modalStyle="desktop:w-1/4 mobile:w-3/4 bg-white min-h-screen z-30 top-0 right-0 animate-slide_left p-8" contentStyle="overflow-y-scroll h-[92vh] flex flex-col justify-between py-8">
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col'>
          <span className='font-normal text-2xl leading-7 text-black mb-3'>Configure  pocket service</span>
          <span className='font-normal text-[15px] leading-7 text-SecondaryAccent'>View the details of the payout service</span>
        </div>
        <div className='flex justify-between items-center border-b pb-4'>
          <span className='font-normal text-[15px] leading-[18px] text-black'>Service status</span>
          <Toggle 
              handleChange={toggleAppEnv} 
              checked={appEnv} 
              style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
            />
        </div>
        <div className='flex justify-between items-center border-b pb-4'>
          <span className='font-normal text-[15px] leading-[18px] text-black'>2Factor authentication</span>
          <Toggle 
              handleChange={toggleAppEnv} 
              checked={appEnv} 
              style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
            />
        </div>
        <div className='w-full flex justify-between'>
          <p className='font-normal text-xs leading-3 text-Black2'>Payout from pocket to bank configuration</p>
          <span className='w-12 h-6 flex justify-end items-center cursor-pointer' onClick={e => toggleCard(e)}>
            <img src={showCard && cardId === "first" ? ArrowUpIcon : ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4" id="first"/>
          </span>
        </div>
        {showCard && cardId === "first" && <ConfigCard min={true} max={true}/>}
        <div className='w-full flex justify-between'>
          <p className='font-normal text-xs leading-3 text-Black2'>Sub pockets creation charge</p>
          <span className='w-12 h-6 flex justify-end items-center cursor-pointer' onClick={e => toggleCard(e)}>
            <img src={showCard && cardId === "second"? ArrowUpIcon : ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4" id="second"/>
          </span>
        </div>
        {showCard && cardId === "second" && <ConfigCard/>}
        <div className='w-full flex justify-between'>
          <p className='font-normal text-xs leading-3 text-Black2'>Pocket funding charge</p>
          <span className='w-12 h-6 flex justify-end items-center cursor-pointer' onClick={e => toggleCard(e)}>
            <img src={showCard && cardId === "third" ? ArrowUpIcon : ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4" id="third"/>
          </span>
        </div>
        {showCard && cardId === "third" && <ConfigCard/>}
      </div>
      <Button
        btnType='button'
        btnText='Save Changes'
        btnStyle='bg-DarkBg3 w-full text-white font-semibold text-sm leading-[22px] flex-row-reverse px-4 py-2'
        onClick={()=>{}}
      />
    </AppModal>
  )

  const multipleBtnData = [
    {btnText:"Pocket Configuration", btnStyle:"bg-DarkBg3 text-white font-semibold text-sm leading-[22px] mobile:text-xs flex-row-reverse px-4 py-2 flex-shrink-0", btnImg: editIcon, btnImgStyle:"w-4 h-4", onClick:toggleModal},
  ]

  return (
    <>
      {modalState && modalContent}
      <div className='flex flex-col gap-5 border py-8 desktop:px-12 px-8 mobile:p-4 rounded-lg max-w-[1299px] mt-8'>
        <PageTitleHeader 
            title="Pocket Configuration"
            subText='Default Pocket configurations'
            subTextStyle='text-sm tablet:text-SecondaryAccent'
            btn_num={1}
            btnData={multipleBtnData} 
        />
        <div className='flex desktop:flex-row flex-col justify-between desktop:gap-12'>
          <div className='flex flex-col gap-8 w-full desktop:w-[502px]'>
            <div className='flex justify-between items-center py-1'>
              <span className='font-semibold text-base leading-7 text-black'>Status</span>
              <ColorTag
                styleColor="border-Green_Accent bg-Green_Accent4"
                text="Active"
                textColor="text-Green_Accent5"
                BgColor="bg-Green_Accent5"
              />
            </div>

            <div className=''>
              <div className='pb-4 border-b'>
                <span className='font-semibold text-base leading-[19.2px] text-black'>Payout from pocket to bank configuration</span>
              </div>
              <div className='flex flex-col gap-3 py-5'>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Service status</span>
                  <ColorTag
                    styleColor="border-Green_Accent bg-Green_Accent4"
                    text="Active"
                    textColor="text-Green_Accent5"
                    BgColor="bg-Green_Accent5"
                  />
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Service charge</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>NGN 3,000.00</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Service charge type</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>Fixed</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Max amount limit status</span>
                  <ColorTag
                    styleColor="border-Green_Accent bg-Green_Accent4"
                    text="Active"
                    textColor="text-Green_Accent5"
                    BgColor="bg-Green_Accent5"
                  />
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Minimum amount</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>100.00</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Maximum amount</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>NGN 10,000,000.00</span>
                </div>
              </div>
            </div>

            <div className=''>
              <div className='pb-4 border-b'>
                <span className='font-semibold text-base leading-[19.2px] text-black'>Sub pockets creation charge</span>
              </div>
              <div className='flex flex-col gap-3 py-5'>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Service charge</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>NGN 0.00</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Charge type</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>Fixed</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Service status</span>
                  <ColorTag
                    styleColor="border-Green_Accent bg-Green_Accent4"
                    text="Active"
                    textColor="text-Green_Accent5"
                    BgColor="bg-Green_Accent5"
                  />
                </div>
              </div>
            </div>

            <div className=''>
              <div className='pb-4 border-b'>
                <span className='font-semibold text-base leading-[19.2px] text-black'>Bank payout configuration (Daily Limit)</span>
              </div>
              <div className='flex flex-col gap-3 py-5'>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Limit daily payout</span>
                  <ColorTag
                    styleColor="border-Green_Accent bg-Green_Accent4"
                    text="Active"
                    textColor="text-Green_Accent5"
                    BgColor="bg-Green_Accent5"
                  />
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Maximum daily payout amount</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>NGN 10,000,000.00</span>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-8 w-full desktop:w-[502px]'>
            <div className=''>
              <div className='pb-4 border-b'>
                <span className='font-semibold text-base leading-[19.2px] text-black'>Funding Configuration</span>
              </div>
              <div className='flex flex-col gap-3 py-5'>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Charge type</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>Fixed</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Charge amount</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>NGN 0.00</span>
                </div>
              </div>
            </div>

            <div className=''>
              <div className='pb-4 border-b'>
                <span className='font-semibold text-base leading-[19.2px] text-black'>Funding Charge</span>
              </div>
              <div className='flex flex-col gap-3 py-5'>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Bearer</span>
                  <span className='font-semibold text-sm leading-[16.8px] truncate'>Merchant</span>
                </div>
              </div>
            </div>

            <div className=''>
              <div className='pb-4 border-b'>
                <span className='font-semibold text-base leading-[19.2px] text-black'>2Factor Authentication</span>
              </div>
              <div className='flex flex-col gap-3 py-5'>
                <div className='flex justify-between items-center'>
                  <span className='font-normal text-sm leading-[16.8px] truncate text-SecondaryAccent'>Service status</span>
                  <ColorTag
                    styleColor="border-Green_Accent bg-Green_Accent4"
                    text="Active"
                    textColor="text-Green_Accent5"
                    BgColor="bg-Green_Accent5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  appEnv: state.app.appEnv,
  bearerToken: state.auth.token,
  publicKey: state.auth.public_key,
  modalState: state.app.modalState,
})

const mapDispatchToProps = dispatch => ({
  toggleModal: () => dispatch(toggleModal()),
  toggleAppEnv: () =>  dispatch(toggleAppEnv())
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultConfig)