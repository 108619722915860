import React from 'react'
import Button from './Button'

type Props = {
    promptSetting: any,
    action?: boolean,
    handleClose?: () => void,
    handleConfirm?: () => void
}

const Prompts:React.FC<Props> = ({promptSetting, action, handleClose, handleConfirm }) => {
    console.log("promptSetting.loadColor:", (promptSetting.loadColor).slice(3));

    const themeColor = (promptSetting.loadColor).slice(3)
    
    return (
        <>
            <div className={`bg-${promptSetting.BgColor} flex flex-col w-fit rounded-lg`}>
                <div className='flex flex-col gap-2 w-[293px] p-5 pb-0 border border-b-0 rounded-t-lg'>
                    <span className={`${promptSetting.iconStyle} rounded-full w-[48px] h-[48px] flex justify-center items-center`}>
                        <img src={promptSetting.icon} alt="prompt_type" className='w-[24px] h-[24px]'/>
                    </span>
                    <span className={`font-semibold text-lg leading-7 text-${themeColor} `}>{promptSetting.message}</span>
                    <span className='font-normal text-sm leading-5 text-Black3 mb-3'>{promptSetting.subMessage}</span>
                </div>
                <div className={`flex rounded-b-lg overflow-hidden`}>
                    <span className={`bg-${themeColor} ${promptSetting.load && 'animate-slide_right rounded-r-lg'} py-[2px] w-full`}></span>
                </div>
            </div>
            {action &&
                <div className='flex gap-5 pt-3'>
                    <span className='w-1/2'>
                        <Button
                            btnType='button'
                            btnText="Cancel"
                            btnStyle={`bg-white w-full py-1 text-${themeColor}`}
                            onClick={handleClose}
                        />
                    </span>
                    <span className='w-1/2'>
                        <Button
                            btnType='button'
                            btnText="Confirm"
                            btnStyle={`${promptSetting.loadColor} w-full py-1 text-white`}
                            onClick={handleConfirm}
                        />
                    </span>
                </div>
            }
        </>
    )
}

export default Prompts