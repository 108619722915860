import React, { useEffect, useState, useCallback } from 'react'
import PageHeader from 'components/molecules/PageHeader'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import { BASE_URL, username } from 'services/http'
import Alert from 'components/atoms/Alert'
import AppModal from '../../../components/organisms/CustomModal'
import { toggleAppEnv, toggleModal } from 'redux/app/app.action'
import { connect } from 'react-redux'
import Button from 'components/atoms/Button'
import { useNavigate } from 'react-router-dom'
import {ToCamelCase} from '../../../components/atoms/CaseManager'
import CopyText from '../../../components/atoms/CopyText'
import moment from 'moment'
import { handleException } from '../../../utils'
import { getValues } from '../../../services/storage'
import SideComponent from './SideComponent'
import axios from 'axios'
import {TransactionsHeader} from '../../../components/molecules/tableExport'

// const searchKeys = ["pocketId", "reference", "narration"].sort()

const searchKeys = [
  {title: "pocketId", value: null, link: null, action: () => null},
  {title: "reference", value: null, link: null, action: () => null},
  {title: "narration", value: null, link: null, action: () => null}
].sort()

const Transactions = ({
  toggleModal,
  modalState,
  bearerToken,
  publicKey,
  pocketId
}) => { 
  const [query, setQuery] = useState("")
  const [queryKey, setQueryKey] = useState("")
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [totalElements, setTotalElements] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [transactionDetails, setTransactionDetails] = useState([])
  const [pageNum, setPageNum] = useState(null)
  const [recordsPerPage ] =  useState(10)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [showModal, setShowModal] = useState(false)

  const defaultURl = `transaction/search?pocketId=${pocketId}`

  const url =  query.length !== 0 && (!startDate || !endDate)
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}`

            : query.length !== 0 && startDate !== "" && endDate !== ""
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
            
            : (startDate && endDate === "")
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}`

            : (startDate === "" && endDate)
            ? `${defaultURl}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : (startDate && endDate)
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : defaultURl

  const mainURL = `${url}&size=${recordsPerPage}&page=${pageNum === null ? 0 : pageNum}`

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber)
  }
  
  const fetchDataFunc = useCallback(async () => {
    setLoading(true)
    try{
      const res = await axios.get(`${BASE_URL + mainURL}` , {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`
        }
      })
      if(res.data.code === 'error'){
        setLoading(false)
        Alert("error", res.data.message);
      }else{
        setLoading(false)
        setTransactions(res.data.data.content)
        setTotalElements(res.data.data.totalElements)
        setTotalPages(res.data.data.totalPages)
      }
    }catch(error){
      setLoading(false)
      setErrorState(true)
      handleException(error)
    }
  }, [mainURL])
  
  useEffect(() => {
    fetchDataFunc()
  }, [fetchDataFunc])

  const viewTransaction = (id) => {
    setShowModal(prevState => !prevState)
    const transactionFilter = transactions.filter(item => item.transactionId === id)
    const transactionItem = transactionFilter[0]
    setTransactionDetails(transactionItem)
  }

  // (
  //   <>
  //   {console.log("viewTransaction:", item)}
  //   {console.log("viewTransaction:", item.availableBalanceAmount)}
  //   {item.transactionId === id}
  //   </>
  // )

  console.log("viewTransaction:", transactionDetails)

  const columns = [
    {
      label: 'Date',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        />
    }, 
    {
      label: 'Amount',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          text={data.transactionCurrency + " " + (Intl.NumberFormat().format(data.transactionAmount))} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        />
    },
    {
      label: 'Pocket Balace',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          text={data.transactionCurrency + " " + (Intl.NumberFormat().format(data.availableBalanceAmount))} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        />
    },
    {
      label: 'Pocket ID',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          additionalClass="cursor-default"
                        >
                          <CopyText 
                            text={data.pocketId}
                            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                            mssgStyle='-right-3 -top-[18px]' 
                            imgStyle="w-4 h-4"
                          />
                        </TableDataSpan>
    },
    {
      label: 'Transaction Reference',
      render: (data) => <TableDataSpan
                          onClick={()=>viewTransaction(data.transactionId)}  
                          additionalClass="cursor-default"
                        >
                          <CopyText 
                            text={data.reference}
                            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                            mssgStyle='-right-3 -top-[18px]' 
                            imgStyle="w-4 h-4"
                          />
                        </TableDataSpan>
    },
    {
      label: 'Transaction ID',
      render: (data) => <TableDataSpan
                          onClick={()=>viewTransaction(data.transactionId)}  
                          additionalClass="cursor-default"
                        >
                          <CopyText 
                            text={data.transactionId}
                            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                            mssgStyle='-right-3 -top-[18px]' 
                            imgStyle="w-4 h-4"
                          />
                        </TableDataSpan>
    },
    {
      label: 'Transaction Type',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          text={data.transactionType} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        />
    },
    {
      label: 'Transaction Status',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          text={data.transactionLeg} 
                          additionalClass={`text-opacity-70 text-xs rounded-full px-2 py-1 cursor-pointer
                            ${data.transactionLeg === 'DEBIT' ? 'bg-DangerAccent3 text-Danger2' : 'bg-Green_Accent text-Green_Accent2'}
                          `}
                        />
    }
  ]


  return (
    <>
      <PageHeader
        pageTitle='Transactions'
        pageTitleStyle=''
        exportTable={true}
        sendFund={false}
        tableKeys={searchKeys}
        queryValue={query}
        setQuery={setQuery}
        setQueryKey={setQueryKey}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        tableHeaders={TransactionsHeader}
        tableData={transactions}
        csvUrl={url}
      />
      <AppTable
        data={transactions}
        columns={columns}
        loading={loading}
        errorState={errorState}
        itemsPerPage={recordsPerPage}

        dataLength={totalElements}
        getCurrentPage={getCurrentPage}
      />
      {showModal && 
        <SideComponent
          toggleModal={() => setShowModal(prevState => !prevState)}
          data={transactionDetails}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  appEnv: state.app.appEnv,
  modalState: state.app.modalState,
  bearerToken: state.auth.token,
  user_data: state.auth.user_data,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId,
})

const mapDispatchToProps = (dispatch) => ({
  toggleAppEnv: () => dispatch(toggleAppEnv()),
  toggleModal: () => dispatch(toggleModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)