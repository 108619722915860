import React from 'react'
import closeIcon from '../../../assets/svg/close-circle-solid.svg'

type Props = {
    children: JSX.Element,
    modalStyle: String,
    contentStyle?: String,
    onClose: () => void
}

const Modal:React.FC<Props> = ({children, modalStyle, contentStyle, onClose}) => {
    
    return (
        <div className={`${modalStyle} fixed`}>
            <div className={`${contentStyle} relative overflow-y-scroll custom_container`}>
                {onClose === undefined ? "" : <img src={closeIcon} alt="closeIcon" className='absolute right-0 top-1 cursor-pointer' onClick={onClose}/>}
                {children}
            </div>
        </div>
    )
}

export default Modal