import React, {useState, useEffect, useRef} from 'react'
import PageTitle from '../atoms/PageTitle'
import FormInput from '../atoms/FormInput'
import Button from 'components/atoms/Button'
import exportIcon from '../../assets/svg/export.svg'
import HomeIconSolid from '../../assets/svg/hubSolid.svg'
import HomeIconActive from '../../assets/svg/home-con-dark-solid.svg'
import arrow_down from '../../assets/svg/arrow-down.svg'
import arrow_up from '../../assets/svg/arrow-up.svg'
import { toggleDataView, toggleModal } from 'redux/app/app.action'
import { connect } from 'react-redux'
import TableHeader from 'components/organisms/app-table/TableHeader'
import {ToTitleCase} from '../atoms/CaseManager'
import Dropdown from 'components/atoms/Dropdown/index'
import ExportModal from '../atoms/ExportComp'
import SendIcon from '../../assets/svg/sendIcon.svg'
import { useNavigate, useNavigation } from 'react-router-dom'

type Props = {
    title?: string,
    titleStyle?: string,
    pageTitle?: string, 
    pageTitleStyle?: string, 
    exportTable?: boolean, 
    sendFund?: boolean, 
    manageTableData?: boolean,
    manageTableBtnText?: string,
    manageTableBtnAction?: () => void

    switchView?: boolean,
    tableKeys: any[],
    tableHeaders?: any[],
    tableData?: any[],
    csvUrl?: string,

    queryValue?: string,
    setQuery?: (searchTerm: any) => void,

    setQueryKey?: (searchLabel?: any) => void,

    startDate?: string,
    setStartDate?: (startDate?: any) => void

    endDate?: string,
    setEndDate?: (endDate?: any) => void

    dataView?: boolean,
    toggleView?: () => void,

    modalState: boolean,
    toggleModal: () => void
}

const PageHeader: React.FC<Props> = ({
    title,
    titleStyle,
    pageTitle,
    pageTitleStyle,
    exportTable,
    sendFund,
    manageTableData,
    manageTableBtnText,
    manageTableBtnAction,
    switchView,
    tableKeys,
    queryValue,
    tableHeaders,
    tableData,
    csvUrl,
    dataView,
    toggleView,
    setQuery,
    setQueryKey,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    modalState,
    toggleModal
}) => {
    const [searchKey, setSearchKey] = useState("")
    const [dropdown, setDropdown] = useState(false)
    const highLight = useRef()
    
    const mapData = tableKeys.map((item, i) => ToTitleCase(item.title))

    const handleExportModal = () => {
        toggleModal()
    }

    const navigate = useNavigate()
    
    const handleTransferFunds = () =>  {
        navigate('/dashboard/send_money')
    }
    
    return (
        <div className='py-5 flex flex-col gap-3'>
            <div className='flex justify-between items-center gap-5'>
                <div className='w-full flex'>
                    {pageTitle &&
                        <PageTitle pageTitle={pageTitle ? pageTitle : ''} pageTitleStyle={pageTitleStyle}/>
                    }
                    {/* {title &&
                        <span className={`${titleStyle}`}>{title}</span>
                    } */}
                </div>

                <div className='flex mobile:hidden justify-end mobile:justify-between gap-5 tablet:w-full'>
                    {exportTable &&
                        <Button 
                            btnType="button" 
                            btnText="Export Data"
                            btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center px-3 py-2 font-semibold text-base leading-7 flex-row-reverse rounded-lg truncate" 
                            disabled={false} 
                            disabledClass=""
                            onClick={handleExportModal}
                        />
                    }
                    {manageTableData && 
                        <Button 
                            btnType="button" 
                            btnText={manageTableBtnText ? manageTableBtnText : "Manage pockets" }
                            btnStyle="px-3 py-2 font-semibold text-base leading-7 bg-DarkBg3 text-SecondaryAccent5 flex-row-reverse rounded-lg truncate" 
                            disabled={false} 
                            disabledClass=""
                            onClick={manageTableBtnAction}
                        />
                    }
                    {sendFund && 
                        <Button 
                            btnType="button" 
                            btnText="Send Money"
                            // btnImg={SendIcon}
                            // btnImgStyle="w-5 h-5 flex-shrink-0"
                            btnStyle="flex gap-3 py-2 px-4 w-fit cursor-pointer font-semibold text-base leading-7 bg-DarkBg3 text-SecondaryAccent5 rounded-lg truncate" 
                            disabled={false} 
                            disabledClass=""
                            onClick={handleTransferFunds}
                        />
                    }
                </div>
            </div>
            <div className='flex mobile:flex-col justify-between items-center gap-5 tablet:gap-2'>
                <div className='w-full'>
                    <Dropdown
                        dropdown={dropdown}
                        setDropdown={setDropdown}
                        dropdownData={tableKeys}
                        containerStyle='min-w-[200px] max-w-[300px] mobile:max-w-full'
                        dropdownStyle="left-0 top-20 !gap-3"
                        selector={true}
                        arrowIcon={false}
                        arrowStyle="absolute right-4 bottom-5"
                        handleSelect={setSearchKey}
                    >
                        <FormInput
                            label={searchKey === "" ? "Select a search key below" : `Filter by ${ToTitleCase(searchKey)}`}
                            labelStyle="text-GrayCustom7 text-xs"
                            type="text"
                            name="searchItem"
                            autoComplete="off"
                            placeholder={searchKey === "" ? "Select category" : `Filter by ${ToTitleCase(searchKey)}`}
                            style="rounded-lg w-full"
                            inputStyle="flex-row-reverse border !py-0 mb-2 mobile:mb-0"
                            img={dropdown ? arrow_up : arrow_down}
                            imgStyle="!right-1 w-10 h-5 cursor-pointer"
                            imgOnClick={()=>setDropdown(prevState => !prevState)}

                            value={queryValue}
                            validationErr=""
                            disabled={searchKey === "" ? true : false}
                            onChange={e=>{
                                setQuery && setQuery(e.target.value)
                                setQueryKey && setQueryKey(searchKey)
                            }}
                            ref={highLight}
                        />
                    </Dropdown>
                </div>
                
                <div className={`mobile:w-full flex mobile:flex-col justify-between mobile:justify-center items-center ${switchView && 'gap-5'}`}>
                    <div className='mobile:w-full flex justify-between items-center gap-5 tablet:gap-2'>
                        <FormInput
                            label="Start Date"
                            labelStyle="text-GrayCustom7 text-xs"
                            type="date"
                            name="start_date"
                            style="rounded-lg w-[200px] mobile:w-full tablet:w-1/2"
                            inputStyle="border"
                            value={startDate}
                            onChange={e=>{
                                setStartDate && setStartDate((e.target.value))
                            }}
                        />
                        <FormInput
                            label="End Date"
                            labelStyle="text-GrayCustom7 text-xs"
                            type="date"
                            name="end_date"
                            style="rounded-lg w-[200px] mobile:w-full tablet:w-1/2"
                            inputStyle="border"
                            value={endDate}
                            onChange={e=>{
                                setEndDate && setEndDate((e.target.value))
                            }}
                        />
                    </div>
                    
                    <div className='hidden mobile:flex desktop:justify-end tablet:justify-between gap-5 tablet:w-full'>
                        {exportTable &&
                            <Button 
                                btnType="button" 
                                btnText="Export Data"
                                btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center px-3 py-2 font-semibold text-base leading-7 flex-row-reverse rounded-lg truncate" 
                                disabled={false} 
                                disabledClass=""
                                onClick={handleExportModal}
                            />
                        }
                        {manageTableData && 
                            <Button 
                                btnType="button" 
                                btnText={manageTableBtnText ? manageTableBtnText : "Manage pockets" }
                                btnStyle="px-3 py-2 font-semibold text-base leading-7 bg-DarkBg3 text-SecondaryAccent5 flex-row-reverse rounded-lg truncate" 
                                disabled={false} 
                                disabledClass=""
                                onClick={manageTableBtnAction}
                            />
                        }
                        {sendFund && 
                            <Button 
                                btnType="button" 
                                btnText="Send Money"
                                btnImg={SendIcon}
                                btnImgStyle="w-5 h-5 flex-shrink-0"
                                btnStyle="flex gap-3 py-2 px-4 cursor-pointer font-semibold text-base leading-7 bg-DarkBg3 text-SecondaryAccent5 rounded-lg truncate" 
                                disabled={false} 
                                disabledClass=""
                                onClick={handleTransferFunds}
                            />
                        }
                    </div>

                    <div className={`flex tablet:hidden justify-end items-end relative top-3 mobile:top-0 flex-shrink-0`}>
                        {switchView &&
                            <Button
                                btnType="button" 
                                btnText={`${dataView ? 'Switch to grid' : 'Switch to table'}`}
                                btnStyle="border cursor-pointer flex gap-3 py-2 px-4 rounded border-dashed truncate flex-shrink-0" 
                                disabled={false} 
                                disabledClass=""
                                btnImg={dataView ? HomeIconSolid : HomeIconActive}
                                btnImgStyle="w-6 h-6 flex-shrink-0"
                                onClick={toggleView}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className={`hidden tablet:flex justify-end items-end  relative top-3 tablet:top-0 flex-shrink-0`}>
                {switchView &&
                    <Button
                        btnType="button" 
                        btnText={`${dataView ? 'Switch to grid' : 'Switch to table'}`}
                        btnStyle="border cursor-pointer flex gap-3 py-2 px-4 rounded border-dashed truncate flex-shrink-0" 
                        disabled={false} 
                        disabledClass=""
                        btnImg={dataView ? HomeIconSolid : HomeIconActive}
                        btnImgStyle="w-6 h-6 flex-shrink-0"
                        onClick={toggleView}
                    />
                }
            </div>
            {modalState && 
                <ExportModal
                    toggleModal={toggleModal}
                    headers={tableHeaders}
                    data={tableData}
                    titleName={pageTitle}
                    csvUrl={csvUrl}
                />
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    dataView: state.app.dataView,
    modalState: state.app.modalState
})

const mapDispatchToProps = (dispatch: any) => ({
    toggleView: () => dispatch(toggleDataView()),
    toggleModal: () => dispatch(toggleModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
