import Alert from 'components/atoms/Alert'
import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown/index'
import FormInput from 'components/atoms/FormInput'
import PageTitle from 'components/atoms/PageTitle'
import Select from 'components/atoms/SelectInput'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { createSubPocket } from '../../../../redux/Pockets/pocket.action'
import { SubPocketData } from './SubPocketData'

const AccountVerification = ({subPocketData, createSubPocket}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const [dropdown, setDropdown] = useState(false)
    const [verificationType, setVerificationType] = useState("") 
    const [idNum, setIdNum] = useState("")  

    const {modeOfIdData} = SubPocketData()

    const absolutePath = '/dashboard/sub_pockets/create_sub_pocket/'

    const currentPath = location.pathname.split('/').slice(-1).toString()

    const handleSubmit = (e?: any)    => {
        e.preventDefault()

        if(verificationType === "" || idNum === ""){
            Alert('error', 'Fill all fields')
        }else{
            navigate(`${absolutePath}finish_setup`)
            console.log("subPocketData:", subPocketData)
            Alert('success','Next')
        }

        createSubPocket({...subPocketData, ...{
            modeOfId: verificationType,
            idNum: idNum
        }})

        console.log("subPocketData:", subPocketData)
    }

    const handleEvent = () => {
        navigate(`${absolutePath}basic_info`)

        console.log("subPocketData:", subPocketData)
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className='w-[342px] mobile:w-full'>
                <div className='flex gap-10 items-center'>
                    <PageTitle 
                        pageTitle="Account verification (Optional)" 
                        pageTitleStyle="!font-semibold !text-xl !my-0"
                    />
                    <Button
                        btnType='button'
                        btnText='Skip'
                        btnStyle='px-4 font-semibold text-lg leading-[30px]'
                        onClick={()=>navigate('/dashboard/sub_pockets/create_sub_pocket/finish_setup')}
                    />
                </div>
                <div className='flex flex-col gap-5 mt-8'>
                    <Dropdown
                        dropdown={dropdown}
                        setDropdown={setDropdown}
                        dropdownData={modeOfIdData}
                        dropdownStyle="-right-0 top-20 animate-slide_down !gap-3"
                        selector={true}
                        arrowStyle="absolute right-4 bottom-4"
                        handleSelect={setVerificationType}
                    >
                        <div className='flex flex-col gap-2 w-full'>
                            <span className='font-semibold text-base leading-6 text-Black2 mx-2'>Select pocket type</span>
                            <div className='border rounded-md p-3 w-full'>
                                <p className='font-normal text-sm text-BackDrop_d_lg'>{verificationType === "" ? "Select pocket function" : verificationType}</p>
                            </div>
                        </div>
                    </Dropdown>
                    <FormInput 
                        type="text"  
                        name="identification" 
                        placeholder="Identification number" 
                        style="mb-2 w-full"
                        inputStyle="border border-transparent text-Black2"
                        label="Enter Identity Number" 
                        labelStyle="font-semibold text-base leading-6 text-Black2" 
                        onChange={e => setIdNum(e.target.value)}
                        value={idNum}
                        validationErr=''
                    />
                </div>
            </div>

            <div className='flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]'>
                <Button 
                    btnType="button"
                    btnText={`${currentPath === 'basic_info' ? 'Cancel' : 'Previous'}`}
                    btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={handleEvent}
                />
                <Button 
                    btnType="submit" 
                    btnText={`${currentPath === 'finish_setup' ? 'Finish' : 'Next'}`}
                    btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={()=>{}}
                />
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    subPocketData: state.pocket.subPocketData
})

const mapDispatchToProps = dispatch => ({
    createSubPocket: (data) => dispatch(createSubPocket(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountVerification)