export const tableHeader = ["ID", "Business name", "Country", "Currency", "Pockets status", "Business status", "Date created"]

export const tableBody = [
  {id: 1, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 2, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 3, business_name: "Stock Exchange", country: "Nigeria", currency: "USD", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 4, business_name: "Stock Exchange", country: "Nigeria", currency: "USD", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 5, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 6, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 7, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 8, business_name: "Stock Exchange", country: "Nigeria", currency: "GHS", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 9, business_name: "Stock Exchange", country: "Nigeria", currency: "GHS", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 10, business_name: "Stock Exchange", country: "Nigeria", currency: "GHS", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 11, business_name: "Stock Exchange", country: "Nigeria", currency: "GHS", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 12, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 13, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 14, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 15, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 16, business_name: "Stock Exchange", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 17, business_name: "Reddy Empire", country: "Nigeria", currency: "USD", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 18, business_name: "Reddy Empire", country: "Nigeria", currency: "USD", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 19, business_name: "Reddy Empire", country: "Nigeria", currency: "USD", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 20, business_name: "Front store", country: "Nigeria", currency: "USD", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 21, business_name: "Front store", country: "Nigeria", currency: "USD", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 22, business_name: "Front store", country: "Nigeria", currency: "USD", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 23, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 24, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 25, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 26, business_name: "Front store", country: "Nigeria", currency: "GHS", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 27, business_name: "Front store", country: "Nigeria", currency: "GHS", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 28, business_name: "Front store", country: "Nigeria", currency: "GHS", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 29, business_name: "Front store", country: "Nigeria", currency: "GHS", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 30, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 31, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 32, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 33, business_name: "Front store", country: "Nigeria", currency: "USD", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 34, business_name: "Front store", country: "Nigeria", currency: "USD", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 35, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 36, business_name: "Front store", country: "Nigeria", currency: "GHS", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 37, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 38, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 39, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 40, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 41, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 42, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 43, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 44, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 45, business_name: "Front store", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 46, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 47, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 48, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 49, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 50, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 51, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 52, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 53, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 54, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 55, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 56, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 57, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 58, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 59, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 60, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 61, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 62, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 63, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 64, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 65, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 66, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 67, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 68, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 69, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 70, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 71, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 72, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 73, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 74, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 75, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 76, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 77, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 78, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 79, business_name: "Seerbit", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 80, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 81, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 82, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 83, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 84, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Inactive", date_created: "22-03-23, 09:00AM"},
  {id: 85, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 86, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Activated", business_status: "Active", date_created: "22-03-23, 09:00AM"},
  {id: 87, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 88, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 89, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
  {id: 90, business_name: "Reddy Empire", country: "Nigeria", currency: "NGN", pocket_status: "Deactivated", business_status: "Configure", date_created: "22-03-23, 09:00AM"},
]