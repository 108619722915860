import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthHeader from 'components/atoms/AuthHeader'
import authSideImg from '../../assets/images/authSideImg2.png'

const Auth = () => {
    
    return (
        <div className='w-full h-[100vh] flex'>
            <div className='flex flex-col w-[56.5%] tablet:w-full p-8'>
                <AuthHeader/>
                <div className='w-full h-[70vh] tablet:h-[100vh] flex justify-center items-center'>
                    <div className='w-80 h-fit'>
                        <Outlet/>
                    </div>
                </div>
            </div>
            <div className='w-[43.5%] tablet:hidden'>
                <img src={authSideImg} alt="auth_sideImg" className="w-full h-[100vh] object-cover object-center"/>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)