import React from 'react'
import FormInput from 'components/atoms/FormInput'
import Button from 'components/atoms/Button'

const SupportEmails = () => {
  return (
    <div className='w-full desktop:w-[342px]'>
      <form className='flex flex-col gap-5'>
        <FormInput 
          type="text"  
          name="chargeback_email" 
          placeholder="example@gmail.com" 
          inputStyle="border border-transparent text-Black2"
          label="Chargeback email" 
          labelStyle="font-normal text-base leading-6 text-DarkBg6" 
          style='w-full'
          onChange={()=>{}}
          value=""
          validationErr=""
        />
        <FormInput 
          type="text"  
          name="support_email" 
          placeholder="example@gmail.com" 
          inputStyle="border border-transparent text-Black2"
          label="Support email" 
          labelStyle="font-normal text-base leading-6 text-DarkBg6" 
          style='w-full'
          onChange={()=>{}}
          value=""
          validationErr=""
        />
        <FormInput 
          type="text"  
          name="phone" 
          placeholder="+234" 
          inputStyle="border border-transparent text-Black2"
          label="Phone number" 
          labelStyle="font-normal text-base leading-6 text-DarkBg6" 
          style='w-full'
          onChange={()=>{}}
          value=""
          validationErr=""
        />
        <div className='flex justify-end items-center gap-5'>
          <Button 
            btnType="button"
            btnText="Cancel"
            btnStyle='bg-SecondaryAccent3 text-black font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
          />
          <Button 
            btnType="button"
            btnText="Save changes"
            btnStyle='bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full'
          />
        </div>
      </form>
    </div>
  )
}

export default SupportEmails