import React, {useState} from 'react'
import { connect } from 'react-redux'
import FormInput from 'components/atoms/FormInput'
import {BsSearch} from 'react-icons/bs'
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
import Toggle from '../../components/atoms/Toggle'
import { toggleAppEnv } from 'redux/app/app.action'
import { NotificationBell } from '../atoms/ImagesImport'
import Dropdown from '../../components/atoms/Dropdown/index'
import { logoutHandler, setAuthRes, setUserLoggedIn } from 'redux/auth/auth.action'
import { getValues, removeValues, setValues } from '../../services/storage'
import { useNavigate } from 'react-router-dom'
import arrowDownIcon from '../../assets/svg/arrow-down.svg'
import arrowUpIcon from '../../assets/svg/arrow-up.svg'
import searchIcon from '../../assets/svg/search-icon.svg'
import NavDropdownData from '../molecules/NavDropdownData'
import Support from 'components/molecules/Support'
import Button from 'components/atoms/Button'
import messageIcon from '../../assets/svg/message-text.svg'
 
const TopNav = ({toggleAppEnv, appEnv, setAuthRes, setUserLoggedIn, logoutHandler, bearerToken}) => {
  const [value, setValue] = useState("")
  const [dropdown, setDropdown] = useState(false)
  const [supportDropdown, setSupportDropdown] = useState(false)

  const {dropdownData} = NavDropdownData(logoutHandler, bearerToken)

  const handleOnChange = e => {
    setValue(e.target.value)
  }

  const showDropdown = () =>{
    setDropdown(prevState => !prevState)
  }

  const username = getValues('username')

  return (
    <div className={`flex justify-between p-3 shadow ${appEnv === false && 'shadow-Primary'}`}>
      <div className='desktop:w-5/12 mx-12'>
        <FormInput
          type="search"
          name="searchItem"
          placeholder="Search payment references, transactions, clients"
          style={'text-Black2'}
          inputStyle="bg-GrayCustom6 text-Black2"
          inputStyle2={`text-xs leading-5 text-GrayCustom7 pl-8`}
          value={value}
          img={searchIcon}
          imgStyle="w-4 h-4 !relative !left-5"
          validationErr=""
          onClick={()=>{}}
          onChange={handleOnChange}
        />
      </div>
      <div className='flex justify-between items-center gap-5 mr-10'>
        <div className='mr-5'>
          <Toggle 
            handleChange={toggleAppEnv} 
            checked={appEnv} 
            label={`${appEnv ? 'Live Mode' : 'Test'}`} 
            labelStyle={`${appEnv ? 'text-black' : 'text-Primary'} truncate text-base leading-5 font-normal w-[62px]`} 
            style="w-6 h-3 after:h-3 after:w-3 after:top-1 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
          />
        </div>

        {/* <Dropdown 
          component={<Support/>} 
          dropdown={supportDropdown}
          style="absolute -right-32 top-12 desktop:w-[497px] animate-slide_down !gap-3 rounded-xl !bg-Black4 text-white"
          iconStyle='w-5 h-5'
          textStyle='text-sm w-full'
          containerStyle='border'
        > */}
        <Dropdown
            dropdown={supportDropdown}
            setDropdown={setSupportDropdown}
            dropdownData={<Support/>}
            dropdownStyle="right-0 top-12 !min-w-[400px]"
        >
          <Button 
            btnType='button'
            btnText='Support'
            btnImg={messageIcon}
            btnImgStyle='w-4 h-4'
            btnStyle='bg-DarkBg3 text-white gap-3 py-1 px-3 flex-row-reverse'
          />
        </Dropdown>

        <div className='relative flex justify-between flex-shrink-0 cursor-pointer mr-5'>
          <span className='flex-shrink-0'>
            <NotificationBell style="w-7 h-7"/>
          </span>
          <span className='flex justify-center items-center absolute top-1 right-0 rounded-full border border-Primary text-center bg-Primary text-white font-semibold text-xs leading-5 ' style={{width:"13.5px", height:"13.5px"}}>
            5
          </span>
        </div>

        <Dropdown
            dropdown={dropdown}
            setDropdown={setDropdown}
            dropdownData={dropdownData}
            dropdownStyle="right-0 top-12 animate-slide_down !gap-3"
        >
          <div className='flex items-center gap-2 cursor-pointer'>
            <div className='flex flex-shrink-0 justify-center items-center bg-DarkBg3 rounded-full w-7 h-7'>
              <span className='flex-shrink-0 font-bold text-base leading-4 text-center mx-auto text-SecondaryAccent4'>{username?.slice(0,2).toUpperCase()}</span>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  bearerToken: state.auth.token,
  auth_data: state.auth.auth_data,
  appEnv: state.app.appEnv
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleAppEnv: () => dispatch(toggleAppEnv()),
  logoutHandler: () => dispatch(logoutHandler()),
  setAuthRes: (data: any) => dispatch(setAuthRes(data)),
  setUserLoggedIn: (data: any) => dispatch(setUserLoggedIn(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)