import {
    SET_TOKEN,
    SET_PUBLIC_KEY,
    SET_USER_LOGGED_IN,
    AUTH_RESPONSE,
    SET_USER_DATA,
    SET_PRIMARY_POCKET_DATA,
    RESET_STATE,
    SET_LANDING_PAGE_DATA
} from './auth.type'

export const setBearerToken = (token: any) => ({
    type: SET_TOKEN,
    payload: token
})

export const setPublicKey = (public_key: any) => ({
    type: SET_PUBLIC_KEY,
    payload: public_key
})

export const setUserLoggedIn = (value: boolean) => ({
    type: SET_USER_LOGGED_IN,
    payload: value
})

export const setAuthRes = (data: any[]) => ({
    type: AUTH_RESPONSE,
    payload: data
})

export const setUserData = (data: any[]) => ({
    type: SET_USER_DATA,
    payload: data
})

export const setPocketData = (data: any[]) => ({
    type: SET_PRIMARY_POCKET_DATA,
    payload: data
})

export const logoutHandler = () => ({
    type: RESET_STATE
})