import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import Alert from './Alert'

type Props = {
    icon1?: JSX.Element,
    icon2?: JSX.Element,
    text?: string,
    style?: string,
    active?: boolean,
    darkMode?: boolean,
    onClick: (e) => void
}

const SideNavItem: React.FC<Props> = ({icon1, icon2, text, active, style, onClick}) => {
    
    const standardStyle = `${style} py-4 gap-3 items-center pl-12 text-SecondaryAccent5 ${text !== '' && 'hover:bg-GrayCustom5'} hover:transition-opacity cursor-pointer my-1 text-white`
    const activeStyle = `bg-GrayCustom4 shadow-sm`
    
    return (
        <div className={`${active ? activeStyle : ''} ${standardStyle} flex justify-between ${text !== '' && 'cursor-pointer'} text-white`} onClick={onClick}>
            <span className='flex gap-3 items-center'>
                <span className="flex-shrink-0">{active ? icon1 : icon2}</span>
                <span className=''>{text}</span>
            </span>
            {active && <span className='h-[35px] w-1 bg-Primary rounded-l'></span>}
        </div>
    )
}

export default SideNavItem