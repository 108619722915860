import React, {useState, useEffect} from 'react'
import SettingsNavItem from 'components/atoms/SettingsNavItem'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'components/atoms/Button'
import addIcon from '../../assets/svg/add_icon.svg'
import { getValues } from 'services/storage'

const NavData = [
    {title: "Profile"},
    {title: "Business information"},
    {title: "API keys"},
    {title: "Web hooks"},
    // {title: "User management"},
]

const SettingsNav = () => {
    const [selected, setSelected] = useState("")
    const location = useLocation()
    const navigate = useNavigate()
    const accountType = getValues('business_type')

    useEffect(() => {
        setSelected(NavData[0].title === "Profile" ? "Profile" : selected)
    }, [accountType])

   const currentPath = (location.pathname).split("/").slice(-1).toString()
   
   console.log("currentPath:", currentPath);
   
    return (
        <div className={`flex flex-col gap-5 w-full`}>
            <div className='flex desktop:gap-16 tablet:gap-5 border-b overflow-x-auto'>
                {NavData.map(item => (
                    <SettingsNavItem
                        title={item.title}
                        active={item.title === selected}
                        link={`/dashboard/settings/${item.title.replace(" ", "_").toLowerCase()}`}
                        onClick={e => setSelected(e.target.dataset.name)}
                    />
                ))}
            </div>
            
            {currentPath === "user_management" ||  currentPath === "payout_service" 
                ?   <div className='flex tablet:flex-col tablet:gap-5 desktop:justify-between'>
                        <span className='desktop:w-[551px] font-normal desktop:text-base tablet:text-sm leading-7 text-DarkBg3 text-justify'>
                            Manage accounts easily by customizing user roles. Assign various access levels and permissions to make sure that each team member has the resources they need to do their job. <a href="#" className='font-bold text-base text-Accent_blue'>Learn more about the permissions.</a>
                        </span>
                        <span className='flex gap-5'>
                            {currentPath === "user_management" &&
                                <>
                                    <Button
                                        btnType="button" 
                                        btnText="Manage Roles"  
                                        btnStyle="w-fit h-fit px-5 py-2 text-DarkBg3 font-semibold text-[14px] leading-5 bg-SecondaryAccent5" 
                                        onClick={() => navigate(`/dashboard/settings/role_permission`)}
                                    />
                                    <Button
                                        btnType="button" 
                                        btnText="Invite User" 
                                        btnImg={addIcon}
                                        btnImgStyle="w-3 h-3"
                                        btnStyle="w-fit h-fit px-5 py-2 flex-row-reverse text-white font-semibold text-[14px] leading-5 bg-DarkBg3" 
                                        onClick={()=>{}}
                                    />
                                </>
                            }
                            {currentPath === "payout_service" &&
                                <Button
                                    btnType="button" 
                                    btnText="Create New" 
                                    btnStyle="w-fit h-fit px-5 py-2 flex-row-reverse text-white font-semibold text-[14px] leading-5 bg-DarkBg3" 
                                    onClick={()=>{}}
                                />
                            }
                        </span>
                    </div>
                
                :   ""
            }
        </div>
    )
}

export default SettingsNav