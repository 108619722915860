import React from 'react'
import InfoNav from 'components/organisms/InfoNav'
import { Outlet } from 'react-router-dom'

const BusinessInfo = () => {
  const BusinessInfoNavData = [
    {title: "Basic details", link: "basic_details"},
    {title: "Support emails", link: "support_emails"},
    {title: "Business address", link: "business_address"},
    {title: "Know your customers", link: "customers"}
  ]
  return (
    <div className='desktop:w-10/12 w-full flex justify-between'>
      <div className='p-5 w-full'>
        <div className='flex flex-col gap-1 mb-12'>
          <span className='text-lg font-normal text-black leading-5'>
            Your  Business Information
          </span>
          <span className='text-[15px] font-normal leading-5 text-DarkBg6'>
            Update your personal and contact information.
          </span>
        </div>
        <Outlet/>
      </div>
      <InfoNav
        navData={BusinessInfoNavData}
        navPath={'/dashboard/settings/business_information/'}
      />
    </div>
  )
}

export default BusinessInfo