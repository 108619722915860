import React, {useState} from 'react'
import copyIcon from '../../assets/svg/copy.svg'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Alert from './Alert'

type Props = {
    text: any,
    textStyle?: string,
    style?: string,
    mssgStyle?: string,
    imgStyle?: string
}

const CopyText: React.FC<Props> = ({text, textStyle, style, mssgStyle, imgStyle}) => {
    const [showMssg, setShowMssg] = useState(false)

    const conetent = <span className={`${mssgStyle} font-semibold text-sm leading-5 text-Success absolute`}>Copied!</span>

    const handleCopy = () => {
        Alert('success','Copied!')
        
        setShowMssg(true)
        setTimeout(() => {
            setShowMssg(false)
        }, 1500);
    }

    return (
        <div className={`${style} flex justify-between gap-3 items-center w-fit relative`}>
            <span className={`${textStyle}`}>
                {text}
            </span>
            <span className='flex-shrink-0 cursor-pointer'>
                <CopyToClipboard text={text} onCopy={handleCopy}>
                    <img src={copyIcon} alt='copyIcon' className={`${imgStyle} cursor-pointer`}/>
                </CopyToClipboard>
            </span>
            {showMssg && conetent}
        </div>
    )
}

export default CopyText