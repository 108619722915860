import {
    TOGGLE_APP_ENV,
    TOGGLE_MODAL,
    TOGGLE_DATA_VIEW,
    SELECT_NAV_ITEM,
    STORE_NAV_KEY,
    STORE_CURRENT_PATH,
    SET_BASE_URL,
    SET_LANDING_PAGE_DATA
} from './app.type'

export const toggleAppEnv = () => ({
    type: TOGGLE_APP_ENV
})

export const toggleModal = () => ({
    type: TOGGLE_MODAL
})

export const toggleDataView = () => ({
    type: TOGGLE_DATA_VIEW
}) 

export const selectNavItem = (data: any) => ({
    type: SELECT_NAV_ITEM,
    payload: data
})

export const storeNavKey = (data: any) => ({
    type: STORE_NAV_KEY,
    payload: data
})

export const storeCurrentPath = (data: any) => ({
    type: STORE_CURRENT_PATH,
    payload: data
})

export const setBaseUrl = (url: any) => ({
    type: SET_BASE_URL,
    payload: url
})

export const setLandingPageData = (data: any[]) => ({
    type: SET_LANDING_PAGE_DATA,
    payload: data
})