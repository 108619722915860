import React, {useState} from 'react'
import PageHeader from '../../../components/molecules/PageHeader'
import {TrailHeader, TrailData} from '../../../mockData/AuditTrailData'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'

const AuditTrail = () => {
  // const [query, setQuery] = useState("")

  const tableKey = TrailHeader.map(item => (
    item.replace(" ","_").toLowerCase()
  ))

  const valueBodyObjValue = TrailData.map(item => (
    Object.values(item).map(value => value)
  ))

  const columns = [
    {
      label: 'User',
      render: (data: any) => <TableDataSpan text={data.user} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Transfer Reference',
      render: (data: any) => <TableDataSpan text={data.transferRef} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Activity Type',
      render: (data: any) => <TableDataSpan text={data.activityType} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Activity',
      render: (data: any) => <TableDataSpan text={data.activity} additionalClass='text-opacity-70 text-xs'/>
    },
    {
      label: 'Date',
      render: (data: any) => <TableDataSpan text={data.date} additionalClass='text-opacity-70 text-xs'/>
    },
  ]

  return (
    <>
      <PageHeader
        pageTitle="Audit Trail" 
        pageTitleStyle="bg-Background"
        exportTable={true}
        manageTableData={true}
        tableKeys={columns}
      />
      <AppTable
        data={TrailData}
        columns={columns}
        itemsPerPage={10}
      />
    </>
  )
}

export default AuditTrail